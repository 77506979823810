@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes pulse {
  0%,
  17%,
  33%,
  50%,
  100% {
    opacity: 1;
  }

  8%,
  25%,
  42% {
    opacity: 0;
  }
}

@keyframes animationRightToLeft {
  from {
    clip-path: inset(0 0 0 100%);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes animationLeftToRight {
  from {
    clip-path: inset(0 100% 0 0);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes animationTopToBottom {
  from {
    clip-path: inset(0 0 100% 0);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes animationBottomToTop {
  from {
    clip-path: inset(100% 0 0 0);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes animationUndo {
  from {
    clip-path: inset(0 0 0 0);
  }

  to {
    clip-path: inset(0 0 0 100%);
  }
}

@keyframes animationUndoAlternate {
  from {
    clip-path: inset(0 0 0 0);
  }

  to {
    clip-path: inset(0 0 100% 0);
  }
}
