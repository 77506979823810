.c-calendar {
  &__sidebar {
    &__body {
      position: sticky;
      top: 92px;
    }

    &__container {
      transform: translateX(-12px);

      @include tabletAndDesktopOnly() {
        min-width: 250px;
      }
    }

    &__body {
      height: calc(100vh - 92px);
      overflow-y: scroll;
    }
  }

  &__featured {
    display: block;

    &__hero {
      position: relative;
      height: 0;
      overflow: hidden;
      border-radius: 16px;
      padding-bottom: 75%;

      &__title {
        font-size: 2rem;
      }

      @include desktopOnly() {
        padding-bottom: $aspectRatio21-9;
      }

      &:hover {
        .c-calendar__featured__hero__content__description {
          transform: translateY(0);
          opacity: 1;
        }

        .c-calendar__featured__hero__content__active_bg {
          transform: translateY(0);
          opacity: 1;
        }
      }

      &__content {
        &--with-bg {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.75) 0%,
            rgba(0, 0, 0, 0.2) 100%
          );
        }

        &__description {
          transform: translateY(20px);
          opacity: 0;
          transition: transform $transition, opacity $transition;
        }

        &__active_bg {
          background: rgba(0, 0, 0, 0.5);
          opacity: 0;
          transition: opacity $transition;
        }
      }
    }
  }

  &__day_row {
    &__grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 36px;

      @include tabletAndDesktopOnly() {
        grid-gap: 24px;
      }

      @include desktopOnly() {
        grid-template-columns: 1fr 1fr;
      }
    }

    &__grid_fill {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 36px;
    }
  }

  &__category_selector {
    &__item {
      border: transparent 1px solid;
      border-radius: 12px;
      margin-bottom: 12px;

      @include tabletAndDesktopOnly() {
        padding: 6px 12px;
        margin-bottom: 4px;
      }

      &:hover {
        @include tabletAndDesktopOnly() {
          border: colour(base, charcoal_grey) 1px solid;
        }
      }

      &--selected {
        .c-calendar__category_selector__item__image {
          background: colour(designSystem, brandA);
          background: linear-gradient(
            90deg,
            #{colour(designSystem, brandA)} 0%,
            #{colour(designSystem, brandB)} 100%
          );

          svg {
            fill: colour(base, white);
          }
        }
      }

      &:hover,
      &--selected {
        .c-calendar__category_selector__item__name {
          color: colour(base, white);
        }

        .c-calendar__category_selector__item__image {
          svg {
            fill: colour(base, white);
          }
        }
      }

      &__name {
        transition: color $transition;
        color: colour(base, dark_grey);
      }

      &__image {
        $size: 38px;
        width: $size;
        height: $size;
        border-radius: $size;
        background: colour(base, charcoal_grey);
        display: flex;
        align-items: center;
        justify-content: center;

        @include tabletAndDesktopOnly() {
          margin-right: 16px;
        }

        svg {
          transition: fill $transition;
          fill: colour(base, dark_grey);
        }

        &--skeleton {
          background: colour(base, almost_black);
        }
      }
    }
  }
}
