.c-originals-covers {
  &__number {
    border: 1px solid colour(workshop, originalsYellow);
    border-radius: 22px;
    display: inline;
    padding: 14px 15px;

    &:nth-child(1) {
      padding: 14px 19px;
    }

    &-selected {
      background-color: rgba(255, 212, 71, 0.15);
    }
    &-faded {
      opacity: 0.4;
      transition: opacity 0.5s;
    }
  }

  &__image {
    max-height: 400px;
  }

  &__background {
    opacity: 0.15;
    filter: grayscale(100%);
    transition: 250ms;
  }

  &__wrapper {
    @include desktopOnly() {
      width: 70%;
    }
  }

  &__description {
    @include desktopOnly() {
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 350;
    }
  }
}

.c-originals-structure {
  &__title {
    @include desktopOnly() {
      display: grid;
      grid-template-columns: 1.2fr 1.4fr 1fr;
    }
  }

  &__card {
    &__img,
    &__active {
      @include desktopOnly() {
        min-height: 318px;
        max-height: 318px;
      }
      min-height: 265px;
    }

    &__shadow {
      z-index: 1;
      position: absolute;
      padding: 18%;
      opacity: 0.85;
      bottom: 0;
      width: 100%;
      background-image: linear-gradient(to bottom, transparent, #000000);
    }

    &__name {
      opacity: 1;
    }

    &__info-label {
      z-index: 4;
    }

    &__description {
      z-index: 3;
      margin-top: auto;
      margin-bottom: 0;
      opacity: 0;
      transform: translateY(-110px);
      transition: opacity $transitionSlow, transform $transitionSlow;
      overflow: hidden;
    }

    &__active {
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity $transition;
      z-index: 2;
    }

    &:hover {
      .c-originals-structure__card__description {
        opacity: 1;
        transform: translateY(-140px);
      }

      .c-originals-structure__card__active {
        opacity: 1;
      }

      .c-originals-structure__card__name {
        opacity: 0;
        transition: opacity $transitionSlow;
      }
    }
  }
}

.c-originals-gallery {
  &__card {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px 0px;

    @include tabletAndDesktopOnly() {
      grid-gap: 24px;
      grid-template-columns: repeat(6, 1fr);
    }

    @include tabletOnly() {
      grid-gap: 16px;
    }

    &-each {
      img {
        @include desktopOnly() {
          max-height: 380px;
          min-height: 380px;
        }

        @include mobileAndTabletOnly() {
          max-height: 350px;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        grid-column: auto / span 2;
        @include desktopOnly() {
          max-height: 380px;
        }
      }

      &:nth-child(4),
      &:nth-child(5) {
        grid-column: auto / span 3;
        @include desktopOnly() {
          max-height: 500px;
        }
      }
    }
  }
}

.c-originals-overview {
  // cursor: none;
  &.t-dropdown-enter.t-dropdown-enter-active,
  &.t-dropdown-appear.t-dropdown-appear-active,
  &.t-dropdown-leave,
  &--closed {
    visibility: hidden;
    opacity: 0;
  }

  &__control {
    border: 3px solid colour(base, almost_black);
    padding: 18px 20px 16px 20px;
    border-radius: 50%;
    backdrop-filter: blur(2px) brightness(1.2);
    background-color: rgba(255, 255, 255, 0.2);

    &:hover {
      backdrop-filter: blur(0px);
      transition: all $transitionSlow;
    }
  }

  &__cursor {
    &-modal {
      width: 320px;
      height: 200px;
      background: linear-gradient(colour(base, black), colour(base, black))
          padding-box,
        linear-gradient(120deg, #5b31be, #9163ff) border-box;
      border-radius: 0.4rem;
      border: 2px solid transparent;
      margin-left: 42px;
      margin-top: -14px;

      &-title {
        background: linear-gradient(to right, #7a4ddb, #ffffff);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &__marquee {
    font-size: 6rem;
    cursor: default;

    h1 {
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      text-transform: uppercase;
    }
    .dark {
      -webkit-text-stroke-color: colour(base, sad_grey);
    }
    min-width: inherit !important;

    &-highlight {
      -webkit-text-stroke-width: 2px;
    }

    @include desktopOnly() {
      margin-bottom: -20px;
    }

    @include mobileOnly() {
      font-size: 3rem;
      margin-bottom: -6px;
    }

    @include tabletOnly() {
      font-size: 4rem;
      margin-bottom: -12px;
    }
  }

  &__episodes {
    &-watched {
      opacity: 40%;
    }
  }
}

.c-originals-hero {
  &__wrapper {
    @include tabletAndDesktopOnly() {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }

  &__blur {
    position: absolute;
    z-index: 2;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 40%,
      rgba(0, 0, 0, 0) 80%
    );
    filter: blur(30px);
    height: 240px;
    transform: scale(2);

    @include desktopOnly() {
      height: 300px;
    }

    @include mobileOnly() {
      top: 50px;
    }
  }

  input[type='range'] {
    top: -70px;
    left: -30px;
    transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    background-color: transparent;
    border: 3px solid colour(base, white);
    width: 100px;
    height: 30%;
    background-image: linear-gradient(colour(base, white), colour(base, white));
    background-repeat: no-repeat;
    padding: 0px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    cursor: pointer;
  }

  input[type='range']::-webkit-slider-runnable-track {
    visibility: none;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
  }

  &__title {
    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }

    &--s {
      @include desktopOnly() {
        font-size: 3rem;
      }
      font-size: 1.5rem;
    }

    &--m {
      @include desktopOnly() {
        font-size: 4.5rem;
        line-height: 4.3rem;
      }
      font-size: 2.5rem;
      line-height: 3rem;
    }

    &--l {
      @include desktopOnly() {
        font-size: 6rem;
        line-height: 5.6rem;
      }
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }
}

.c-originals-benefits {
  flex-wrap: wrap;
  gap: 44px 0px;

  @include tabletOnly() {
    width: 90%;
  }
}

.c-originals-quiz {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
  background-color: black;

  @keyframes horizontal-slide-in {
    0%{
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  @keyframes horizontal-slide-out {
    0%{
      opacity: 1;
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
      opacity: 0;
    }
  }

  @keyframes vertical-slide-in {
    0%{
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  &__content {
    max-width: 900px;
    background-color: black;
    text-align: center;
  }

  &__title-enter {
    animation: vertical-slide-in .4s ease-in-out;
  }

  &__title-exit {
    animation: horizontal-slide-out .4s ease-in-out;
  }

  &__question {
    line-height: 2.5rem;

    @include mobileOnly() {
      line-height: 2rem;
    }

    animation: horizontal-slide-in .4s ease-in-out;
  }

  &__question-subtitle {
    animation: horizontal-slide-in .6s ease-in-out;
  }

  &__answers {
    animation: horizontal-slide-in .8s ease-in-out;
  }

  &__submit-button {
    animation: horizontal-slide-in .10s ease-in-out;
  }

  &__answer {
    background-color: black;
    border-radius: 48px;
    padding: 20px;
    border: 1px solid colour(base, sad_grey);

    &:hover,
    &--selected {
      background-color: colour(base, sad_grey);
    }

    &--selected {
      border: 1px solid white;
    }

    &--highlighted-correct {
      border: 1px solid rgba(0, 255, 0, 0.5);
      background-color: rgba(0, 255, 0, 0.1);
    }

    &--highlighted-incorrect {
      border: 1px solid rgba(255, 0, 0, 0.5);
      background-color: rgba(255, 0, 0, 0.1);
    }
  }

  &__progress {
    &--parent {
      height: 2px;
      border-radius: 40px;
      margin: 50;
    }

    &--child {
      &--wrapper {
        height: 100%;
        background-color: colour(base, sad_grey);
      }

      &--content {
        height: 100%;
      background-color: white;
      text-align: right;
      transition: width 0.4s ease-in-out;
      }
    }
  }

  &__emoji-rain {
    &--wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-height: 100%;
      overflow: hidden;
    }

    &--emoji {
      position: absolute;
      animation: fadeOut 2s forwards;

      &__odd {
        animation-delay: 1.5s;
      }

      &__even {
        animation-delay: 2.5s;
      }

      &__third {
        animation-delay: 2s;
      }
    }
  }
}

.c-originals-player {
  &__wrapper {
    column-gap: 32px;
  }

  &__guide-btn,
  &__play-btn {
    border: 1px solid colour(base, sad_grey);
    border-radius: 40px;

    transition: all 0.2s ease-in-out;

    &:hover {
      background: colour(base, very_light_grey);
      color: colour(base, almost_black) !important;
    }
  }

  &__guide-btn-overview {
    border: 1px solid colour(base, charcoal_grey);
    color: colour(base, grey);
    border-radius: 40px;
    text-align: center;

    transition: all 0.2s ease-in-out;

    &:hover {
      border: 1px solid colour(base, sad_grey);
      color: colour(base, white);
    }
  }

  &__share-btn {
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &__title {
    @include mobileOnly() {
      line-height: 1.8rem;
    }
  }
}

.c-originals-featured-classes {
  &__list {
    gap: 32px;
  }

  &__card {
    img {
      border-radius: 8px;
      height: 200px;
    }
  }
}

.c-originals-player-playlist {
  max-height: 500px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(transparent, black);
  }

  &__videos {
    max-height: 450px;
    overflow: auto;
    row-gap: 24px;

    // &::after {
    //   content: '';
    //   position: absolute;
    //   bottom: 0;
    //   width: 100%;
    //   height: 40px;
    //   background: linear-gradient(transparent, black);
    // }
  }
}

.c-originals-video-card {
  cursor: pointer;

  &__title {
    transition: color 0.2s ease-in-out;
    font-weight: 500 !important;
  }

  &:hover {
    .c-originals-video-card__title {
      color: white;
    }
  }

  &__thumbnail {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;

    &--completed {
      &::after {
        content: '';
        background: black;
        opacity: 0.5;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    &::before {
      display: block;
      content: '';
      padding-bottom: 56.25%;
    }
  }

  &__progress-bar {
    background-color: red;
    height: 2px;
  }

  &:last-child {
    padding-bottom: 24px;
  }
}

.c-originals-progress-bar {
  &__icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.c-workshop-overview {
  &__play-btn {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    border: 1px solid colour(base, sad_grey);
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(0.9);
    }
  }

  &__thumbnail {
    &--img {
      @include tabletAndDesktopOnly() {
        min-width: 292px;
        max-width: 292px;
        min-height: 164px;
        max-height: 164px;
      }
    }

    &--time {
      background-color: rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(1px);

      &-watched {
        background-color: rgba(0, 0, 0, 0.5) !important;
        opacity: 100% !important;
      }
    }
  }
}

.c-originals-instructor-intro {
  &__sentence-one,
  &__sentence-two {
    font-family: 'Inter', sans-serif;
  }

  &__sentence-two,
  &__sentence-three {
    color: #c4c4c4;
  }

  &__sentence-one {
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 3rem;
  }

  &__sentence-two {
    font-weight: 300;
    line-height: 2rem;
  }

  @include desktopOnly() {
    &__sentence-one {
      font-size: 4rem;
      line-height: 5rem;
    }

    &__sentence-two {
      line-height: 2.5rem;
      font-size: 1.5rem;
    }
  }
}

.c-line-progress-bar {
  &__overall {
    height: 2px;
    background-color: colour(base, sad_grey);
    &--inner {
      background-color: colour(base, white);
      height: 2px;
    }
  }

  &__episode {
    height: 1px;
    background-color: colour(base, charcoal_grey);
    &--inner {
      height: 2px;
      background: linear-gradient(
        to right,
        colour(designSystem, brandA),
        colour(designSystem, brandB)
      );

      animation: leftToRight 1s linear;
    }

    @keyframes leftToRight {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }
}

@include transitionGroup('.t-play-pause', opacity, $transition, 0, 1);
