.c-instructor-dashboard {
  &__image {
    $size: 64px;
    width: $size;
    height: $size;
  }

  &__block {
    width: 180px;
    background: colour(base, sad_grey);

    @include mobileOnly() {
      width: 100%;
    }

    &:hover {
      background: #444;
    }
  }

  &__breakdown {
    @include tabletAndDesktopOnly() {
      padding-left: 112px;
    }

    &-wrapper {
      @include mobileOnly() {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;
      }
    }
  }

  &__assets_container {
    @include mobileOnly() {
      overflow-x: scroll;
      height: 500px;
    }
  }

  &__ig_asset {
    background: colour(base, sad_grey);
    flex-shrink: 0;

    @include tabletAndDesktopOnly() {
      width: 270px;
      height: 480px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
