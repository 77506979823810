.dds-input,
.dds-select {
  border-radius: 2em;
  border: none;
  background: colour(base, very_light_grey);

  &--size {
    &-l {
      padding: 12px 16px;

      @include desktopOnly() {
        padding: 16px 32px;
      }
    }
  }

  &--theme {
    &-light {
      background: colour(base, white);
    }

    &-dark {
      background: colour(base, almost_black);
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
      transition: border 250ms;

      &:focus {
        border: rgba(255, 255, 255, 0.3) 1px solid !important;
      }
    }

    &-dark--without-border {
      background: colour(base, charcoal_grey);
      caret-color: colour(base, light_grey);
      &::placeholder {
        color: colour(base, light_grey);
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:active {
      transform: none;
    }
  }
}

.dds-textarea {
  border-radius: $defaultBorderRadius / 4;
  box-shadow: $buttonShadowNormal;
  border: none !important;
  background: colour(base, very_light_grey) !important;
}

.dds-input__chat {
  border-radius: 2em !important;
  box-shadow: $buttonShadowNormal !important;
  border: none !important;
  background: colour(base, almost_black) !important;
  font-family: $font_stack;
  padding-left: 16px;

  &--disabled {
    @include desktopOnly() {
      padding: 18px 0 0 0 !important;
    }
  }
}

.dds-input__phone-code {
  input {
    text-indent: 80px;

    // this is to hide arrows from number input
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.dds-input__phone-code-logged-out-home {
  input {
    text-indent: 80px;
  }
}
