.c-text-circle {
  font-size: 12px;
  z-index: 1000;
  pointer-events: none;
  transition: width 0.1s ease-in-out, height 0.1s ease-in-out;
  top: 0;

  &--purple {
    .c-text-circle__char {
      color: #{colour(designSystem, brandA)};
    }
  }

  &--grey {
    .c-text-circle__char {
      color: #{colour(base, dark_grey)};
    }
  }

  &--yellow {
    .c-text-circle__char {
      color: #{colour(workshop, yellow)};
    }
  }

  &--green {
    .c-text-circle__char {
      color: #{colour(workshop, green)};
    }
  }

  &--pink {
    .c-text-circle__char {
      color: #{colour(workshop, pink)};
    }
  }

  &--light-blue {
    .c-text-circle__char {
      color: #{colour(workshop, lightBlue)};
    }
  }

  &--blue {
    .c-text-circle__char {
      color: #{colour(workshop, blue)};
    }
  }

  &__char-groups {
    height: 100%;
    width: 100%;
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-duration: 30s;
    animation-timing-function: linear;
  }

  &__char-group {
    letter-spacing: 4px;
    height: 100%;
    bottom: 50%;
    left: 50%;
    transform-origin: bottom;
  }

  &__char {
    transform-origin: bottom;
    transform: translateX(-50%);
    text-transform: uppercase;
  }

  &__icon {
    transition: transform 0.4s ease-in-out;
  }

  @keyframes spin {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
