a {
  transition: color $transition;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.u-link-brand,
.u-link-brand--dark {
  transition: all $transition;
  color: colour(brand);

  &:hover {
    color: colour(base, black);
  }
}

.u-link-brand--dark {
  &:hover {
    color: colour(base, white) !important;
  }
}

.u-link-black {
  transition: all $transition;
  color: colour(base, black);

  &:hover {
    color: colour(brand) !important;
  }
}

.u-link-black--alt {
  transition: all $transition;
  color: colour(base, black);

  &:hover {
    color: colour(base, dark_grey) !important;
  }
}

.u-link-grey {
  transition: all $transition;
  color: colour(base, grey);

  &:hover {
    color: colour(base, dark_grey) !important;
  }
}

.u-link-blue-alert {
  transition: all $transition;
  color: colour(other, blueAlert);

  &:hover {
    color: colour(base, very_light_grey) !important;
  }
}

.u-link-grey--alt {
  transition: all $transition;
  color: colour(base, grey);

  &:hover {
    color: colour(base, black);
  }
}

.u-link-dark-grey {
  transition: all $transition;
  color: colour(base, dark_grey);

  &:hover {
    color: colour(base, black);
  }
}

.u-link-dark-grey--alt {
  transition: all $transition;
  color: colour(base, dark_grey);

  &:hover {
    color: colour(base, light_grey) !important;
  }
}

.u-link-black-grey {
  transition: all $transition;
  color: colour(base, grey);

  &:hover {
    color: colour(base, black);
  }
}

.u-link-very-light-grey {
  transition: all $transition;
  color: colour(base, very_light_grey);

  &:hover {
    color: colour(base, grey);
  }
}

.u-link-light-grey {
  transition: all $transition;
  color: colour(base, light_grey);

  &:hover {
    color: colour(base, white);
  }
}

.u-link-devil-grey {
  transition: all $transition;
  color: colour(base, devil_grey);

  &:hover {
    color: colour(base, black);
  }
}

.u-link-alert {
  transition: all $transition;
  color: colour(other, alert);

  &:hover {
    color: colour(base, black);
  }

  &--alt {
    &:hover {
      color: colour(base, light_grey) !important;
    }
  }
}

.u-link-white {
  transition: all $transition;
  color: colour(base, white);

  &:hover {
    color: colour(base, dark_grey) !important;
  }

  &--alt {
    &:hover {
      transition: all $transition;
      color: colour(base, grey) !important;
    }
  }
}

.u-link-blue-alert {
  transition: all $transition;
  color: colour(base, light_grey);

  &:hover {
    color: colour(other, blueAlert) !important;
  }

  &--alt {
    &:hover {
      color: colour(other, blueAlert) !important;
    }
  }
}
