.c-native-player {
  &__video {
    width: 100%;
    height: 100%;
    position: relative;

    video {
      width: 100%;
      height: 100%;
    }

    &__overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;

      &__centre_controls,
      &__bottom_controls {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__centre_controls {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 350px;
        height: 150px;

        &__bg,
        &__content {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        &__bg {
          background: rgba(0, 0, 0, 0.4);
          filter: blur(30px);
          border-radius: 100%;
        }

        &__content {
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
        }
      }

      &__bottom_controls {
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }

  &__timestamp {
    width: 150px;
  }

  &__scrubber {
    $scrubberHeight: 6px;

    width: 100%;
    height: $scrubberHeight;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;

    &__playback_track,
    &__handle_track {
      width: 100%;
      height: $scrubberHeight;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__playback_track {
      background: rgba(255, 255, 255, 0.25);
      border-radius: 8px;
      overflow: hidden;

      &__played {
        height: $scrubberHeight;
        background: colour(base, white);
        width: 0%;
      }
    }

    &__handle_track {
      &__handle {
        $handleSize: 14px;
        width: $handleSize;
        height: $handleSize;
        border-radius: $handleSize;
        background: colour(base, white);
        position: absolute;
        top: 50%;
        left: 0;
        transition: none;
        transform: translate(-50%, -50%);
      }
    }
  }
}
