.c-grandfathered-homepage {
  &__hero_container {
    @include tabletAndDesktopOnly() {
      height: calc(100vh - #{$headerHeight});
    }
  }

  &__hero {
    &_background_img {
      transform: scale(1.7) translateY(20%);
    }
    &_video {
      height: 555px;
      width: 297px;
      border-radius: 60px;

      @include smallMobileOnly() {
        height: 500px;
        width: 265px;
        border-radius: 10px;
      }
    }
  }
}