.page-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  transition: all 300ms;
  display: flex;
  padding: gap();
  justify-content: center;
  align-items: center;
  z-index: z-index(modal);

  &.t-dropdown-enter.t-dropdown-enter-active,
  &.t-dropdown-appear.t-dropdown-appear-active,
  &.t-dropdown-leave,
  &--closed {
    visibility: hidden;
    opacity: 0;
  }

  &__cover {
    background: colour(other, lessTranslucentWhite);
    -webkit-tap-highlight-color: transparent;
    position: fixed;
    z-index: -1;
    transition: all 300ms;
    @include blurBackground();

    &--dark {
      background: colour(other, translucentBlack);
    }
  }

  &__content {
    background: colour(base, white);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    max-width: 100%;
    border-radius: $defaultBorderRadius / 2;

    &--dark {
      background: colour(base, charcoal_grey);
    }

    &--small {
      width: 420px;
    }

    &--medium {
      width: 480px;
    }

    &--large {
      width: 80vw;
      height: 85vh;
      border-radius: 0;
      max-height: none;
      max-width: 100vw;
      margin: -#{gap()};
    }

    &--fill-content {
      .page-modal__body {
        $headerHeight: 74px;
        height: calc(100% - #{$headerHeight});
      }
    }

    &--full-screen {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      max-height: none;
      max-width: 100vw;
      margin: -#{gap()};
    }

    @media screen and (min-height: 700px) {
      &--vertical-centered {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &--markdown {
      width: 540px;
      height: 785px;
    }

    &--invite-user-to-project {
      width: 480px;
      min-height: 340px;
      max-height: 820px;
    }

    &--invite-collaborator {
      width: 540px;
      height: 600px;
    }

    &--collaboration-info {
      width: 540px;
    }

    &--workshop-add-email {
      max-width: 100%;
      min-width: 500px;
    }

    &--project-likes,
    &--private-profile,
    &--share-project,
    &--workshop,
    &--workshop-flow-complete,
    &--comments {
      width: 650px;
    }

    &--workshop,
    &--workshop-flow-complete {
      height: 760px;
    }

    &--workshop-flow-complete {
      padding: 0;

      .c-modal__content {
        padding: 0;
      }
    }

    &--workshop-attendee-list {
      min-width: 700px;
      max-width: 700px;
      max-height: 67%;

      .global-modal__header {
        margin-bottom: 0;
      }

      .page-modal__body {
        padding-bottom: 0;
      }
    }

    &--mentor-followers {
      max-height: 68%;
    }
  }
}

.c-modal__content--scrollable {
  $headerHeight: 56px;

  overflow: hidden;
  height: calc(100% - #{$headerHeight});
}

#category-nav {
  @include mobileOnly() {
    padding: 0;
  }

  .page-modal__cover {
    background: rgba(255, 255, 255, 0.96);
    @include blurBackground();
  }

  > div {
    max-height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
