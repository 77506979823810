.dds-progress-dots {
  display: flex;
  justify-content: center;
  align-items: center;

  &__dot {
    $size: 8px;
    width: $size;
    height: $size;
    border-radius: $size;
    margin-left: 8px;
    margin-right: 8px;
    background: colour(base, white);
    opacity: 0.2;
    transition: transform $transition;

    &--active {
      opacity: 1;
      transform: scale(1.6);
    }
  }
}
