.c-discord-popup {
  width: 80%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;

  @include tabletAndDesktopOnly {
    width: 450px;
  }

  animation: slowRender 2s linear;

  @keyframes slowRender {
    0% {
      opacity: 0;
      transform: translate(-50%, -10%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
}
