.categories-grid {
  display: grid;
  grid-gap: 16px;

  &--default {
    grid-template-columns: repeat(2, 1fr);

    @include desktopOnly() {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &--2up-fixed {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  &__item {
    background: colour(base, skeleton_grey);
    transition: transform $transition;
    height: 0;
    overflow: hidden;
    padding-bottom: $aspectRatio16-9;
    border-radius: $defaultBorderRadius / 2;

    a,
    button {
      padding-bottom: $aspectRatio16-9;
      content: '';
      height: 0;
      display: block;
    }

    &--active {
      transform: scale(1.2);

      @include mobileOnly() {
        transform: scale(1.1) !important;
      }
      &:hover {
        transform: scale(1.2) !important;

        @include mobileOnly() {
          transform: scale(1.1) !important;
        }
      }
    }

    &--hoverable {
      @include tabletAndDesktopOnly() {
        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &__checkbox {
      position: absolute !important;
      right: 0;
      bottom: 40px;

      @include mobileOnly() {
        padding-left: 40px !important;
      }

      @include desktopOnly() {
        bottom: 44px;
        padding-left: 44px !important;
      }
    }
  }

  img {
    background-image: url('/images/categories/default.png');
  }
}

.c-explore-your-interests {
  &__category_item {
    background: colour(base, black);
    width: 120px;
    height: 75px;
    border-radius: $defaultBorderRadius / 2;

    img {
      border-radius: $defaultBorderRadius / 2;
    }

    @include desktopOnly() {
      width: 11.9vw;
      height: 7.15vw;
      max-width: 200px;
      max-height: 125px;
    }
  }
}
