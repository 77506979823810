table {
  width: 100%;
  border-collapse: collapse;
}

.lined-table {
  tr + tr td {
    border-top: 1px solid colour(base, light_grey);
  }

  td,
  th {
    padding: 20px 0;
  }
}
