.c-logged-in-homepage {
  &__skeleton {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }

  &__hero {
    position: relative;
    width: 100%;
    height: 400px;

    @include tabletAndDesktopOnly() {
      height: 80vh;
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__title_category {
      width: 100%;
      flex: 1;
      position: relative;
      display: flex;

      &__category_skeleton {
        margin-top: 38px;
      }

      &__title {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;

        &__button {
          position: absolute;
          bottom: 0;
          transform: translateY(35px);

          @include tabletAndDesktopOnly() {
            transform: translateY(50px);
          }
        }
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 36px;

    @include tabletOnly() {
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
    }

    @include desktopOnly() {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 24px;
    }
  }

  &__discover_more {
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    &__img {
      width: 80px;

      @include tabletAndDesktopOnly() {
        width: 120px;
      }
    }
  }
}
