.c-empty-state {
  &--background {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('/images/logo-watermark.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    min-height: 100%;

    @include tabletAndDesktopOnly() {
      min-height: 500px;
    }
  }

  .ribbons__container {
    .ribbons__ribbon {
      width: 100vw;
    }
  }

  &.ribbons__container--empty-project {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-150%, -50%);
  }

  &.ribbons__container--empty-feed {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.ribbons__container--empty-search-results {
    margin-top: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.ribbons__container--empty-user-work,
  &.ribbons__container--empty-user-projects {
    position: absolute;
    top: 15vh;
    left: 50%;
    transform: translate(-50%, -50%);

    @include tabletAndDesktopOnly() {
      top: 30vh;
    }
  }
}
