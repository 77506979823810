.c-subscription-settings {
  &__header {
    min-height: 200px;
  }

  &__cancel_modal {
    background: colour(base, black);
    width: 500px;

    .c-modal__content {
      overflow: hidden;
    }
  }
}

// Portal
.settings-overlay[data-portal='subscription_cancel_confirm'] {
  .settings-overlay__container {
    background: colour(base, black);
    overflow-x: hidden;
  }
}
