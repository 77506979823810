@include mobileOnly {
  .account-settings-header {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;

    & > .absolute--tl {
      left: 12px;
    }
  }
}

.manage-blocked-users {
  @include tabletAndDesktopOnly() {
    width: 300px;
    height: 400px;
  }

  .avatar {
    display: none;
  }

  &__user {
    div div:first-of-type {
      margin-right: 0 !important;
    }
  }
}
