.c-careers {
  color: colour(base, very_light_grey);

  &-sentence {
    @include tabletAndDesktopOnly() {
      line-height: 2rem;
    }
  }

  &-section-container {
    @include desktopOnly() {
      height: calc(100vh - 125px);
    }

    @include mobileAndTabletOnly() {
      flex-direction: column;
    }
  }

  &-iframe-container {
    @include desktopOnly() {
      height: calc(100vh - 125px);
      width: 100%;
    }
  }

  &-roles-container {
    border-radius: 50px;
    &:hover {
      border: 1px solid colour(base, dark_grey);
      transition: border $transitionSlow;
    }
  }
}
