.c-crypto-signup {
  &__modal {
    width: 80%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: fixed;
    top: 50%;
    left: 50%;

    animation: opacity $transition ease;

    @keyframes opacity {
      0% {
        opacity: 0.5;
      }

      100% {
        opacity: 1;
      }
    }

    @include tabletAndDesktopOnly {
      width: 460px;
      height: 450px;
    }
  }
}

// for built-in CSSTransition transition
.fadein-exit,
.fadein-appear,
.fadein-enter {
  opacity: 0;
}

.fadein-appear-active,
.fadein-enter-active,
.fadein-exit-active {
  opacity: 1;
  transition: all 3s;
}

.fadein-appear-done,
.fadein-enter-done,
.fadein-exit-done {
  opacity: 1;
}
