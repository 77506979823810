.c-maintain-mode {
  background: black;
  color: white;
  min-height: 100vh;

  @include mobileOnly() {
    padding-top: 32px;
  }

  @include tabletAndDesktopOnly() {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__wordmark,
  &__stay_in_touch {
    @include tabletAndDesktopOnly() {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__wordmark {
    height: 26px;

    @include mobileOnly() {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 32px;
    }

    @include tabletAndDesktopOnly() {
      top: 32px;
    }
  }

  &__stay_in_touch {
    bottom: 32px;
  }

  &__content {
    text-align: center;

    @include mobileOnly() {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include tabletAndDesktopOnly() {
      width: 400px;
    }

    &__wilf {
      $size: 250px;
      width: $size;
      height: $size;
      border-radius: $size;
    }
  }
}
