@mixin createWidths($breakpoint: null) {
  $breakpoint-suffix: '';

  @if $breakpoint != null {
    $breakpoint-suffix: \@#{$breakpoint};
  }

  .u-1\/1#{$breakpoint-suffix} {
    width: 100%;
  }

  .u-1\/1#{$breakpoint-suffix}-i {
    width: 100% !important;
  }

  .u-1\/2#{$breakpoint-suffix} {
    width: 50%;
  }

  .u-1\/3#{$breakpoint-suffix} {
    width: 33.333%;
  }

  .u-1\/4#{$breakpoint-suffix} {
    width: 25%;
  }

  .u-1\/5#{$breakpoint-suffix} {
    width: 20%;
  }

  .u-1\/8#{$breakpoint-suffix} {
    width: 12.5%;
  }

  .u-2\/3#{$breakpoint-suffix} {
    width: 66.666%;
  }

  .u-2\/5#{$breakpoint-suffix} {
    width: 40%;
  }

  .u-3\/4#{$breakpoint-suffix} {
    width: 75%;
  }

  .u-3\/5#{$breakpoint-suffix} {
    width: 60%;
  }

  .u-3\/8#{$breakpoint-suffix} {
    width: 37.5%;
  }

  .u-4\/5#{$breakpoint-suffix} {
    width: 80%;
  }

  .u-5\/8#{$breakpoint-suffix} {
    width: 62.5%;
  }

  .u-7\/8#{$breakpoint-suffix} {
    width: 87.5%;
  }

  .u-2\/1#{$breakpoint-suffix} {
    width: 200%;
  }

  .u-width-auto#{$breakpoint-suffix} {
    width: auto;
  }
}

@include createWidths();

@include mobileOnly() {
  @include createWidths(s);
}

@include tabletAndDesktopOnly() {
  @include createWidths(m);
}

@include desktopOnly() {
  @include createWidths(l);

  .u-w-initial\@l {
    width: initial;
  }
}

.u-max-100 {
  max-width: 100%;
}

.u-width-100-important {
  width: 100% !important;
}

.u-width-auto-i {
  width: auto !important;
}

.u-width-fill-available {
  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-available;
}
