.dds-tooltip {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(16px);
  z-index: z-index(modal) + 1;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  min-height: 42px;
  text-align: center;

  &--direction-right {
    transform: translateX(4px);
  }

  &--overflow {
    .dds-tooltip__content {
      max-height: 450px;
      overflow-y: scroll;
    }
  }

  &--style {
    &-default {
      .dds-tooltip__content,
      .dds-tooltip__arrow {
        background: colour(base, white);
      }
    }

    &-dark {
      .dds-tooltip__content,
      .dds-tooltip__arrow {
        background: colour(base, charcoal_grey);
      }
    }

    &-dark-rounded {
      .dds-tooltip__content,
      .dds-tooltip__arrow {
        background: colour(base, charcoal_grey);
      }

      .dds-tooltip__content {
        border-radius: 100px;
      }
    }
  }

  &--size {
    &-wrap {
      width: fit-content;
    }

    &-xs {
      width: 70px;
    }

    &-s {
      width: 200px;
    }

    &-m {
      width: 300px;
    }

    &-l {
      width: 550px;
    }
  }

  &__arrow {
    content: '';
    display: block;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
    border-radius: 5px;
    width: 40px;
    height: 40px;
    left: 50%;
    top: 16px;
    background: colour(base, white);

    &--direction-right {
      width: 20px;
      height: 20px;
      left: 2px;
      top: 50%;
    }
  }

  &--animate-position {
    transition: top $transition, left $transition;
  }

  &__content {
    padding: 16px;
    position: relative;
    z-index: z-index(modal) + 2;
    border-radius: 24px;
    min-height: 42px;
    background: colour(base, white);
  }

  &__dismiss {
    z-index: z-index(modal) - 2;
    cursor: default;
  }
}
