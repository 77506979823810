.c-split-screen {
  @include desktopOnly() {
    display: grid;
    height: 100vh;
    grid-template-columns: 1fr 1fr;
  }

  &-content {
    @include desktopOnly() {
      height: auto;
      max-height: 100vh;
      overflow: hidden;
    }

    &__inner {
      @include tabletAndDesktopOnly() {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        max-width: 405px;
      }
    }
  }
}
