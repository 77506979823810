.dds-user-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 32px;
  grid-column-gap: 40px;

  @include mobileOnly() {
    grid-template-columns: repeat(3, 1fr);
  }
}
