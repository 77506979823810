.banner {
  position: relative;
  left: -20px;
  width: 100vw;

  @include tabletOnly() {
    left: -32px;
  }

  @include desktopOnly() {
    position: absolute;
    left: 0;
  }

  &__content {
    margin-left: 20px;
    margin-right: 20px;

    @include tabletOnly() {
      margin-left: 32px;
      margin-right: 32px;
    }

    @include desktopOnly() {
      max-width: 1440px;
      padding-left: 48px;
      padding-right: 48px;
      margin: 0 auto;
    }
  }
}
