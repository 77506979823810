.campaign-daisie-x-tove-lo {
  &--category_header,
  &--project_categories,
  &--checkbox,
  &--typeahead {
    background-image: url('/images/icons/dxtl-icon.svg');
    background-repeat: no-repeat;
  }

  &--typeahead {
    background-size: 15px !important;
    padding-right: 24px !important;
  }

  &--project_categories {
    background-size: 15px !important;
    padding-right: 24px !important;
  }

  &--checkbox {
    background-size: 15px !important;
    padding-right: 34px !important;
  }

  &--category_header {
    text-transform: none;
    background-image: url('/images/icons/dxtl-icon.png');
    background-position: center right !important;
    background-size: 30px !important;

    @include tabletOnly() {
      background-size: 39px !important;
      padding-right: 60px !important;
    }

    @include desktopOnly() {
      background-size: 44px !important;
      padding-right: 70px !important;
    }
  }

  &--category-nav {
    &:after {
      top: 1em !important;

      @include tabletAndDesktopOnly() {
        top: 0.8em !important;
      }
    }
  }

  &--category-nav-icon {
    height: 28px;
    min-height: 28px;
    min-width: 24.5px;
    position: relative;
    top: 4px;
  }
}

.campaign-wonder-women {
  &--category_header,
  &--project_categories,
  &--checkbox,
  &--typeahead {
    background-image: url('/images/icons/ww-icon.svg');
    background-repeat: no-repeat;
  }

  &--category_header {
    background-position: 100% 46% !important;
  }

  &--icon {
    width: 32px;
  }
}

.campaign-daisie-x-tove-lo,
.campaign-wonder-women {
  &--category_navigation_icon {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    right: 0;
  }

  &--category_header,
  &--project_categories,
  &--checkbox,
  &--typeahead {
    background-repeat: no-repeat;
  }

  &--category_header {
    padding-right: 105px;
    background-position: 100% 26%;
    background-size: 42px;

    @include tabletAndDesktopOnly() {
      background-size: 85px;
    }
  }

  &--project_categories {
    padding-right: 30px;
    background-size: 25px;
    background-position: center right;
  }

  &--checkbox {
    background-size: 25px;
    background-position: 93% 50%;
  }

  &--typeahead {
    padding-right: 30px;
    background-size: 25px;
    background-position: center right;
  }
}
