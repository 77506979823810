$typeaheadHeightMobile: 150px;

.typeahead {
  z-index: 5;
  top: 100%;
  left: 0;
  height: auto;
  margin-top: 4px;
  overflow: scroll;
  background: colour(base, almost_black);

  @include mobileOnly() {
    max-height: $typeaheadHeightMobile;
  }

  &__sticky,
  &__item {
    padding: 16px;
    display: block;
    width: 100%;
  }

  &__item {
    text-align: left;
    &:hover {
      &--dark {
        background-color: colour(base, sad_grey);
      }
      &--light {
        background-color: colour(base, very_light_grey);
      }
    }
  }

  &__sticky {
    position: sticky;
    bottom: 0;
  }
}
