.dds-slide-toggle {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 2em;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 4px;
  color: colour(base, white);

  &:hover {
    .dds-slide-toggle__active--hover {
      opacity: 1;
    }
  }

  &__button {
    padding: 12px 10px;
    z-index: 3;
    display: flex;
    align-items: center;

    &:first-of-type {
      padding-left: 20px;
    }

    &:last-of-type {
      padding-right: 20px;
    }

    &--active {
      color: white;
    }
  }

  &__active,
  &__active--hover {
    $activePadding: 12px;

    height: calc(100% - #{$activePadding});
    position: absolute;
    top: 50%;
    left: $activePadding / 2;
    border-radius: 2em;
    transition: all 250ms;

    &--skip-transition {
      transition: none;
    }
  }

  &__active {
    z-index: 1;
    background: black;

    &--hover {
      z-index: 2;
      opacity: 0;
      background: rgb(133, 67, 227);
      background: linear-gradient(
        90deg,
        rgba(133, 67, 227, 1) 0%,
        rgba(99, 59, 235, 1) 100%
      );

      @include mobileOnly() {
        background: colour(base, black);
      }
    }
  }
}
