.c-list {
  max-height: 100%;

  &--scrollable {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
  }

  & > span {
    height: 0px;
    font-size: 0;
  }
}

.c-list--bordered {
  & > li {
    & + li {
      border-top: 1px solid colour(base, light_grey);
    }
  }

  &--spaced > li {
    @extend .u-split;
    padding-bottom: gap(ml);
    padding-top: gap(ml);

    @include tabletAndDesktopOnly {
      padding-bottom: gap(m);
      padding-top: gap(m);
    }
  }

  .borderless {
    border-top: none;
  }
}
