.a-slide-fade-in {
  animation-name: slideFadeIn;
  animation-duration: 500ms;
}

.a-slide-fade-out {
  animation-name: slideFadeOut;
  animation-duration: 500ms;
}

@keyframes slideFadeOut {
  from {
    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(-60px);
  }
}

@keyframes slideFadeIn {
  from {
    opacity: 0;
    transform: translateY(60px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
