body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: colour(base, black);
  color: colour(base, black);
  font: $font;
}

.screen-reader-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.text-indent {
  overflow: hidden;
  white-space: nowrap;
  text-indent: 200%;
}

.no-wrap {
  white-space: nowrap;
}

.text-inline {
  height: 1em;
}

.u-caps {
  text-transform: uppercase;
}

.u-no-caps {
  text-transform: none !important;
}

.u-underline {
  text-decoration: underline;
}

.u-word-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}

.u-word-break-all {
  word-break: break-all;
}

.u-overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.u-word-break-initial {
  word-break: initial;
}

.u-letter-spacing {
  letter-spacing: 1px;
}

.u-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .u-no-truncate {
    white-space: pre-wrap;
  }

  &--2l,
  &--3l,
  &--4l,
  &--5l,
  &--6l {
    white-space: pre-line;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: 100%;
    -webkit-line-clamp: 2;
  }

  &--3l {
    -webkit-line-clamp: 3;
  }

  &--4l {
    -webkit-line-clamp: 4;
  }

  &--5l {
    -webkit-line-clamp: 5;
  }

  &--6l {
    -webkit-line-clamp: 6;
  }
}

.u-capitalize {
  text-transform: capitalize;
}

.u-lowercase {
  text-transform: lowercase;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-book {
  font-weight: 350;
}

.u-bold {
  font-weight: 500;
}

.u-x-bold {
  font-weight: 700;
}

.u-pre-wrap {
  white-space: pre-wrap;
}

.u-line-through {
  text-decoration: line-through;
}

.content {
  * + * {
    margin-top: gap(ml);
  }

  ul {
    margin-left: 24px;
  }

  li {
    list-style: disc;

    & + li {
      margin-top: gap(s);
    }
  }

  a {
    color: colour(base, black);
    text-decoration: underline;
  }
}

.f-lynstone-light {
  &-1,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Lynstone';
    font-weight: 300;
  }
}

.f-lynstone-regular {
  &-1,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Lynstone';
    font-weight: 400;
  }
}

.f-lynstone-semibold {
  &-1,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Lynstone';
    font-weight: 500;
    letter-spacing: -0.05rem;
  }
}

.f-lynstone-book {
  &-1,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Lynstone';
    font-weight: 350;
    letter-spacing: -0.05rem;
  }
}

.f-lynstone-bold {
  &-1,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Lynstone';
    font-weight: 700;
    letter-spacing: -0.02rem;
  }
}

.f-lynstone-light {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Lynstone';
    font-weight: 300;
  }
}

.f-lynstone-regular {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Lynstone';
    font-weight: 400;
  }
}

.f-lynstone-semibold {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Lynstone';
    font-weight: 500;
    letter-spacing: -0.05rem;
  }
}

.f-lynstone-bold {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Lynstone';
    font-weight: 700;
    letter-spacing: -0.02rem;
  }
}

.f-synthemesc-regular {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Synthemesc';
  }
}

.f-pp-hatton-bold {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'PPHatton';
  }
}

.f-monument-extended-ultrabold {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'MonumentExtended';
  }
}

.f-obviously-extended-semibold {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'obviously-extended';
    font-weight: 600;
  }
}

.f-agrandir-wide-black-italic {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Agrandir';
  }
}

.f-wail {
  &-1,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'NewGroteskSquareTWO';
    text-transform: uppercase;
  }
}

.f-bellow {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'NewGroteskSquareTHREE';
    text-transform: uppercase;
  }
}

.f-shout {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'NewGroteskSquareFOUR';
    text-transform: uppercase;
  }
}

.f-yell {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'NewGroteskSquareFIVE';
    text-transform: uppercase;
  }
}

.f-holler {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'NewGroteskSquareSIX';
    text-transform: uppercase;
  }
}

.f-yodel {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'NewGroteskSquareSEVEN';
    text-transform: uppercase;
  }
}

.f-lynstone-light-1z,
.f-lynstone-regular-1z,
.f-lynstone-semibold-1z,
.f-lynstone-bold-1z,
.f-inter-light-1z,
.f-inter-medium-1z,
.f-inter-regular-1z,
.f-synthemesc-regular-z,
.f-pp-hatton-bold-1z,
.f-monument-extended-ultrabold-1z,
.f-agrandir-wide-black-italic-1z,
.f-obviously-extended-semibold-1z,
.f-yell-1z,
.f-shout-1z,
.f-bellow-1z,
.f-holler-1z,
.f-yodel-1z,
.f-wail-1z {
  font-size: 40px !important;
  font-size: 2.5rem;
  line-height: 44px;
  line-height: 2.75rem;

  @include tabletOnly() {
    font-size: 80px;
    font-size: 5rem;
    line-height: 64px;
    line-height: 4rem;
  }

  @include desktopOnly() {
    font-size: 104px;
    font-size: 6.5rem;
    line-height: 112px;
    line-height: 7rem;
  }
}

.f-lynstone-light-1,
.f-lynstone-regular-1,
.f-lynstone-semibold-1,
.f-lynstone-bold-1,
.f-lynstone-book-1,
.f-inter-light-1,
.f-inter-medium-1,
.f-inter-regular-1,
.f-synthemesc-regular-1,
.f-pp-hatton-bold-1,
.f-monument-extended-ultrabold-1,
.f-agrandir-wide-black-italic-1,
.f-obviously-extended-semibold-1,
.f-yell-1,
.f-shout-1,
.f-bellow-1,
.f-holler-1,
.f-yodel-1,
.f-wail-1,
.f-workshop-1,
.f-synthemesc-1,
.f-pp-hatton-1,
.f-agrandir-1,
.f-monument-extended-1 {
  font-size: 36px;
  font-size: 2.25rem;
  line-height: 35px;
  line-height: 2.1875rem;

  @include tabletOnly() {
    font-size: 64px;
    font-size: 4rem;
    line-height: 59px;
    line-height: 3.6875rem;
  }

  @include desktopOnly() {
    font-size: 80px;
    font-size: 5rem;
    line-height: 76px;
    line-height: 5rem;
  }
}

.f-lynstone-light-1a,
.f-lynstone-regular-1a,
.f-lynstone-semibold-1a,
.f-lynstone-bold-1a,
.f-lynstone-book-1a,
.f-inter-light-1a,
.f-inter-medium-1a,
.f-inter-regular-1a,
.f-synthemesc-regular-1a,
.f-pp-hatton-bold-1a,
.f-monument-extended-ultrabold-1a,
.f-agrandir-wide-black-italic-1a,
.f-obviously-extended-semibold-1a,
.f-yell-1a,
.f-shout-1a,
.f-bellow-1a,
.f-holler-1a,
.f-yodel-1a,
.f-wail-1a {
  font-size: 32px;
  font-size: 2rem;
  line-height: 36px;
  line-height: 2.25rem;

  @include tabletOnly() {
    font-size: 44px;
    font-size: 2.75rem;
    line-height: 48px;
    line-height: 3rem;
  }

  @include desktopOnly() {
    font-size: 64px;
    font-size: 4rem;
    line-height: 64px;
    line-height: 4rem;
  }

  &--reduce-height {
    line-height: 32px;
    line-height: 2rem;

    @include tabletOnly() {
      line-height: 48px;
      line-height: 2.8rem;
    }

    @include desktopOnly() {
      line-height: 55px;
      line-height: 3.4rem;
    }
  }

  &--extend-height {
    line-height: 42px;
    line-height: 2.6rem;

    @include tabletOnly() {
      line-height: 55px;
      line-height: 3.4rem;
    }

    @include desktopOnly() {
      line-height: 80px;
      line-height: 5rem;
    }
  }
}

.f-lynstone-light-1b,
.f-lynstone-regular-1b,
.f-lynstone-semibold-1b,
.f-lynstone-bold-1b,
.f-lynstone-book-1b,
.f-inter-light-1b,
.f-inter-medium-1b,
.f-inter-regular-1b,
.f-synthemesc-regular-1b,
.f-pp-hatton-bold-1b,
.f-monument-extended-ultrabold-1b,
.f-agrandir-wide-black-italic-1b,
.f-obviously-extended-semibold-1b,
.f-yell-1b,
.f-shout-1b,
.f-bellow-1b,
.f-holler-1b,
.f-yodel-1b,
.f-wail-1b {
  font-size: 24px;
  font-size: 2rem;
  line-height: 24px;
  line-height: 2rem;

  @include tabletOnly() {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 40px;
    line-height: 2.5rem;
  }

  @include desktopOnly() {
    font-size: 48px;
    font-size: 3rem;
    line-height: 48px;
    line-height: 3rem;
  }
}
.f-lynstone-light-2,
.f-lynstone-regular-2,
.f-lynstone-semibold-2,
.f-lynstone-bold-2,
.f-lynstone-book-2,
.f-inter-light-2,
.f-inter-medium-2,
.f-inter-regular-2,
.f-synthemesc-regular-2,
.f-pp-hatton-bold-2,
.f-monument-extended-ultrabold-2,
.f-agrandir-wide-black-italic-2,
.f-obviously-extended-semibold-2,
.f-yell-2,
.f-shout-2,
.f-bellow-2,
.f-holler-2,
.f-yodel-2,
.f-wail-2 {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 22px;
  line-height: 1.375rem;

  @include tabletOnly() {
    font-size: 32px;
    font-size: 2rem;
    line-height: 32px;
    line-height: 2rem;
  }

  @include desktopOnly() {
    font-size: 32px;
    font-size: 2rem;
    line-height: 32px;
    line-height: 2rem;
  }
}

.f-lynstone-light-2a,
.f-lynstone-regular-2a,
.f-lynstone-semibold-2a,
.f-lynstone-bold-2a,
.f-lynstone-book-2a,
.f-inter-light-2a,
.f-inter-medium-2a,
.f-inter-regular-2a,
.f-synthemesc-regular-2a,
.f-pp-hatton-bold-2a,
.f-monument-extended-ultrabold-2a,
.f-agrandir-wide-black-italic-2a,
.f-obviously-extended-semibold-2a,
.f-yell-2a,
.f-shout-2a,
.f-bellow-2a,
.f-holler-2a,
.f-yodel-2a,
.f-wail-2a {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 22px;
  line-height: 1.375rem;

  @include tabletOnly() {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 26px;
    line-height: 1.6rem;
  }

  @include desktopOnly() {
    font-size: 26px;
    font-size: 1.6rem;
    line-height: 29px;
    line-height: 1.8rem;
  }
}

.f-lynstone-light-3,
.f-lynstone-regular-3,
.f-lynstone-semibold-3,
.f-lynstone-bold-3,
.f-lynstone-book-3,
.f-inter-light-3,
.f-inter-medium-3,
.f-inter-regular-3,
.f-synthemesc-regular-3,
.f-pp-hatton-bold-3,
.f-monument-extended-ultrabold-3,
.f-agrandir-wide-black-italic-3,
.f-obviously-extended-semibold-3,
.f-yell-3,
.f-shout-3,
.f-bellow-3,
.f-holler-3,
.f-yodel-3,
.f-wail-3 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 19px;
  line-height: 1.2rem;

  @include tabletOnly() {
    font-size: 19px;
    font-size: 1.2rem;
    line-height: 19px;
    line-height: 1.2rem;
  }

  @include desktopOnly() {
    font-size: 19px;
    font-size: 1.2rem;
    line-height: 26px;
    line-height: 1.6rem;
  }
}

.f-lynstone-light-4,
.f-lynstone-regular-4,
.f-lynstone-semibold-4,
.f-lynstone-bold-4,
.f-lynstone-book-4,
.f-inter-light-4,
.f-inter-medium-4,
.f-inter-regular-4,
.f-synthemesc-regular-4,
.f-pp-hatton-bold-4,
.f-monument-extended-ultrabold-4,
.f-agrandir-wide-black-italic-4,
.f-obviously-extended-semibold-4,
.f-yell-4,
.f-shout-4,
.f-bellow-4,
.f-holler-4,
.f-yodel-4,
.f-wail-4 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 19px;
  line-height: 1.2rem;

  @include tabletOnly() {
    font-size: 16px;
    font-size: 1rem;
    line-height: 19px;
    line-height: 1.2rem;
  }

  @include desktopOnly() {
    font-size: 16px;
    font-size: 1rem;
    line-height: 19px;
    line-height: 1.2rem;
  }
}

.f-lynstone-light-5,
.f-lynstone-regular-5,
.f-lynstone-semibold-5,
.f-lynstone-bold-5,
.f-lynstone-book-5,
.f-inter-light-5,
.f-inter-medium-5,
.f-inter-regular-5,
.f-synthemesc-regular-5,
.f-pp-hatton-bold-5,
.f-monument-extended-ultrabold-5,
.f-agrandir-wide-black-italic-5,
.f-obviously-extended-semibold-5,
.f-yell-5,
.f-shout-5,
.f-bellow-5,
.f-holler-5,
.f-yodel-5,
.f-wail-5 {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 16px;
  line-height: 1rem;

  @include tabletOnly() {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 16px;
    line-height: 1rem;
  }

  @include desktopOnly() {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 16px;
    line-height: 1rem;
  }
}

.f-lynstone-light-6,
.f-lynstone-regular-6,
.f-lynstone-semibold-6,
.f-lynstone-bold-6,
.f-lynstone-book-6,
.f-inter-light-6,
.f-inter-medium-6,
.f-inter-regular-6,
.f-synthemesc-regular-6,
.f-pp-hatton-bold-6,
.f-monument-extended-ultrabold-6,
.f-agrandir-wide-black-italic-6,
.f-obviously-extended-semibold-6,
.f-yell-6,
.f-shout-6,
.f-bellow-6,
.f-holler-6,
.f-yodel-6,
.f-wail-6 {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 16px;
  line-height: 1rem;

  @include tabletOnly() {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 16px;
    line-height: 1rem;
  }

  @include desktopOnly() {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 16px;
    line-height: 1rem;
  }
}

.f-title-1 {
  font-family: $font_stack;
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 31px;
  line-height: 1.9375rem;

  @include tabletOnly() {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 34px;
    line-height: 2.125rem;
  }

  @include desktopOnly() {
    font-size: 32px;
    font-size: 2rem;
    line-height: 38px;
    line-height: 2.375rem;
  }
}

.f-title-2 {
  font-family: $font_stack;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 26px;
  line-height: 1.625rem;

  @include tabletOnly() {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 26px;
    line-height: 1.625rem;
  }

  @include desktopOnly() {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 29px;
    line-height: 1.8125rem;
  }
}

.f-title-3 {
  font-family: $font_stack;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 22px;
  line-height: 1.375rem;

  @include tabletOnly() {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 24px;
    line-height: 1.5rem;
  }

  @include desktopOnly() {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}

.f-text-1 {
  font-family: $font_stack;
  font-size: 16px;
  font-size: 1rem;
  line-height: 21px;
  line-height: 1.3125rem;

  @include tabletOnly() {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 26px;
    line-height: 1.625rem;
  }

  @include desktopOnly() {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 26px;
    line-height: 1.625rem;
  }
}

.f-text-2 {
  font-family: $font_stack;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 19px;
  line-height: 1.1875rem;

  @include tabletOnly() {
    font-size: 16px;
    font-size: 1rem;
    line-height: 21px;
    line-height: 1.312rem;
  }

  @include desktopOnly() {
    font-size: 16px;
    font-size: 1rem;
    line-height: 21px;
    line-height: 1.312rem;
  }
}

.f-text-3 {
  font-family: $font_stack;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem;

  @include tabletOnly() {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 18px;
    line-height: 1.125rem;
  }

  @include desktopOnly() {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 18px;
    line-height: 1.125rem;
  }
}

.f-text-4 {
  font-family: $font_stack;
  text-transform: uppercase;

  font-size: 10px;
  font-size: 0.625rem;
  line-height: 13px;
  line-height: 0.9285rem;

  @include tabletOnly() {
    font-size: 12px;
    font-size: 0.8571rem;
    line-height: 16px;
    line-height: 1rem;
  }

  @include desktopOnly() {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 16px;
    line-height: 1rem;
  }
}

.f-text-5 {
  font-family: $font_stack;

  font-size: 14px;
  font-size: 0.75rem;
  line-height: 14px;
  line-height: 0.875rem;

  @include tabletOnly() {
    font-size: 14px;
    font-size: 0.75rem;
    line-height: 16px;
    line-height: 1rem;
  }

  @include desktopOnly() {
    font-size: 14px;
    font-size: 0.75rem;
    line-height: 16px;
    line-height: 1rem;
  }
}

.f-text-6 {
  font-family: $font_stack;

  font-size: 10px;
  font-size: 0.625rem;
  line-height: 10px;
  line-height: 0.625rem;

  @include tabletOnly() {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 12px;
    line-height: 0.75rem;
  }

  @include desktopOnly() {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 12px;
    line-height: 0.75rem;
  }
}

.f-text-category-nav {
  font-size: 29px;
  font-size: 1.8rem;
  line-height: 29px;
  line-height: 1.8rem;

  @include desktopOnly() {
    font-size: 32px;
    font-size: 1.97rem;
    line-height: 29px;
    line-height: 1.8rem;
  }
}

.u-line-height {
  line-height: 1;

  &--workshop-title-extended {
    line-height: 5.5rem;

    @include tabletOnly() {
      line-height: 4.5rem;
    }

    @include mobileOnly() {
      line-height: 3rem;
    }
  }
}

.u-line-behind {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;

  &:before {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background-color: colour(base, light_grey);
    top: calc(50% - 1px);
    z-index: 0;
    position: absolute;
  }

  & > span {
    background-color: colour(base, white);
    padding: 0px 12px;
    z-index: 1;
  }

  &--black {
    color: colour(base, grey);
    & > span {
      background-color: colour(base, black);
      padding: 10px 20px;
    }

    &:before {
      background-color: colour(base, sad_grey);
    }
  }

  &--large {
    & > span {
      @extend .f-text-3;
    }
  }

  &--off-white {
    & > span {
      background-color: colour(base, off_white);
    }
  }

  &--dark {
    & > span {
      background-color: colour(base, black);
      color: colour(base, devil_grey);
    }

    &:before {
      background-color: colour(base, sad_grey);
    }
  }
}

@include mobileOnly() {
  .f-text-3\@s {
    font-family: $font_stack;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 18px;
    line-height: 1.125rem;

    @include tabletOnly() {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 18px;
      line-height: 1.125rem;
    }

    @include desktopOnly() {
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 18px;
      line-height: 1.125rem;
    }
  }
}

@include tabletAndDesktopOnly {
  .f-title-3\@m {
    font-family: $font_stack;
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 22px;
    line-height: 1.375rem;

    @include tabletOnly() {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px;
      line-height: 1.5rem;
    }

    @include desktopOnly() {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px;
      line-height: 1.5rem;
    }
  }
}

.f-inter-light {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
  }
}

.f-inter-medium {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
  }
}

.f-inter-regular {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
  }
}

.f-inter-bold {
  &-1,
  &-1z,
  &-1a,
  &-1b,
  &-2,
  &-2a,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
  }
}
