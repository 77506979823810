.c-search-input-container {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  height: 40px;

  .c-search-input {
    width: 100%;
    border: none;
    padding: 0;
    outline: none;
    background: none;

    &::-webkit-search-cancel-button {
      display: none;
    }

    @include mobileOnly {
      transform: none;
    }
  }
}

.c-search-filters {
  width: 100%;

  @include mobileOnly() {
    max-width: 100vw;
  }

  .typeahead-container input {
    text-align: left;

    @include tabletAndDesktopOnly() {
      padding-left: gap(m);
      padding-top: gap(m);
      padding-bottom: gap(m);
    }
  }

  &__dropdown {
    text-align: left;
    position: relative;
    padding-right: gap(xl);

    & ~ .c-dropdown {
      top: calc(100% + 8px);
      max-height: 30vh;
      overflow-y: scroll;
    }

    &:after {
      content: '';
      right: gap(sm);
      top: calc(50% - 2px);
      width: 6px;
      height: 6px;
      border-right: 2px solid colour(base, grey);
      border-bottom: 2px solid colour(base, grey);
      transform: translateY(-50%) rotate(45deg);
      position: absolute;

      @include tabletAndDesktopOnly() {
        top: 50%;
      }
    }

    @include tabletAndDesktopOnly() {
      padding-right: gap(xl);
    }
  }

  &__search-type {
    padding: 0;
  }

  &__lfc-check {
    border: 1px solid colour(base, grey);
    transition: 300ms all;

    .c-checkbox + label {
      padding: 8px 8px 8px 48px;

      &:before {
        top: 6px;
        left: 12px;
      }

      &:after {
        left: 22px;
        top: 10px;
      }

      @include tabletAndDesktopOnly() {
        padding: 12px 12px 12px 48px;

        &:before {
          top: 10px;
          left: 12px;
        }

        &:after {
          left: 22px;
          top: 14px;
        }
      }
    }

    &:hover {
      border-color: colour(base, black);
    }
  }

  &__sentence-start {
    min-width: 200px;
  }

  &__blur {
    position: absolute;
    height: 100%;
    width: 48px;
    top: 0;
    right: 0;
    background-color: colour(base, transparent);
    background-image: linear-gradient(
      to right,
      colour(base, transparent),
      colour(base, off_white)
    );
    z-index: 300;
  }
}
