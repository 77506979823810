.page {
  &__title {
    padding-bottom: gap(l);
    margin-bottom: gap(l);
  }

  &__footer {
    border-top: 1px solid colour(base, sad_grey);
    padding-top: gap(xl);
    margin-top: gap(xl);
  }

  @include mobileOnly {
    &__hero {
      margin-right: -#{gap(ml)};
      margin-left: -#{gap(ml)};
    }
  }

  @include tabletAndDesktopOnly {
    &__title {
      padding-bottom: 70px;
      padding-top: gap(ml);
      margin-bottom: 100px;
    }

    &__footer {
      margin-top: 120px;
    }
  }
}
