.dds-hero-blur-overlay {
  position: absolute;

  &--main {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
  }

  &--l {
    top: 0;
    left: 0;
    height: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    filter: blur(30px);
    width: 150px;
    transform: scale(1.5);

    @include desktopOnly() {
      width: 500px;
      transform: scale(1.5);
    }
  }

  &--r {
    top: 0;
    right: 0;
    height: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    filter: blur(30px);
    width: 150px;
    transform: scale(1.5);

    @include desktopOnly() {
      width: 500px;
      transform: scale(1.5);
    }
  }

  &--c {
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 30%,
      rgba(0, 0, 0, 0) 80%
    );
    filter: blur(30px);
    height: 240px;
    transform: scale(2);

    @include desktopOnly() {
      height: 400px;
      transform: scale(1.5);
    }
  }
}
