html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

* {
  box-sizing: inherit;
}

body {
  margin: 0;
}

// #root {
//   overflow: hidden;
// }

blockquote,
pre,
ol,
ul,
figure {
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  display: block;
  height: auto;
  border: none;
}

article,
aside,
figure,
footer,
header,
aside,
main,
nav {
  display: block;
}

iframe {
  border: none;
}

ul,
ol,
li {
  margin: 0;
  list-style: none;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  margin: 0;
  font-weight: inherit;
  line-height: normal;
  font-size: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0;
}

button {
  background: transparent;
  border: none;
  color: inherit;
  font: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  text-transform: initial;
  transition: all 300ms;

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
}
