.dds-button {
  display: flex;
  border-radius: 50px;
  box-shadow: $buttonShadowNormal;
  transition: opacity $transition, background-color $transition,
    border $transition;
  align-items: center;
  justify-content: center;
  opacity: 1;

  // GENERIC STATES
  &:hover {
    box-shadow: $buttonShadowHover;
  }

  &:active {
    transform: translateY(1.5px);
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    cursor: not-allowed;

    &:active {
      transform: none;
    }
  }

  &--disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;

    &:active {
      transform: none;
    }
  }

  // SIZES
  &--size {
    &-s {
      padding: 8px 16px;

      &--icon-only {
        padding: 8px;
        width: 34px;
        height: 34px;
      }
    }

    &-m {
      padding: 12px 20px;

      &--icon-only {
        padding: 12px;
        width: 44px;
        height: 44px;
      }
    }

    &-l {
      padding: 12px 32px;

      span {
        position: relative;
        top: 1px;
      }

      &--icon-only {
        padding: 12px;
        width: 68px;
        height: 68px;
      }
    }

    &-xl {
      padding: 16px 36px;
    }
  }

  // MODIFIERS
  &--wide {
    &-s {
      padding: 8px 32px;
    }

    &-m {
      padding: 12px 48px;
    }

    &-l {
      padding: 12px 64px;
    }
  }

  // STYLES
  &--default {
    // CSS can't transition gradient backgrounds, so we use the :before selector
    // to create a transparent element that sits behind the button content,
    // that fades in when hovered over
    font-weight: 500;
    background: colour(designSystem, brandA);
    background: linear-gradient(
      90deg,
      #{colour(designSystem, brandA)} 0%,
      #{colour(designSystem, brandB)} 100%
    );
    color: colour(base, white);
    position: relative;
    box-sizing: border-box;

    p,
    div {
      z-index: 1;
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: colour(designSystem, brandA);
      transition: opacity 250ms;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      border-radius: 50px;
    }

    @at-root a#{&}:hover::before,
      &:hover:enabled::before {
      opacity: 1;
    }

    @at-root a#{&}:focus,
      &:focus:not(.dds-button--no-focus):enabled {
      background: colour(base, white);
      color: colour(designSystem, brandA);

      &:hover {
        color: colour(base, white);
      }
    }
  }

  &--dark {
    background: colour(base, almost_black);
    color: colour(base, white);

    @at-root a#{&}:hover,
      &:hover,
      &:hover:enabled {
      background: colour(base, charcoal_grey);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      background: colour(base, white);
      color: colour(base, almost_black);
    }
  }

  &--dark-translucent {
    background: colour(other, translucentBlack);
    color: colour(base, white);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(other, translucentBlack);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      background: colour(other, translucentBlack);
      color: colour(base, almost_black);
    }
  }

  &--light {
    background: colour(base, white);
    color: colour(base, devil_grey);
    border: #{colour(base, white)} 1px solid;

    &--size-l {
      color: colour(base, black);

      @at-root a#{&}:hover,
        &:hover:enabled {
        color: #{colour(base, devil_grey)} !important;
      }
    }

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(base, white);
      color: colour(base, almost_black);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      background: colour(base, black);
      color: colour(base, white);

      &:hover {
        background: colour(base, white);
        color: colour(base, almost_black);
      }
    }
  }

  &--light-translucent {
    background: colour(other, translucentWhite);
    color: colour(base, white);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(other, translucentWhite);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      background: colour(other, translucentWhite);
      color: colour(base, almost_black);
    }
  }

  &--very-light-translucent {
    background: colour(base, charcoal_grey);
    color: colour(base, white);

    @at-root a#{&}:hover,
      &:hover,
      &:hover:enabled {
      background: colour(base, very_light_grey);
      color: colour(base, black);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      background: colour(base, very_light_grey);
      color: colour(base, black);
    }
  }

  &--very-light-translucent-danger {
    background: rgba(255, 255, 255, 0.15);
    color: colour(other, alert);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: rgba(255, 255, 255, 0.15);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      background: rgba(255, 255, 255, 0.15);
      color: colour(other, alert);
    }
  }

  &--discord {
    background: #6770e6;
    color: colour(base, white);
    font-weight: 500;

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: #7a82f5;
    }
  }

  &--danger {
    background: colour(base, white);
    color: colour(other, alert);
    font-weight: 500;

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(other, alert);
      color: colour(base, white);
      box-shadow: rgba(255, 0, 0, 0.1) 0 3px 8px;
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      background: colour(other, alert);
      color: colour(base, white);
    }
  }

  &--danger-invert {
    background: colour(other, alert);
    color: colour(base, white);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(base, white);
      color: colour(other, alert);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      background: colour(base, white);
      color: colour(other, alert);
    }
  }

  &--outline-light {
    background: none;
    border: #{colour(base, dark_grey)} 1px solid;
    color: colour(base, white);
    transition: border $transition;

    @at-root a#{&}:hover,
      &:hover,
      &:hover:enabled {
      border: 1px solid colour(base, light_grey);
      color: colour(base, white);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      border: 1px solid colour(base, light_grey);
      color: colour(base, white);
    }
  }

  &--outline-dark {
    border: #{colour(base, dark_grey)} 1px solid;
    color: colour(base, white);

    @at-root a#{&}:hover,
      &:hover,
      &:hover:enabled {
      background: colour(base, very_light_grey);
      border: 1px solid colour(base, very_light_grey);
      color: colour(base, almost_black) !important;
    }
  }

  &--outline-white {
    box-shadow: none;
    background: none;
    border: #{colour(base, very_light_grey)} 1px solid;
    color: colour(base, white);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(base, white);
      border: 1px solid colour(base, white);
      color: colour(base, almost_black) !important;
    }
  }

  &--outline-green {
    background: none;
    border: #{colour(other, go)} 1px solid;
    color: colour(other, go);
    transition: border $transition, color $transition, background $transition;

    @at-root a#{&}:hover,
      &:hover,
      &:hover:enabled {
      background: colour(other, go);
      color: colour(base, white);
    }
  }

  &--originals {
    background: colour(workshop, originalsYellow);
  }

  // SPECIAL FEATURES
  .counter-badge {
    position: absolute;
    top: -18px;
    right: -25px;

    &--size-l {
      font-size: 14px;
      right: -42px;
    }
  }

  &__show_on_hover {
    .dds-button {
      opacity: 0;
      pointer-events: none;
      transition: opacity 250ms;
    }

    &:hover {
      .dds-button {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__signup-login {
    padding: 18px 0;
  }
}
