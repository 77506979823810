label,
legend {
  padding-left: 0;
  padding-right: 0;
  display: block;
  cursor: pointer;
}

// disable autofill styling that may difficult visibility
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s, color 9999s ease-in-out 0s;
}

input,
textarea,
select {
  -webkit-appearance: none;
  background: #fff;
  display: block;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  border: none;
  outline: none;
  margin: 0;
  padding: 1em;
  font-size: 1rem;

  &:focus {
    border-color: colour(base, dark_grey);
  }

  &::placeholder {
    color: colour(base, dark_grey);
  }

  &.compact {
    @include mobileOnly() {
      font-family: $font_stack;
    }
  }
}

textarea,
select,
option,
input {
  font-family: $font_stack;
}

input[type='search'] {
  -webkit-appearance: textfield;
}

input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

[type='submit'] {
  -webkit-appearance: none;
}

input.input--dark {
  background: colour(base, charcoal_grey);
  border-color: colour(base, dark_grey);
  color: colour(base, white);

  &:focus {
    border-color: colour(base, white);
  }

  &::placeholder {
    color: colour(base, cloud_grey);
  }
}

textarea {
  font-family: inherit;
  min-height: 7.5rem;
}

select {
  cursor: pointer;
  background-image: url('data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS42NjggMS41MWw0Ljk3NCA0Ljk3NSA0Ljk0OC00Ljk1IiBzdHJva2U9IiNENURERTIiIHN0cm9rZS13aWR0aD0iMiIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  padding-right: 2.5rem;
  appearance: none;

  &[disabled] {
    opacity: 0.5;
  }

  &::-ms-expand {
    display: none;
  }
}

::-webkit-search-decoration {
  display: none;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;

  & + fieldset {
    margin-top: gap(m);
  }
}

.c-checkbox {
  & + label {
    position: relative;
    padding-left: 40px;
    display: block;

    &:before,
    &:after {
      content: '';
      position: absolute;
      transition: 300ms all;
    }

    &:before {
      border: 1px solid colour(base, grey);
      width: 22px;
      height: 22px;
      left: 0;
      top: 0px;
      border-radius: 24px;
    }

    &:after {
      content: '';
      width: 4px;
      height: 9px;
      border-bottom: 2px solid transparent;
      border-right: 2px solid transparent;
      left: 9px;
      top: 5px;
      transform: rotate(45deg);
    }
  }

  &:checked + label {
    &:before {
      background: colour(designSystem, brandA);
      background: linear-gradient(
        90deg,
        #{colour(designSystem, brandA)} 0%,
        #{colour(designSystem, brandB)} 100%
      );
      border-color: colour(base, white);
    }

    &:after {
      border-color: colour(base, white);
    }
  }

  .dds-cinematic-page--style-frosted-dark &,
  .u-bg-black & {
    & + label {
      &:before {
        background: colour(base, almost_black);
        border-color: rgba(255, 255, 255, 0.1);
      }

      &:after {
        background: colour(base, almost_black);
        border-color: colour(base, almost_black);
      }
    }
    &:checked + label {
      &:before {
        border: 1px solid colour(base, white);
      }

      &:after {
        border-color: colour(base, white);
      }
    }
  }
}

.radio-group {
  margin: 0;

  li + li {
    margin-top: gap();
  }

  input + label {
    display: block;
  }

  label {
    padding-left: 40px;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      border-radius: 100%;
      transition: 300ms opacity;
    }

    &:before {
      border: 1px solid colour(base, grey);
      width: 22px;
      height: 22px;
      left: 0;
      top: 0;
    }

    &:after {
      background: colour(base, black);
      width: 14px;
      height: 14px;
      left: 5px;
      top: 5px;
      opacity: 0;
    }

    span {
      display: block;
    }
  }

  &-dark-mode {
    label {
      &:after {
        background: colour(base, white) !important;
      }
    }
  }

  input:checked + label:after,
  input:focus + label:after {
    opacity: 1;
  }
}

.input-group {
  &--error,
  &--error-alt {
    input,
    select,
    textarea {
      border-color: colour(other, alert);
      padding-right: 40px;
      background-image: url('/images/icons/warning.svg');
      background-position: right 14px top 14px;
      background-repeat: no-repeat;

      &:focus {
        border-color: darken(colour(other, alert), 15%);
      }
    }

    output {
      color: colour(other, alert);
      display: block;
      font-weight: 400;
      padding-bottom: gap(m);
      font-size: 0.75em;
      text-align: center;
    }
  }

  &--error-alt {
    input,
    select,
    textarea {
      border-color: colour(base, grey);
      background-image: none;

      &:focus {
        border-color: colour(base, black);
      }
    }

    output {
      color: colour(other, blueAlert);
    }
  }
}

.input-group.input-group--toggle {
  align-items: center;

  & > label:first-child {
    padding-top: 0;
    margin: 0;
  }
}

.centered-form {
  min-height: 450px;
}

.avatar-picker {
  &__image {
    max-width: 137px;
    width: 40%;
  }
}
