.c-workshop {
  &__banner {
    @include mobileOnly() {
      padding-bottom: calc(env(safe-area-inset-bottom, 20px) + 20px) !important;
    }

    &__img {
      $size: 48px;
      width: $size;
      height: $size;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  &__mentor {
    &__link_wrapper {
      &:hover {
        color: colour(base, white) !important;
      }

      &__icon {
        $size: 24px;
        $spacingRight: 10px;
        $lineHeightOffsetMobile: 3px;
        $lineHeightOffsetTabletDesktop: 7px;

        width: $size;
        height: $size;
        position: relative;
        left: calc(-#{$size} - #{$spacingRight});
        top: $lineHeightOffsetMobile;

        @include tabletAndDesktopOnly() {
          top: $lineHeightOffsetTabletDesktop;
        }

        svg {
          transition: opacity $transition;
        }

        svg:nth-child(2) {
          opacity: 0;
        }
      }

      &:hover {
        .c-workshop__mentor__link_wrapper__icon svg:nth-child(2) {
          opacity: 1;
        }
      }
    }

    &__image_container {
      position: relative;
      height: 100vw;

      @include tabletOnly() {
        height: 59.21vw;
      }

      @include tabletAndDesktopOnly() {
        width: 47.62vw;
        max-width: 800px;
      }

      @include desktopOnly() {
        height: 48.21vw;
        max-height: 810px;
      }
    }

    &__image {
      position: absolute;

      &--big,
      &--small {
        overflow: hidden;
      }

      &--big {
        bottom: 0;
        left: 0;
        width: 74vw;
        height: 100vw;

        @include tabletAndDesktopOnly() {
          width: 33.75vw;
          height: 48.27vw;
          max-width: 567px;
          max-height: 811px;
        }
      }

      &--small {
        bottom: 0;
        right: 0;
        width: 40.65vw;
        height: 42.02vw;

        @include tabletAndDesktopOnly() {
          width: 20.65vw;
          height: 22.02vw;
          max-width: 347px;
          max-height: 370px;
        }
      }
    }
  }

  &__text_row {
    &__title {
      width: 100%;

      @include desktopOnly() {
        width: 200px;
      }
    }
  }

  &__sign-up {
    &__card-input {
      background: colour(base, almost_black);
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
      transition: border 250ms;

      &--focus {
        border: rgba(255, 255, 255, 0.3) 1px solid !important;
      }
    }
  }
}

.c-workshop-card {
  border-radius: $defaultBorderRadius;
  width: 100%;
  overflow: hidden;
  height: 400px;

  @include safariBorderRadiusFix();

  &--size-xs {
    height: 210px;
  }

  &__fade {
    width: 100%;
    height: 100px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }

  &__title {
    max-height: 8.6rem;
    overflow: hidden;
  }

  &__description {
    &--small {
      max-height: 2.6rem;
    }

    &--large {
      max-height: 4rem;
    }
  }
}

.c-workshop-flow-complete {
  &__header {
    transform: translateY(-25%);
    opacity: 0;
    animation-name: longFlowCompleteAnimation;
    animation-delay: 500ms;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    top: 45%;

    @include smallMobileOnly() {
      top: 92px !important;
    }

    @include tabletAndDesktopOnly() {
      top: 50%;
    }

    h1 {
      text-shadow: rgba(0, 0, 0, 0.45) 0 6px 40px;

      @include mobileOnly() {
        font-size: 3rem;
        line-height: 4rem;
      }
    }
  }

  &__meta {
    transform: translateY(20px);
    opacity: 0;
    animation-name: shortFlowCompleteAnimation;
    animation-delay: 2000ms;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
  }

  &__cta {
    transform: translateY(20px);
    opacity: 0;
    animation-name: shortFlowCompleteAnimation;
    animation-delay: 2000ms;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
  }

  &__close {
    opacity: 0;
    animation-name: staticFlowCompleteAnimation;
    animation-delay: 4000ms;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    margin-top: 20px;
    margin-right: 20px;
    background: colour(other, translucentBlack);
    border-radius: 100%;
    padding: 4px;

    @include tabletAndDesktopOnly() {
      margin-top: 28px;
      margin-right: 24px;
    }
  }

  &__mobile_prompt {
    animation-name: shortFlowCompleteAnimation;
    animation-delay: 4000ms;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translateY(20px);
  }

  @keyframes longFlowCompleteAnimation {
    from {
      transform: translateY(-25%);
      opacity: 0;
    }

    to {
      transform: translateY(-50%);
      opacity: 1;
    }
  }

  @keyframes shortFlowCompleteAnimation {
    from {
      transform: translateY(20px);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes staticFlowCompleteAnimation {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.c-workshop-thumbnail {
  width: 96px;
  border-radius: $defaultBorderRadius;
  overflow: hidden;

  @include tabletAndDesktopOnly() {
    width: 120px;
  }

  &--square {
    height: 96px;

    @include tabletAndDesktopOnly() {
      height: 120px;
    }
  }
}

.c-workshop-metadata {
  width: 100%;

  &__description {
    p {
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }
  }
}

.c-workshop-metadata-v2 {
  max-width: 900px;
}

.c-workshop-outcomes {
  &__left,
  &__right {
    img {
      width: 100%;
      border-radius: 8px;
    }
  }

  &__img-container {
    &::before {
      display: block;
      content: '';
      padding-bottom: 56.25%;
    }
  }

  @include tabletAndDesktopOnly() {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;

    &__right {
      img {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &__left {
      img {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &__label {
      display: flex;
      align-items: center;

      &--link {
        flex-direction: row-reverse;
        top: 165px;

        svg {
          transform: rotate(180deg);
        }
      }

      &--name {
        left: -24px;
        bottom: 0;
        transform-origin: left;
        transform: rotate(-90deg);
      }

      span {
        background: colour(designSystem, brandA);
        background: linear-gradient(
          90deg,
          #{colour(designSystem, brandA)} 0%,
          #{colour(designSystem, brandB)} 100%
        );
      }

      &--theme-yellow {
        a {
          color: colour(workshop, yellow);
        }
        span {
          background: colour(workshop, yellow);
        }
      }

      &--theme-green {
        a {
          color: colour(workshop, green);
        }
        span {
          background: colour(workshop, green);
        }
      }

      &--theme-pink {
        a {
          color: colour(workshop, pink);
        }
        span {
          background: colour(workshop, pink);
        }
      }

      &--theme-light-blue {
        a {
          color: colour(workshop, lightBlue);
        }
        span {
          background: colour(workshop, lightBlue);
        }
      }

      &--theme-blue {
        a {
          color: colour(workshop, blue);
        }
        span {
          background: colour(workshop, blue);
        }
      }

      span {
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }
    }
  }
}

.c-workshop-sentences {
  @media screen and (min-width: $x-small-desktop-size) {
    width: 95%;
  }

  @media screen and (min-width: $medium-desktop-size) {
    width: 75%;
  }

  &__h1 {
    font-size: 3.25rem;
    line-height: 3.75rem;
  }

  &__h2 {
    font-size: 2.25rem;
    line-height: 3.5rem;
  }

  &__p {
    font-size: 1rem;
    line-height: 1.8rem;
  }

  &__coloured {
    background: colour(designSystem, brandA);
    background: linear-gradient(
      90deg,
      #{colour(designSystem, brandA)} 0%,
      #{colour(designSystem, brandB)} 100%
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;

    font-size: 2rem;
    line-height: 3.25rem;

    // background clip properties have to be set here as well
    &--yellow {
      background: colour(workshop, yellow);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }

    &--green {
      background: colour(workshop, green);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }

    &--pink {
      background: colour(workshop, pink);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }

    &--light-blue {
      background: colour(workshop, lightBlue);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }

    &--blue {
      background: colour(workshop, blue);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }
  }

  &__cta {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  @include tabletOnly() {
    &__h1 {
      font-size: 5vw;
      line-height: 4.5rem;
    }

    &__h2 {
      font-size: 3rem;
      line-height: 4.5rem;
    }

    &__p {
      font-size: 1.3rem;
      line-height: 2.35rem;
    }

    &__coloured {
      font-size: 3.5vw;
      line-height: 4rem;
    }
  }

  @include desktopOnly() {
    &__h1 {
      font-size: 5vw;
      line-height: 6.75rem;
    }

    &__h2 {
      font-size: 2.5rem;
      line-height: 5rem;
    }

    &__p {
      font-size: 1.5rem;
      line-height: 2.5rem;
    }

    &__coloured {
      font-size: 3.5vw;
      line-height: 5.3rem;
    }

    &__cta {
      font-size: 5.5rem;
      line-height: 5.2rem;
    }
  }

  @media screen and (min-width: $medium-desktop-size) {
    &__h2 {
      font-size: 3.5rem;
    }
  }
}

.c-workshop-creator-carousel {
  & > div {
    top: 50vh;
    transform: translateY(-50%);
  }

  .carousel__track {
    transition: none;
  }

  .carousel__container {
    overflow: hidden;
  }
}

.c-workshop-creator-carousel,
.c-workshop-recommended-carousel {
  &__item {
    @include tabletAndDesktopOnly() {
      &::before {
        content: '';
        padding-bottom: 100%;
        display: block;
      }
    }
  }

  &__card {
    border-radius: 8px;

    @include desktopOnly() {
      opacity: 0.7;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  .carousel__track {
    padding-left: 64px;
  }

  @include mobileAndTabletOnly() {
    .carousel__track {
      padding-left: 24px;
    }
  }
}

.c-workshop-more-info {
  &:hover {
    background: colour(base, white);
    border: 1px solid colour(base, white);
    color: colour(base, almost_black);
    transition: $transition;
  }
}

.c-workshop-chat-img {
  min-height: 47vh !important;
  max-width: 65vw !important;
}

.c-workshop-share-button-mobile {
  margin-left: auto;
}

.c-workshop-instructor {
  &__name {
    font-size: 1.8rem;

    @include tabletAndDesktopOnly() {
      font-size: 2.25rem;
    }
  }
}

.c-workshop-what-you-learn {
  section {
    &:nth-child(odd) {
      flex-direction: column-reverse;
    }
  }

  &__title {
    top: 128px;
  }

  &__cards {
    display: flex;
    flex-wrap: nowrap;
  }

  &__card {
    width: 90%;
    height: 100%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;
    box-sizing: border-box;
    padding: 0 12px;
    opacity: 0.3;
    transition: opacity 0.3s ease-in-out;

    @include tabletOnly() {
      width: 70%;
    }

    @include desktopOnly() {
      width: 25%;
    }

    &--is-active {
      opacity: 1;
    }
  }

  &__image {
    width: 100%;

    &::before {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }
}
