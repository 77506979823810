@font-face {
  font-family: 'NewGroteskSquareONE';
  src:
    url('/fonts/NewGroteskSquareONE/NewGroteskSquareONE.woff') format('woff'),
    url('/fonts/NewGroteskSquareONE/NewGroteskSquareONE.otf') format('opentype'),
    url('/fonts/NewGroteskSquareONE/NewGroteskSquareONE.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NewGroteskSquareONE/NewGroteskSquareONE.eot'),
    url('/fonts/NewGroteskSquareONE/NewGroteskSquareONE.svg#NewGroteskSquareONE') format('svg');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'NewGroteskSquareTWO';
  src:
    url('/fonts/NewGroteskSquareTWO/NewGroteskSquareTWO.woff') format('woff'),
    url('/fonts/NewGroteskSquareTWO/NewGroteskSquareTWO.otf') format('opentype'),
    url('/fonts/NewGroteskSquareTWO/NewGroteskSquareTWO.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NewGroteskSquareTWO/NewGroteskSquareTWO.eot'),
    url('/fonts/NewGroteskSquareTWO/NewGroteskSquareTWO.svg#NewGroteskSquareTWO') format('svg');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'NewGroteskSquareTHREE';
  src:
    url('/fonts/NewGroteskSquareTHREE/NewGroteskSquareTHREE.woff') format('woff'),
    url('/fonts/NewGroteskSquareTHREE/NewGroteskSquareTHREE.otf') format('opentype'),
    url('/fonts/NewGroteskSquareTHREE/NewGroteskSquareTHREE.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NewGroteskSquareTHREE/NewGroteskSquareTHREE.eot'),
    url('/fonts/NewGroteskSquareTHREE/NewGroteskSquareTHREE.svg#NewGroteskSquareTHREE') format('svg');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'NewGroteskSquareFOUR';
  src:
    url('/fonts/NewGroteskSquareFIVE/NewGroteskSquareFOUR.woff') format('woff'),
    url('/fonts/NewGroteskSquareFOUR/NewGroteskSquareFOUR.otf') format('opentype'),
    url('/fonts/NewGroteskSquareFOUR/NewGroteskSquareFOUR.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NewGroteskSquareFOUR/NewGroteskSquareFOUR.eot'),
    url('/fonts/NewGroteskSquareFOUR/NewGroteskSquareFOUR.svg#NewGroteskSquareFOUR') format('svg');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'NewGroteskSquareFIVE';
  src:
    url('/fonts/NewGroteskSquareFIVE/NewGroteskSquareFIVE.woff') format('woff'),
    url('/fonts/NewGroteskSquareFIVE/NewGroteskSquareFIVE.otf') format('opentype'),
    url('/fonts/NewGroteskSquareFIVE/NewGroteskSquareFIVE.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NewGroteskSquareFIVE/NewGroteskSquareFIVE.eot'),
    url('/fonts/NewGroteskSquareFIVE/NewGroteskSquareFIVE.svg#NewGroteskSquareFIVE') format('svg');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'NewGroteskSquareSIX';
  src:
    url('/fonts/NewGroteskSquareSIX/NewGroteskSquareSIX.woff') format('woff'),
    url('/fonts/NewGroteskSquareSIX/NewGroteskSquareSIX.otf') format('opentype'),
    url('/fonts/NewGroteskSquareSIX/NewGroteskSquareSIX.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NewGroteskSquareSIX/NewGroteskSquareSIX.eot'),
    url('/fonts/NewGroteskSquareSIX/NewGroteskSquareSIX.svg#NewGroteskSquareSIX') format('svg');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'NewGroteskSquareSEVEN';
  src:
    url('/fonts/NewGroteskSquareSEVEN/NewGroteskSquareSEVEN.woff') format('woff'),
    url('/fonts/NewGroteskSquareSEVEN/NewGroteskSquareSEVEN.otf') format('opentype'),
    url('/fonts/NewGroteskSquareSEVEN/NewGroteskSquareSEVEN.eot?#iefix') format('embedded-opentype'),
    url('/fonts/NewGroteskSquareSEVEN/NewGroteskSquareSEVEN.eot'),
    url('/fonts/NewGroteskSquareSEVEN/NewGroteskSquareSEVEN.svg#NewGroteskSquareSEVEN') format('svg');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lynstone';
  src:
    url('/fonts/Lynstone/Lynstone-Light.woff') format('woff'),
    url('/fonts/Lynstone/Lynstone-Light.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lynstone';
  src:
    url('/fonts/Lynstone/Lynstone-Book.woff') format('woff'),
    url('/fonts/Lynstone/Lynstone-Book.otf') format('opentype');
  font-style: normal;
  font-weight: 350;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lynstone';
  src:
    url('/fonts/Lynstone/Lynstone-Regular.woff') format('woff'),
    url('/fonts/Lynstone/Lynstone-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lynstone';
  src:
    url('/fonts/Lynstone/Lynstone-SemiBold.woff') format('woff'),
    url('/fonts/Lynstone/Lynstone-SemiBold.otf') format('opentype');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Lynstone';
  src:
    url('/fonts/Lynstone/Lynstone-Bold.woff') format('woff'),
    url('/fonts/Lynstone/Lynstone-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

// let's use the google link for now
// @font-face {
//   font-family: 'Inter';
//   src: url('/fonts/Inter/Inter.woff2') format('woff2');
//   font-style: normal;
//   text-rendering: optimizeLegibility;
// }

@font-face {
  font-family: 'Synthemesc';
  src:
    url('/fonts/Synthemesc/Synthemesc W00 Regular.woff') format('woff')
    url('/fonts/Synthemesc/Synthemesc W00 Regular.ttf') format('truetype');
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'PPHatton';
  src:
    url('/fonts/PPHatton/PP Hatton Bold 700.woff') format('woff'),
    url('/fonts/PPHatton/PP Hatton Bold 700.otf') format('opentype');
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Agrandir';
  src:
    url('/fonts/Agrandir/Agrandir-WideBlackItalic.woff') format('woff'),
    url('/fonts/Agrandir/Agrandir-WideBlackItalic.otf') format('opentype');
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'MonumentExtended';
  src:
    url('/fonts/MonumentExtended/MonumentExtended-Ultrabold.woff') format('woff'),
    url('/fonts/MonumentExtended/MonumentExtended-Ultrabold.otf') format('opentype');
  font-style: normal;
  text-rendering: optimizeLegibility;
}

// Alex Jenkins Class

@font-face {
  font-family: 'NeueMontreal';
  src:
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-Light.woff') format('woff'),
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-Light.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'NeueMontreal';
  src:
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-LightItalic.woff') format('woff'),
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-LightItalic.otf') format('opentype');
  font-style: italic;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'NeueMontreal';
  src:
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-Regular.woff') format('woff'),
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'NeueMontreal';
  src:
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-Italic.woff') format('woff'),
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-Italic.otf') format('opentype');
  font-style: italic;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'NeueMontreal';
  src:
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-Medium.woff') format('woff'),
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-Medium.otf') format('opentype');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'NeueMontreal';
  src:
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-MediumItalic.woff') format('woff'),
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-MediumItalic.otf') format('opentype');
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'NeueMontreal';
  src:
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-Bold.woff') format('woff'),
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'NeueMontreal';
  src:
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-BoldItalic.woff') format('woff'),
    url('/AlexJenkinsClass/fonts/NeueMontreal/NeueMontreal-BoldItalic.otf') format('opentype');
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Originals';
  src:
    url('/AlexJenkinsClass/fonts/Originals/Originals-Regular.woff') format('woff'),
    url('/AlexJenkinsClass/fonts/Originals/Originals-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}
