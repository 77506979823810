/* Height */
.u-mh-100 {
  max-height: 100%;
}

.u-mh-100vh {
  max-height: 100vh;
}

.u-mh-50vh {
  max-height: 50vh;
}

.u-min-height-100 {
  min-height: 100%;
}

.u-h-100 {
  height: 100%;
}

.u-h-100vh {
  height: 100vh;
}

.u-h-75vh {
  height: 75vh;
}

.u-h-50vh {
  height: 50vh;
}

.u-h-0 {
  height: 0;
}

.u-show-on-hover {
  display: none;

  :hover > & {
    display: initial;
  }
}

.u-height-auto {
  height: auto !important;
}

/* Border */
.bh {
  border-left: 1px solid colour(base, light_grey);
  border-right: 1px solid colour(base, light_grey);

  &--dark-grey {
    border-left: 1px solid colour(base, dark_grey);
    border-right: 1px solid colour(base, dark_grey);
  }

  &--white {
    border-left: 1px solid colour(base, white);
    border-right: 1px solid colour(base, white);
  }

  &--white-hint {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.bv {
  border-top: 1px solid colour(base, light_grey);
  border-bottom: 1px solid colour(base, light_grey);

  &--dark-grey {
    border-top: 1px solid colour(base, dark_grey);
    border-bottom: 1px solid colour(base, dark_grey);
  }

  &--white {
    border-top: 1px solid colour(base, white);
    border-bottom: 1px solid colour(base, white);
  }

  &--white-hint {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}

@include mobileOnly() {
  .bv\@s {
    border-top: 1px solid colour(base, light_grey);
    border-bottom: 1px solid colour(base, light_grey);
  }

  .bt\@s {
    border-top: 1px solid colour(base, light_grey);

    &--dark-grey {
      border-top: 1px solid colour(base, dark_grey);
    }

    &--charcoal-grey {
      border-top: 1px solid colour(base, charcoal_grey);
    }
  }
}

.bt {
  border-top: 1px solid colour(base, light_grey);

  &--dark-grey {
    border-top: 1px solid colour(base, dark_grey);
  }

  &--sad-grey {
    border-top: 1px solid colour(base, sad_grey);
  }

  &--white-hint {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.br {
  border-right: 1px solid colour(base, light_grey);

  &--white-hint {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.bb {
  border-bottom: 1px solid colour(base, light_grey);
}

.bl {
  border-left: 1px solid colour(base, light_grey);
}

.bl--dark-grey--thick {
  border-left: 2px solid colour(base, dark_grey);
}

.b--brand--dashed {
  border: 2px dashed colour(brand);
}

.b--devil-grey {
  border: 1px solid colour(base, devil_grey);
}

.bb--black {
  border-bottom: 1px solid colour(base, black);
}

.br--dark-grey {
  border-right: 1px solid colour(base, dark_grey);
}

.br--sad-grey {
  border-right: 1px solid colour(base, sad_grey);
}

.br--very-translucent-white {
  border-right: 1px solid colour(other, veryTranslucentWhite);
}

.bt--very-light-grey {
  border-top: 1px solid colour(base, very_light_grey);
}

.bb--very-light-grey {
  border-bottom: 1px solid colour(base, very_light_grey);
}

.bb--dark-grey {
  border-bottom: 1px solid colour(base, dark_grey);
}

.bb--charcoal-grey {
  border-bottom: 1px solid colour(base, charcoal_grey);
}

.bb--sad-grey {
  border-bottom: 1px solid colour(base, sad_grey);
}

.bb--devil-grey {
  border-bottom: 1px solid colour(base, devil_grey);
}

.b--none {
  border: none;
}

.bb--none {
  border-bottom: none !important;
}

.b--black {
  border: 1px solid colour(base, black);
}

.b--white {
  border: 1px solid colour(base, white);
}

.b--sad-grey {
  border: 1px solid colour(base, sad_grey);
}

.b--charcoal-grey {
  border: 1px solid colour(base, charcoal_grey);
}

.b--brand {
  border: 1px solid colour(brand);
}

.b--go {
  border: 1px solid colour(other, go);
}

.bb--translucent-black {
  border-bottom: 1px solid colour(other, veryTranslucentBlack);
}

.bb--translucent-white {
  border-bottom: 1px solid colour(other, translucentWhite);
}

.bb--very-translucent-white {
  border-bottom: 1px solid colour(other, veryTranslucentWhite);
}

@include mobileOnly() {
  .u-h-100\@s {
    height: 100%;
  }

  .bb\@s {
    border-bottom: 1px solid colour(base, light_grey);
  }

  .br\@s {
    border-right: 1px solid colour(base, light_grey);
  }

  .bb--very-translucent-white\@s {
    border-bottom: 1px solid colour(other, veryTranslucentWhite);
  }

  .u-word-break-all\@s {
    word-break: break-all;
  }
}

@include tabletAndDesktopOnly {
  .u-h-100\@m {
    height: 100%;
  }

  .u-h-100vh\@m {
    height: 100vh;
  }

  .u-h-90\@m {
    height: 90%;
  }

  .bb\@m {
    border-bottom: 1px solid colour(base, light_grey);
  }

  .br\@m {
    border-right: 1px solid colour(base, light_grey);
  }

  .b--sad-grey\@m {
    border: 1px solid colour(base, sad_grey);
  }

  .u-bg-brand\@m {
    background: linear-gradient(
      90deg,
      #{colour(designSystem, brandA)} 0%,
      #{colour(designSystem, brandB)} 100%
    );

    a:hover {
      color: colour(base, light_grey);
    }
  }
}

@include desktopOnly {
  .bb\@l {
    border-bottom: 1px solid colour(base, light_grey);
  }

  .br\@l {
    border-right: 1px solid colour(base, light_grey);
  }

  .b--none\@l {
    border: none;
  }

  .u-bg-almost-black\@l {
    background-color: colour(base, almost_black);
  }
}

/* border-radius */
.u-border-radius {
  border-radius: $defaultBorderRadius;

  &--top {
    border-radius: $defaultBorderRadius $defaultBorderRadius 0 0;
  }

  &--bottom {
    border-radius: 0 0 $defaultBorderRadius $defaultBorderRadius;
  }

  &--right {
    border-radius: 0 $defaultBorderRadius $defaultBorderRadius 0;
  }

  &--pill {
    border-radius: 2rem;
  }

  &--s {
    border-radius: $defaultBorderRadius / 2;
  }

  &--xs {
    border-radius: $defaultBorderRadius / 4;
  }
}

.u-rounded {
  border-radius: 100%;
}

/* Box-shadow */
.u-box-shadow {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

.u-box-shadow--top {
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.12);
}

.u-box-shadow--dark {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
}

.u-box-shadow--overlay--large {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 40px;
}

.u-box-shadow--bottom-banner {
  box-shadow: rgba(0, 0, 0, 0.12) 0 -2px 4px 0;
}

/* Colours */
.u-danger {
  color: colour(other, alert);

  &--i {
    color: colour(other, alert) !important;
  }
}

.u-dark-grey {
  color: colour(base, dark_grey);
}

.u-sad-grey {
  color: colour(base, sad_grey);
}

.u-grey {
  color: colour(base, grey);
}

.u-grey\@m {
  @include tabletAndDesktopOnly() {
    color: colour(base, grey);
  }
}

.u-black {
  color: colour(base, black);

  &--blurred {
    text-shadow: 0px -1px 2px rgba(0, 0, 0, 0.8);
    color: transparent;
  }
}

.u-go {
  color: colour(other, go);
}

.u-go\@m {
  @include tabletAndDesktopOnly() {
    color: colour(other, go);
  }
}

.u-coral {
  color: colour(other, coral);
}

.u-light-grey {
  color: colour(base, light_grey);
}

.u-charcoal-grey {
  color: colour(base, charcoal_grey);
}

.u-cloud-grey {
  color: colour(base, cloud_grey);
}

.u-white {
  color: colour(base, white);

  &--blurred {
    text-shadow: 0px -1px 2px rgba(255, 255, 255, 0.8);
    color: transparent;
  }

  &--i {
    color: colour(designSystem, white) !important;
  }
}

.u-brand {
  color: colour(designSystem, brandA);

  &--i {
    color: colour(designSystem, brandA) !important;
  }
}

.u-alert {
  color: colour(other, alert);
}

.u-blue-alert {
  color: colour(other, blueAlert);
}

.u-devil-grey {
  color: colour(base, devil_grey);
}

.u-very-light-grey {
  color: colour(base, very_light_grey);
}

.u-originals-yellow {
  color: colour(workshop, originalsYellow);
}

.u-google {
  color: colour(socialMedia, google);
}

.u-fb-messenger {
  color: colour(socialMedia, facebookMessenger);
}

/* Backgrounds */
.u-bg-transition {
  transition: background-color $transition;
}

.u-bg-none {
  background: none;
}

.u-bg-white {
  background-color: colour(base, white);
}

.u-bg-very-light-grey {
  background-color: colour(base, very_light_grey);
}

.u-bg-overcast-grey {
  background-color: colour(base, overcast_grey);
}

.u-bg-light-grey {
  background-color: colour(base, light_grey);
}

.u-bg-off-white {
  background-color: colour(base, off_white);
}

.u-bg-grey {
  background-color: colour(base, grey);
}

.u-bg-dark-grey {
  background-color: colour(base, dark_grey);
}

.u-bg-sad-grey {
  background-color: colour(base, sad_grey);
}

.u-bg-charcoal-grey {
  background-color: colour(base, charcoal_grey);
}

.u-bg-dark-charcoal-grey {
  background-color: colour(base, charcoal_grey);
}

.u-bg-brand {
  background: linear-gradient(
    90deg,
    #{colour(designSystem, brandA)} 0%,
    #{colour(designSystem, brandB)} 100%
  );

  a:hover {
    color: colour(base, light_grey);
  }
}

.u-bg-brand-pale {
  background-color: colour(brand, pale);
}

.u-bg-black {
  background-color: colour(base, black);

  &--i {
    background-color: colour(base, black) !important;
  }
}

.u-bg-almost-black {
  background-color: colour(base, almost_black);
}

.u-bg-translucent-black {
  background-color: colour(other, translucentBlack);

  &--shadow {
    box-shadow: #{colour(other, veryTranslucentWhite)} 0 0 20px;
  }
}

.u-bg-fade {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.u-bg-very-translucent-black {
  background-color: colour(other, veryTranslucentBlack);
}

.u-bg-less-translucent-black {
  background-color: colour(other, lessTranslucentBlack);
}

.u-bg-very-translucent-white {
  background-color: colour(other, veryTranslucentWhite);
}

.u-bg-translucent-white {
  background-color: colour(other, translucentWhite);
}

.u-bg-new-grey {
  background-color: colour(newPalette, grey);
}

.u-bg-gradient {
  background-color: colour(base, transparent);
  background-image: linear-gradient(
    to bottom,
    colour(base, transparent),
    colour(base, very_light_grey)
  );
}

.u-bg-closed-to-collab {
  background: colour(base, sad_grey);
}

.u-bg-gold {
  background-color: colour(other, gold);
}

.u-bg-go {
  background-color: colour(other, go);
}

.u-bg-facebook {
  background-color: colour(socialMedia, facebook);
}

.u-bg-twitter {
  background-color: colour(socialMedia, twitter);
}

.u-bg-instagram {
  background: colour(socialMedia, instagram);
}

.u-bg-reddit {
  background-color: colour(socialMedia, reddit);
}

.u-bg-whatsapp {
  background-color: colour(socialMedia, whatsapp);
}

.u-bg-yellow {
  background-color: colour(other, yellow);
}

.u-bg-alert {
  background-color: colour(other, alert);
}

.u-bg-skeleton {
  background-color: colour(base, skeleton_grey);
}

.u-fade-up--black {
  opacity: 0.8;
  height: 100%;
  background: linear-gradient(
    180deg,
    transparent 5%,
    transparent 25%,
    colour(other, lessTranslucentBlack) 100%
  );
}

.u-fade-up--white {
  height: 100%;
  background: linear-gradient(
    0deg,
    transparent 5%,
    transparent 25%,
    colour(other, lessTranslucentWhite) 100%
  );
}

.u-fade-up--text-block--dark {
  height: 100%;
  background: linear-gradient(
    180deg,
    transparent 5%,
    transparent 25%,
    colour(other, lessTranslucentBlack) 100%
  );
}

.u-fade-up--text-block--light {
  height: 100%;
  background: linear-gradient(
    180deg,
    transparent 50%,
    colour(other, veryTranslucentBlack) 75%,
    colour(other, translucentBlack) 100%
  );
}

.u-card {
  background: colour(base, white);
  box-shadow: $cardShadowNormal;
  transition: box-shadow $transition;
  border-radius: $defaultBorderRadius / 2;

  &--dark {
    background: colour(base, charcoal_grey);
  }
  &:hover {
    box-shadow: $cardShadowHover;
  }
}

/* Opacity */
.opacity-0 {
  opacity: 0;

  &--i {
    opacity: 0 !important;
  }
}

.opacity-1 {
  opacity: 1;

  &--i {
    opacity: 1 !important;
  }
}

.opacity-0-8 {
  opacity: 0.8;
}

.u-translucent {
  opacity: 0.5;
}

.u-very-translucent {
  opacity: 0.1;
}

/* Animation */
.animate-opacity {
  transition: opacity $transition;

  &--slow {
    transition: opacity $transitionSlow;
  }
}

.u-fade-in {
  transition: 300ms opacity;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

.u-animate--pulse {
  animation: pulse 3s infinite ease-in-out;
}

.u-animate--fadeIn {
  animation: fadeIn 2.5s ease-in;
}

/* SVG fills */
.u-fill-light-grey {
  fill: colour(base, light_grey);
}

/* Fit */
.u-object-cover {
  object-fit: cover;
}

.u-object-cover\@s {
  @include mobileOnly() {
    object-fit: cover;
  }
}

.u-object-contain {
  object-fit: contain;
}

.u-object-contain\@m {
  @include tabletAndDesktopOnly() {
    object-fit: contain;
  }
}

/* Overflow */
.u-overflow-hidden {
  overflow: hidden;
}

.u-overflow-hidden\@m {
  @include tabletAndDesktopOnly() {
    overflow: hidden;
  }
}

.u-overflow-hidden-fix {
  @include safariBorderRadiusFix();
}

.u-overflow-scroll {
  overflow: scroll;
}

.u-overflow-visible {
  overflow: visible !important;
}

.u-overflow-y-visible {
  overflow-y: visible;
}

.u-overflow-y-scroll\@s {
  @include mobileOnly() {
    overflow-y: scroll;
  }
}

.u-overflow-x-scroll {
  overflow-x: scroll;
}

.u-overflow-x-scroll\@s {
  @include mobileOnly() {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.u-overflow-x-scroll\@m {
  @include tabletAndDesktopOnly() {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.u-overflow-x-scroll\@l {
  @include desktopOnly() {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

.u-overflow-x-hidden {
  overflow-x: hidden !important;
}

.u-overflow-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

/* Random */
.u-white-on-hover {
  &:hover {
    transition: all $transition;
    color: colour(base, white);
  }
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-cursor-default {
  cursor: default;
}

.u-cursor-disabled {
  cursor: not-allowed;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-all {
  pointer-events: all;
}

.pointer-events-auto {
  pointer-events: auto;
}

.transition {
  &-bottom {
    transition: bottom $transition, opacity $transition;
  }

  &-none {
    transition: none;
  }
}

.u-word-break-all {
  word-break: break-all;
}

.transition-color {
  transition: color $transition;
}

.transition-delay {
  transition-delay: $transition;
}

.u-rotate-45deg {
  transition: transform $transition;

  &--apply {
    transform: rotate(45deg);
  }
}

.u-zoom-on-hover {
  transform: scale(1, 1);
  transition: transform $transition;

  &:hover {
    transform: scale(1.05, 1.05);
  }
}

.u-hide-scrollbar--2a {
  @include hide-horizontal-scrollbar(36px);

  .c-sub-navigation__seperator {
    height: 36px;
  }

  @include tabletAndDesktopOnly() {
    @include hide-horizontal-scrollbar(46px);

    .c-sub-navigation__seperator {
      height: 46px;
    }
  }
}

/* Responsive */
@include tabletAndDesktopOnly() {
  .mobile-only {
    display: none !important;
  }
}

@include desktopOnly() {
  .mobile-and-tablet-only {
    display: none !important;
  }
}

@include mobileAndDesktopOnly() {
  .tablet-only {
    display: none !important;
  }
}

@include mobileOnly() {
  .tablet-and-desktop-only {
    display: none !important;
  }

  .u-bg-charcoal-grey\@s {
    background-color: colour(base, charcoal_grey);
  }
}

@include mobileAndTabletOnly() {
  .desktop-only {
    display: none !important;
  }
}

.u-fit-content {
  width: fit-content;
  width: -moz-fit-content;
}

.u-h-fit-content {
  height: fit-content;
}

.u-filter-grayscale {
  filter: grayscale(1);
}

.u-filter-sepia {
  filter: sepia(1);
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  transform-origin: center bottom;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-delay: 5s;

  &--centred {
    -webkit-animation-name: bounceCentred;
    animation-name: bounceCentred;
  }
}

@mixin createBounceAnimation($name, $x: 0) {
  @keyframes #{$name} {
    0%,
    20%,
    53%,
    80%,
    100% {
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d($x, 0, 0);
    }

    40%,
    43% {
      -webkit-transition-timing-function: cubic-bezier(
        0.755,
        0.05,
        0.855,
        0.06
      );
      transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d($x, -10px, 0);
      transform: translate3d($x, -10px, 0);
    }

    70% {
      -webkit-transition-timing-function: cubic-bezier(
        0.755,
        0.05,
        0.855,
        0.06
      );
      transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d($x, -5px, 0);
      transform: translate3d($x, -5px, 0);
    }

    90% {
      -webkit-transform: translate3d($x, -2px, 0);
      transform: translate3d($x, -2px, 0);
    }
  }
}

@include createBounceAnimation('bounce');

@include createBounceAnimation('bounceCentred', -50%);

.u-aspect-square {
  padding-top: 100%;
  height: 0;
}

.u-simple-bullet-list,
.u-simple-number-list {
  li {
    margin-left: 24px;
  }
}

.u-simple-bullet-list {
  li {
    list-style-type: circle;
  }
}

.u-simple-number-list {
  li {
    list-style-type: decimal;
  }
}

.u-text-position-under {
  text-underline-position: under;
}

.u-scrollbar-disabled {
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  -webkit-appearance: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.share-button-icon {
  svg {
    height: 13px !important;
  }
}
