:root {
  --vh: 1vh;
}

.page-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  max-width: 100vw;
  position: relative;
  padding-top: $headerHeight;

  & > * {
    width: 100%;
  }

  main {
    flex: 1;
  }

  @include tabletAndDesktopOnly() {
    padding-top: $headerHeight;

    &[data-key-route='home'][data-auth='false'],
    &[data-key-route='students'] {
      // no padding for pages where the nav is hidden
      padding-top: 0;
    }
  }

  &[data-auth='false'] {
    &[data-key-route='home'] {
      background: colour(base, black);
    }

    &[data-key-route='workshopsAll'] {
      background: colour(base, off_white);
    }
  }

  $bannerHeight: 80px;

  .fixed-banner {
    display: none;

    [data-auth='false'][data-key-route='home'] & {
      .top {
        height: 72px;
      }

      .fixed-banner {
        display: block;
        position: fixed;
        top: 0;
        z-index: 500;
        height: $bannerHeight;

        & + header {
          top: $bannerHeight;
        }
      }

      &.page-layout--banner {
        padding-top: calc(#{$headerHeight} + #{$bannerHeight});
      }

      @include mobileOnly() {
        .fixed-banner {
          position: static;
          height: auto;
        }
        .page-layout--banner {
          padding-top: 0;
        }
      }
    }
  }

  &--cookie-banner {
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch;
    min-height: auto;
    position: absolute;

    header[role='banner'].visible,
    header[role='banner'].visible-no-transition {
      position: sticky !important;
    }

    main {
      @include mobileOnly() {
        padding-top: $headerHeight;
      }
    }
  }

  &--download-app-banner {
    width: 100vw;
    -webkit-overflow-scrolling: touch;
    min-height: auto;
    padding-top: 0 !important;

    header[role='banner'].visible,
    header[role='banner'].visible-no-transition {
      position: sticky !important;
    }

    main {
      @include mobileOnly() {
        padding-top: $headerHeight;
      }
    }
  }

  &--theme-yellow {
    .dds-button--default:not(.dds-button--no-theme) {
      background: colour(workshop, yellow);
      color: colour(base, almost_black);

      &::before {
        display: none;
      }

      &:hover {
        background: colour(workshop, yellowHover);
      }
    }
  }

  &--theme-green {
    .dds-button--default:not(.dds-button--no-theme) {
      background: colour(workshop, green);
      color: colour(base, almost_black);

      &::before {
        display: none;
      }

      &:hover {
        background: colour(workshop, greenHover);
      }
    }
  }

  &--theme-pink {
    .dds-button--default:not(.dds-button--no-theme) {
      background: colour(workshop, pink);
      color: colour(base, almost_black);

      &::before {
        display: none;
      }

      &:hover {
        background: colour(workshop, pinkHover);
      }
    }
  }

  &--theme-light-blue {
    .dds-button--default:not(.dds-button--no-theme) {
      background: colour(workshop, lightBlue);
      color: colour(base, almost_black);

      &::before {
        display: none;
      }

      &:hover {
        background: colour(workshop, lightBlueHover);
      }
    }
  }

  &--theme-blue {
    .dds-button--default:not(.dds-button--no-theme) {
      background: colour(workshop, blue);
      color: colour(base, white);

      &::before {
        display: none;
      }

      &:hover {
        background: colour(workshop, lightBlueHover);
      }
    }
  }

  &[data-key-route='appStoreReferral'],
  &[data-key-route='app'],
  &[data-key-route='getStarted'] {
    padding-top: 0 !important;

    header[role='banner'] {
      display: none;
    }

    main {
      padding-top: 0 !important;
    }
  }

  &[data-key-route='optimisedJoinFlowSignUpMonthlyPay'],
  &[data-key-route='optimisedJoinFlowSignUpYearlyPay'] {
    @include mobileOnly() {
      padding-top: 0 !important;
    }
  }

  &[data-key-route='optimisedJoinFlowChoosePlan'],
  &[data-key-route='optimisedJoinFlowSignUpMonthly'],
  &[data-key-route='optimisedJoinFlowSignUpYearly'],
  &[data-key-route='studentsChoosePlan'],
  &[data-key-route='studentsSignUpMonthly'],
  &[data-key-route='studentsSignUpYearly'],
  &[data-key-route='studentsSignUpMonthlyPay'],
  &[data-key-route='studentsSignUpYearlyPay'],
  &[data-key-route='optimisedJoinFlowSignUpMonthlyPay'],
  &[data-key-route='optimisedJoinFlowSignUpYearlyPay'],
  &[data-key-route='getDiscount'] {
    @include tabletAndDesktopOnly() {
      padding-top: 0 !important;

      header[role='banner'] {
        display: none;
      }

      main {
        padding-top: 0 !important;
      }
    }
  }

  &[data-key-route='calendar'],
  &[data-key-route='classes'] {
    overflow: initial;
  }
}

.gradient-separator {
  background: linear-gradient(
    0deg,
    #ededed 5.15%,
    rgba(246, 246, 246, 0.595941) 41.47%,
    rgba(246, 246, 246, 0) 95.02%
  );
  border: none;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  width: 100vw;
  height: 32px;

  &--nudge {
    margin-top: -12px;
  }

  @include tabletAndDesktopOnly() {
    height: 64px;
  }
}

.prevent-scroll-all {
  overflow: hidden;
  height: 100vh;
}

@include mobileOnly {
  .prevent-scroll-mobile,
  .prevent-scroll-mobile-no-nav {
    overflow: hidden;
    height: 100vh;
  }
}
