.user-content-styling {
  overflow: hidden;
  font-size: 1rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 24px;
    font-family: 'NewGroteskSquareFOUR';
    text-transform: uppercase;
  }

  @include tabletAndDesktopOnly() {
    [data-block-size='2'] & {
      font-size: 18px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 32px;
        line-height: 34px;
      }
    }

    [data-block-size='3'] & {
      font-size: 24px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 36px;
        line-height: 40px;
      }
    }

    .block--text &,
    [data-block-size='4'] & {
      font-size: 24px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 48px;
        line-height: 54px;
      }
    }
  }

  * + * {
    margin-top: 1em;
  }

  .block--text--align-center & li,
  .bp--text--align-center & li {
    list-style-position: inside;
    margin-left: 0;
  }

  li + li {
    margin-top: 0.5em;
  }

  ul li {
    list-style: outside disc;
    margin-left: 1.1em;
  }

  ul li p {
    display: inline;
  }

  ol {
    counter-reset: list;
  }

  ol li {
    padding-left: 1.5em;
    position: relative;
    counter-increment: list;
    pointer-events: none;

    & > * {
      pointer-events: all;
    }

    &:before {
      content: counter(list) '.';
      position: absolute;
      left: 0;
    }
  }

  code {
    font-size: 0.9em;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
      monospace;
  }

  a {
    text-decoration: underline;
    position: relative;
    z-index: 1;
  }

  &--discussion,
  &--discussion-cardboard {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-transform: none;
    }
  }

  &--discussion {
    & > *,
    code {
      @extend .f-title-3;
      font-family: $font_stack !important;
    }
  }

  &--discussion-cardboard {
    & > *,
    code {
      @extend .f-text-2;
      font-family: $font_stack !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      // can't get this to work, sorry
      // @extend .f-text-2;

      font-size: 14px;
      font-size: 0.875rem;
      line-height: 19px;
      line-height: 1.1875rem;

      @include tabletOnly() {
        font-size: 16px;
        font-size: 1rem;
        line-height: 21px;
        line-height: 1.312rem;
      }

      @include desktopOnly() {
        font-size: 16px;
        font-size: 1rem;
        line-height: 21px;
        line-height: 1.312rem;
      }
    }
  }
}
