.c-cookie-consent {
  position: fixed;
  z-index: z-index(cookieBar);

  background: colour(base, black);
  color: colour(base, white);

  @include tabletAndDesktopOnly() {
    border-bottom: #{colour(base, sad_grey)} 1px solid;
  }

  @include mobileOnly() {
    border-top: #{colour(base, sad_grey)} 1px solid;
    position: fixed;
    z-index: 999;
  }

  &[data-key-route='careers'],
  &[data-key-route='careersListing'],
  &[data-key-route='home'][data-auth='false'] {
    .c-cookie-consent__button {
      background-color: colour(base, black);
      border: #{colour(base, dark_grey)} 1px solid;
      color: colour(base, dark_grey);

      &:hover {
        border: #{colour(base, white)} 1px solid;
        color: colour(base, white);
      }
    }
  }

  a {
    border-bottom: 1px solid;
  }

  &__wrap {
    margin: 0 auto;
    @include mobileOnly() {
      margin: 2px 0 6px 0;
    }
    max-width: 740px;
  }

  &__button {
    width: 100%;
    padding: 4px 8px;
    border: #{colour(base, light_grey)} 1px solid;
    color: #000;

    @include tabletAndDesktopOnly() {
      width: 150px;
      padding: 8px;
    }
  }

  &__top {
    top: 58px;
  }
}
