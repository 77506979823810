.dds-avatar {
  $size: 24px;
  width: $size;
  height: $size;
  border-radius: $size;

  &__avatar {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__initials {
    font-size: 0.7rem;
  }

  &--card_attending {
    $size: 20px;
    width: $size;
    height: $size;
    border-radius: $size;
    border: black 2px solid;
    box-sizing: border-box;
  }

  &--fill-m,
  &--fill-l {
    width: 100%;
    padding-bottom: 100%;
    border-radius: 100%;
  }

  &--fill-m {
    .dds-avatar__initials {
      font-size: 3rem;
    }
  }

  &--fill-l {
    .dds-avatar__initials {
      font-size: 6rem;
    }
  }
}
