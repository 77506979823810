.c-notification {
  &__primary {
    font-size: 0.875em;
    line-height: 1.2em;
    margin-bottom: 4px;
    font-weight: 350;

    a {
      font-weight: 500;
      transition: color $transition;

      &:hover {
        color: colour(base, dark_grey) !important;
      }
    }
  }

  &__secondary {
    font-size: 0.75rem;
  }

  &__tertiary {
    font-size: 0.75rem;
  }

  &__quote {
    border-left: 1px solid colour(base, light_grey);
    padding: 2px 0px 2px 10px;
    font-size: 0.875em;
    line-height: 1.2em;
  }

  &__reply {
    font-size: 0.875em;
  }

  &--indent {
    margin: gap(m) 0 0 52px;
  }

  &__avatar {
    width: 1em;

    a {
      display: block;
    }

    // Avatar stacking
    a:first-child:nth-last-child(2) {
      &,
      & ~ * {
        width: 75%;
      }

      & ~ * {
        margin: -50% 0 0 25%;
      }
    }
  }

  &__icon {
    $icon-size: 24px;

    position: absolute;
    bottom: -5px;
    right: -5px;
    width: $icon-size;
    height: $icon-size;
    border-radius: $icon-size;
    background: colour(base, white);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image img {
    max-width: 250px;
  }

  &__images {
    overflow: hidden;
    white-space: nowrap;

    img {
      margin-right: 5px;
      height: 40px;
      width: auto;
      display: inline-block;
    }

    &--project-activity {
      margin-left: 50px;
    }
  }

  &--large {
    .c-notification__avatar {
      margin-right: 10px;
      font-size: 40px;
    }

    .c-notification__main-icon {
      margin-left: 2px;
      margin-right: 16px;

      @include tabletAndDesktopOnly {
        margin-left: 4px;
        margin-right: 20px;
      }
    }

    @include tabletAndDesktopOnly {
      .c-notification__primary {
        font-size: 1em;
        padding-top: gap(xs);
      }

      .c-notification__avatar {
        margin-right: gap(m);
        font-size: 48px;
      }

      .c-notification--indent {
        margin-left: 64px;
      }

      .avatar {
        width: 48px;
        height: 48px;
      }
    }
  }

  &--medium {
    .c-notification__avatar {
      margin-right: 10px;
      font-size: 40px;
    }

    .c-notification__main-icon {
      margin-right: 4px;

      @include tabletAndDesktopOnly {
        margin-right: 8px;
      }
    }
  }

  &--small {
    .c-notification__avatar {
      margin-right: gap(s);
      font-size: 32px;
    }

    .c-notification--indent {
      margin-left: 42px;
    }
  }
}
.c-notifications__dropdown {
  -webkit-overflow-scrolling: touch;
  overflow-x: visible;
  overflow-y: scroll;
  max-height: 650px;
  height: calc(100vh - #{$headerHeight} - 20px);

  .c-list {
    max-height: none;
  }
}
