.c-categories-list {
  &__category-link {
    text-align: center;

    @include tabletAndDesktopOnly() {
      white-space: nowrap;
    }

    span {
      transition: all 300ms;
    }

    &:hover {
      span {
        color: colour(brand, default);
      }
    }

    &:after {
      content: '';
    }

    &:nth-last-child(n + 2) {
      &:after {
        content: ' / ';
      }
    }

    &--small {
      &:nth-last-child(n + 2) {
        &:after {
          content: '/';
          margin-left: 8px;
          color: colour(base, grey) !important;
        }
      }

      &:hover {
        &:nth-last-child(n + 2) {
          &:after {
            color: colour(base, grey) !important;
          }
        }
      }
    }
  }
}

// for interest preferences and onboarding and see more categories on home
.category {
  background: colour(base, white);
  transition: 300ms transform ease;

  & > * {
    background: colour(base, black);
  }

  &__title {
    transform: translateY(-50%);
    height: auto;
    top: 50%;
  }

  &__aspect,
  &__aspect--square {
    content: '';
    height: 0;
    display: block;
  }

  &__aspect {
    padding-bottom: 65%;
  }

  &__aspect--square {
    padding-bottom: 100%;
  }

  img {
    opacity: 0.6;
    background: url('/images/categories/default.png');
  }
}
