.c-press-links {
  &__logo {
    height: 16px;
    transition: opacity $transition;

    @include tabletAndDesktopOnly() {
      height: 32px;
    }

    &--light {
      opacity: 0.75;
    }

    &--dark {
      filter: invert(1);
      opacity: 0.5;
    }

    &:hover {
      opacity: 0.9;
    }
  }
}
