.c-mobile-app-prompt {
  background: colour(base, #ffffff);
  width: 100%;
  text-align: center;

  &--ios {
    border-radius: 20px;
    padding: $defaultBorderRadius;
  }

  &--android {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: $defaultBorderRadius;
    border-radius: 20px 20px 0 0;
  }

  &--theme {
    &-dark {
      background: colour(base, almost_black);
    }

    &-dark-translucent {
      background: rgba(0, 0, 0, 0.85);
    }
  }
}
