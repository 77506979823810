.dds-cinematic {
  overflow: hidden;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: $headerHeight;

  @include desktopOnly() {
    overflow: initial;
    padding-top: 0;
    height: 100%;
  }

  &__container_wrapper {
    flex-direction: column;

    @include desktopOnly() {
      flex-direction: row;
    }
  }

  &__container {
    width: 100%;
    transition: transform $transition, opacity $transition;

    &--nudge-right {
      transform: translateX(44px);
    }

    &__fix {
      width: 100%;
      max-width: 640px;
    }

    @include desktopOnly() {
      width: 50%;
    }

    &:first-of-type {
      @include desktopOnly() {
        padding-right: 32px;
      }
    }

    &:last-of-type {
      @include desktopOnly() {
        padding-left: 32px;
      }
    }
  }
}
