.marketing-wrapper {
  display: grid;
  grid-gap: gap(l);

  @include tabletAndDesktopOnly {
    grid-template-columns: minmax(150px, 320px) minmax(450px, 888px);
    justify-content: space-between;
  }

  &__main {
    &__markdown {
      $table_colour: colour(base, light_grey);

      width: 100%;
      word-break: break-word;

      h1 {
        display: none;
      }

      h2,
      h3,
      h4 {
        scroll-margin-top: $headerHeight;
      }

      h2 {
        @extend .f-title-2;
        font-weight: 700;
        margin-top: 80px;
        margin-bottom: 12px;
      }

      h3 {
        @extend .f-title-2;
        font-weight: 500;
        margin-top: 64px;
        margin-bottom: 16px;
      }

      h4 {
        font-weight: 500;
        text-decoration: underline;
      }

      a {
        text-decoration: underline;
        font-weight: 500;
        transition: color $transition;

        &:hover {
          color: colour(base, dark_grey);
        }
      }

      h4,
      p,
      ul li,
      ol li {
        @extend .f-text-1;
        margin-bottom: 16px;
      }

      ul li,
      ol li {
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      ul,
      ol {
        padding-left: 32px;
        margin-bottom: 16px;
      }

      ul li {
        list-style-type: disc;
      }

      ol li {
        list-style-type: decimal;
      }

      table {
        @extend .f-text-1;
        margin-top: 32px;
        margin-bottom: 32px;
        border: $table_colour 1px solid;

        thead {
          border-bottom: $table_colour 1px solid;
        }

        tr {
          border-bottom: $table_colour 1px solid;

          &:last-child {
            border-bottom: none;
          }
        }

        td,
        th {
          padding: 8px;
          border-right: $table_colour 1px solid;

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }
}
