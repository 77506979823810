.c-card {
  &:hover {
    .c-card__meta__description {
      opacity: 1;
      transform: translateY(0px);
    }

    .c-card__meta__active_bg {
      opacity: 1;
    }
  }

  &__cover {
    height: 0;
    overflow: hidden;
    padding-bottom: $aspectRatio16-9;
    border-radius: 16px;
    transform: translateZ(0);

    &__radio {
      width: 60px;
      top: 35%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;

      @include tabletAndDesktopOnly() {
        width: 110px;
      }
    }
  }

  &__meta {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
    display: flex;
    flex-direction: column;

    &__active_bg {
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity $transition;
      z-index: 2;
    }

    &__description {
      z-index: 3;
      margin-top: auto;
      margin-bottom: 0;
      opacity: 0;
      transform: translateY(20px);
      transition: opacity $transition, transform $transition;
      overflow: hidden;
    }

    &__bottom {
      z-index: 3;
      display: flex;
      flex-direction: column;
    }
  }

  &__room_speakers {
    &--nudge-up {
      transform: translateY(-30px);
    }

    &__bubbles {
      display: flex;

      &--2up {
        transform: translateX(-10px);

        .c-card__room_speakers__bubble--1 {
          transform: translateX(10px);
        }
      }

      &--3up {
        .c-card__room_speakers__bubble--1 {
          transform: translateX(10px);
        }

        .c-card__room_speakers__bubble--2 {
          transform: translateX(0px);
          position: relative;
          z-index: 1;
        }

        .c-card__room_speakers__bubble--3 {
          transform: translateX(-10px);
        }
      }
    }

    &__bubble {
      $size: 42px;
      width: $size;
      height: $size;
      border-radius: $size;
      position: relative;

      &__pulse {
        width: $size;
        height: $size;
        border-radius: $size;
        position: absolute;
        left: 0;
        top: 0;
        animation-name: cardSpeakersBubblePulse;
        animation-duration: 3s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-delay: 1s;
      }

      img {
        border-radius: $size;
        object-fit: cover;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
      }

      &--1,
      &--2,
      &--3 {
        background: colour(base, sad_grey);
      }
    }
  }
}

@keyframes cardSpeakersBubblePulse {
  from {
    transform: scale(1);
    opacity: 0.8;
  }

  to {
    transform: scale(2);
    opacity: 0;
  }
}
