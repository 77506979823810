$cinematic_page_default_height: 72.5vh;

.dds-cinematic-pages {
  width: 100%;

  @include desktopOnly() {
    height: $cinematic_page_default_height;
  }

  &--transition-in {
    transform: translateY(100vh);
    opacity: 0;
    animation-name: cinematicPagesTransitionInTranslate,
      cinematicPagesTransitionInOpacity;
    animation-duration: 1000ms, 3000ms;
    animation-fill-mode: forwards, forwards;
    animation-delay: 800ms;
  }

  &__mask {
    @include desktopOnly() {
      transition: height $transition;
      pointer-events: none;
      position: relative;
      left: -160px;
      padding-left: 160px;
      height: 100%;
      overflow: hidden;
      mask-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 170px
      );
      -webkit-mask-image: -webkit-linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 170px
      );

      &--l {
        mask-image: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 170px,
          rgba(255, 255, 255, 1) calc(100% - 145px),
          rgba(255, 255, 255, 0) 100%
        );
        -webkit-mask-image: -webkit-linear-gradient(
          0deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 170px,
          rgba(255, 255, 255, 1) calc(100% - 145px),
          rgba(255, 255, 255, 0) 100%
        );
      }
    }
  }

  &__container {
    display: flex;
    height: 100%;
    transition: transform $transition, opacity $transition, left $transition;

    &--stacked {
      .dds-cinematic-page {
        transform-origin: center right;
        opacity: 0.35;
      }
    }
  }
}

.dds-cinematic-page {
  border-radius: 25px;
  margin-right: 0px;
  width: 100%;
  height: 100%;
  transition: opacity $transition, transform $transition, height 300ms;
  box-shadow: $cardShadowNormal;
  flex-shrink: 0;
  overflow: hidden;
  pointer-events: auto;

  &--default-height {
    height: #{$cinematic_page_default_height} !important;
  }

  &--style {
    &-default {
      background: colour(base, white);
    }

    &-dark {
      background: colour(base, black);
    }

    &-translucent-dark {
      background: rgba(0, 0, 0, 0.8);
    }

    &-frosted-dark {
      background: radial-gradient(
        192.74% 192.74% at 54.95% 51.06%,
        rgba(0, 0, 0, 0.87) 1.09%,
        rgba(0, 0, 0, 0.87) 27.08%,
        rgba(0, 0, 0, 0.303327) 98.69%
      );
      backdrop-filter: saturate(180%) blur(20px);
      color: colour(base, white);
    }

    &-frosted-light {
      background: radial-gradient(
        197.56% 197.56% at 48.25% 46.25%,
        #ffffff 0.52%,
        rgba(255, 255, 255, 0.9) 16.67%,
        rgba(255, 255, 255, 0.8) 27.08%,
        rgba(255, 255, 255, 0) 64.06%
      );
      backdrop-filter: saturate(180%) blur(20px);
    }
  }

  &--inactive {
    opacity: 0.2;
    transform: scale(0.95);
    pointer-events: none;

    .dds-cinematic-page__go_to {
      pointer-events: all;
    }

    &:hover {
      opacity: 0.5;
      transform: scale(0.96);
    }
  }

  &__modal {
    opacity: 0;
    transition: left $transition, opacity $transition;

    &--active {
      opacity: 1;
    }

    &__close {
      position: absolute;
      top: 48px;
      right: 5px;
      transform: translateX(50%);
    }
  }

  &__loading {
    height: $cinematic_page_default_height;
  }

  &__indicator {
    width: 50px;
    position: absolute;
    top: 0;
    height: $cinematic_page_default_height;
    z-index: 1;
    display: flex;
    align-items: center;
    pointer-events: none;
  }
}

@keyframes cinematicPagesTransitionInTranslate {
  from {
    transform: translateY(100vh);
  }

  to {
    transform: translateY(0vh);
  }
}

@keyframes cinematicPagesTransitionInOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.dds-cinematic-page-mini-modal {
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100%;
  pointer-events: none;
  transition: height 250ms;
  overflow: hidden;

  &__modal {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0%;
    transition: transform 250ms;
    padding-bottom: 32px;
    display: flex;
    justify-content: center;
    transform: translateY(100%);

    &--visible {
      transform: translateY(0%);
    }

    &__content {
      pointer-events: auto;
      background: colour(base, white);
      border-radius: 28px;
      box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
      padding: 32px 16px;
      display: flex;
      align-items: center;
    }

    &__close {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(25%, -25%);
    }
  }
}
