.c-text-marquee {
  // font-size: 8rem;
  h1 {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    text-transform: uppercase;
  }
  .dark {
    -webkit-text-stroke-color: colour(base, sad_grey);
  }
  .light {
    -webkit-text-stroke-color: colour(base, very_light_gre);
  }
}

.marquee {
  min-width: inherit !important;
}
