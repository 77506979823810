.c-sub-navigation {
  width: 100%;
  display: flex;

  // Allows the [x] corner button to break out the container
  &--editable {
    height: 95px !important;
  }

  &__wrapper {
    position: relative;
    width: 100%;

    &--width-auto {
      width: auto;
    }

    // Adjusts the height and position of the subnavigation component
    // so the [x] corner buttons can break out of the container
    &--editable {
      height: 73px;
      top: -22px;
    }
  }

  &__link {
    border-bottom: transparent 2px inset;
    border-bottom-style: inset;
    position: relative;
    z-index: 1;
    flex: 0 0 auto;
    transition: color $transition;

    // Allows the [x] corner button to break out the container
    &--editable {
      margin-top: 22px;
    }

    &__text {
      display: flex;

      &__image {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        margin-left: 8px;
        margin-top: auto;
        margin-bottom: auto;
        width: 28px;
        height: 28px;
      }

      &--half-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        max-width: 42px;
        color: transparent;

        &--active {
          border-bottom: colour(brand, default) 2px solid;
        }
      }
    }

    &--active {
      border-bottom: colour(brand, default) 2px inset;

      &-black {
        border-bottom: colour(base, black) 2px inset;
      }

      &-white {
        border-bottom: colour(base, white) 2px inset;
      }

      &--half-line {
        border-bottom: transparent 2px inset;
      }
    }

    &--no-highlight {
      border-bottom: transparent 2px inset;

      .c-sub-navigation__link--active {
        border-bottom: transparent 2px inset;
      }

      &\@s {
        @include mobileOnly() {
          border-bottom: none !important;

          .c-sub-navigation__link--active {
            border-bottom: none !important;
          }
        }
      }

      &\@m {
        @include tabletAndDesktopOnly() {
          border-bottom: none !important;

          .c-sub-navigation__link--active {
            border-bottom: none !important;
          }
        }
      }

      &\@l {
        @include desktopOnly() {
          border-bottom: none !important;

          .c-sub-navigation__link--active {
            border-bottom: none !important;
          }
        }
      }
    }
  }

  &__seperator {
    display: flex;
    border-bottom: transparent 2px inset !important;

    i {
      width: 6px;
      height: 6px;
      background: colour(base, light_grey);
      border-radius: 6px;
      display: block;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .counter-badge {
    right: 0;

    @include mobileOnly() {
      position: initial;
      height: 2em;
      min-width: 2em;
      line-height: 1em;
    }

    @include tabletAndDesktopOnly() {
      position: absolute;
      transform: translate(28px, 0);
    }
  }

  .badge {
    top: 0px;
    margin-left: 4px;

    @include tabletAndDesktopOnly() {
      top: 2px;
      margin-left: 8px;
    }
  }

  &__line {
    background: colour(base, very_light_grey);
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0px;

    &--light-grey {
      background: colour(base, light_grey);
    }

    @include tabletAndDesktopOnly() {
      bottom: 2px;
    }
  }

  &__blur {
    position: absolute;
    height: calc(100% - 2px);
    width: 48px;
    top: 0;
    right: 0;
    background-color: colour(base, transparent);
    background-image: linear-gradient(
      to right,
      colour(base, transparent),
      colour(base, off_white)
    );
    z-index: 300;
    pointer-events: none;
    opacity: 1;
    transition: opacity $transition;

    @include tabletAndDesktopOnly() {
      display: none;
    }

    &--centred {
      @include tabletAndDesktopOnly() {
        display: block !important;
      }
    }

    &--fixed {
      @include tabletAndDesktopOnly() {
        overflow: hidden;
        display: block !important;
        opacity: 1 !important;
      }
    }

    &--hide {
      opacity: 0 !important;
    }
  }

  &__spacer {
    height: auto;
  }
}
