.registration_form {
  label[for='acceptedMarketingCommunications'] {
    &::before {
      top: 6px;
    }

    &::after {
      top: 11px;
      left: 9px;
    }
  }
}

// Here comes the fancy classNames from Google SSO button
.nsm7Bb-HzV7m-LgbsSe {
  color: colour(base, black) !important;

  &-MJoBVe,
  &-bN97Pc-sM5MNb {
    &:hover {
      background-color: colour(base, charcoal_grey) !important;
      color: colour(base, white) !important;
    }
  }

  &-Bz112c-haAclf {
    background-color: transparent !important;
  }

  &.MFS4be-Ia7Qfc {
    padding: 28px !important;
    border-radius: 50px !important;
  }
}

.S9gUrf-YoZ4jf {
  width: 100% !important;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  font-family: 'Lynstone', 'Google Sans', arial, sans-serif !important;
  font-weight: 400 !important;
}

.hJDwNd-SxQuSe {
  background-color: colour(base, very_light_grey) !important;

  &:hover {
    background-color: colour(base, charcoal_grey) !important;
    color: colour(base, white) !important;
  }
}
