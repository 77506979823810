$dropdownWidthXSmall: 184px;
$dropdownWidthSmall: 200px;
$dropdownWidthSm: 280px;
$dropdownWidthMedium: 332px;
$dropdownWidthLarge: 415px;

.c-dropdown {
  background: colour(base, white);
  position: absolute;
  top: calc(100% + 12px);
  left: 0;
  width: 100vw;
  transition: all 300ms;

  &--xsmall {
    max-width: $dropdownWidthXSmall;
  }

  &--small {
    max-width: $dropdownWidthSmall;
  }

  &--sm {
    max-width: $dropdownWidthSm;
  }

  &--medium {
    max-width: $dropdownWidthMedium;
  }

  &--large {
    max-width: $dropdownWidthLarge;
  }

  &--max-content {
    width: max-content;
  }

  &--right {
    right: 0;
    left: auto;
  }

  &--dark-mode {
    background: colour(base, charcoal_grey) !important;
  }

  &--darker-mode {
    background: colour(base, almost_black) !important;
  }

  &__connector {
    position: absolute;
    bottom: -24px;
    height: 24px;
    width: 100%;
  }

  &.t-dropdown-enter.t-dropdown-enter-active,
  &.t-dropdown-appear.t-dropdown-appear-active,
  &.t-dropdown-leave,
  &--closed {
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
  }

  &__cover {
    -webkit-tap-highlight-color: transparent;
    position: fixed;
    background: transparent;
  }
}
.c-dropdown__trigger {
  transition: 300ms all;
}

.c-dropdown__cover,
.c-dropdown__content,
.c-dropdown__connector,
.c-dropdown__trigger,
.c-dropdown {
  .c-dropdown-container & {
    z-index: z-index(dropdown);
  }

  .c-dropdown--active & {
    z-index: z-index(dropdown) + 1;
  }

  .c-dropdown-container--hover-triggered & {
    z-index: z-index(dropdown) - 1;
  }
}

#project-mode-switcher {
  top: calc(100% + 6px) !important;
  width: 100%;
  max-width: none;
}

.c-dropdown--phone-code {
  max-width: 78px;

  &-list {
    overflow: scroll;
    max-height: 260px;
  }

  &-button {
    box-shadow: none !important;
    width: 78px;
  }
}
