.dds-cinematic-steps {
  width: 100%;
  transform: translateY(100%);
  transition: transform 500ms, opacity 500ms;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 500ms;
  animation-fill-mode: forwards;

  .dds-cinematic-step {
    margin-bottom: 16px;
  }
}

.dds-cinematic-step {
  background: colour(base, white);
  width: 100%;
  border-radius: 25px;
  padding: 24px 16px;
  text-align: center;
  transition: opacity $transition, transform $transition;
  box-shadow: $cardShadowNormal;

  &--inactive {
    opacity: 0.2;
    transform: scale(0.95);
    pointer-events: none;

    &:hover {
      transform: scale(0.96);
    }
  }

  &__step_number {
    font-weight: 500;
    border: #{colour(base, brand)} 2px solid;
    font-size: 1rem;
    line-height: 1.8rem;
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 100%;
  }
}
