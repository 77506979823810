.modal-mask {
  height: 100vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
  background: colour(other, translucentBlack);
  z-index: z-index(modal) - 1;

  &--hidden {
    display: none;
  }
}

.modal {
  -webkit-overflow-scrolling: touch;
  background: colour(base, white);
  border-radius: 20px 20px 0px 0px;
  height: 75vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z-index(modal);
  overflow: scroll;

  &--hidden {
    display: none;
  }
}

.global-modal {
  &__header {
    background: colour(base, white);
    position: sticky;
    top: 0;
    z-index: 1;

    &--dark {
      background: colour(base, charcoal_grey);
    }
  }
}
