@include tabletAndDesktopOnly {
  .user-message--following {
    transition: $transition border-color, $transition color;
  }
}

.user-message--loading {
  position: relative;

  &:before {
    content: '';
    color: transparent;
  }
}

.c-user-cardboard {
  box-shadow: $cardShadowNormal;
  transition: box-shadow $transition;
  background: colour(base, white);

  &:hover {
    box-shadow: $cardShadowHover;
  }

  &__aspect {
    height: 0;
    padding-bottom: $aspectRatio4-3;
  }

  &__image {
    position: relative;
    height: 0;
    overflow: hidden;
    background-color: colour(pastel, light_grey);
    background-repeat: no-repeat;
    padding-bottom: 40%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include tabletAndDesktopOnly() {
      padding-bottom: $aspectRatio1-1;
    }
  }

  &__roles {
    position: relative;
    left: -2px;
    width: 14px;
    margin-right: 4px;
    margin-bottom: auto;
    flex: none;
  }

  &__location_pin {
    position: relative;
    left: -4px;
    margin-bottom: auto;
    flex: none;
  }
}

.c-user-cardboards {
  display: grid;
  grid-gap: gap(l);
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: gap(l);
  grid-template-columns: 1fr;

  @include tabletOnly() {
    grid-template-columns: 1fr 1fr;
    max-width: 100%;
  }

  @include desktopOnly() {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 100%;
  }
}
