.c-logged-out-homepage {
  &__hero {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc((var(--vh, 1vh) * 100) - #{$headerHeight});
    overflow: hidden;

    @include desktopOnly() {
      height: calc((var(--vh, 1vh) * 100));
      position: relative;
    }

    .f-lynstone-bold-1z {
      @include tabletOnly() {
        line-height: 88px;
        line-height: 5.5rem;
      }
    }

    @media screen and (min-width: 1681px) {
      height: 915px;
    }

    &__body {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      @include desktopOnly() {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 80%;
        transform: translateX(-50%);
        height: calc(100% - 19vw);
      }

      &__inner {
        @include mobileOnly() {
          margin-top: -10vw;
        }

        @include tabletOnly() {
          margin-top: -4vw;
        }
      }
    }
  }

  .f-lynstone-book-2a {
    line-height: 22px;
    line-height: 1.5rem;

    @include tabletOnly() {
      line-height: 28px;
      line-height: 1.72rem;
    }

    @include desktopOnly() {
      line-height: 32px;
      line-height: 1.99rem;
    }
  }

  &__daisie_logo {
    width: 200px;
    height: 200px;

    @include tabletAndDesktopOnly() {
      width: 400px;
      height: 400px;
    }
  }

  &__workshop_carousel {
    @include tabletAndDesktopOnly() {
      height: 490px;
    }

    .carousel {
      @include desktopOnly() {
        overflow: visible;
        position: relative;
        left: -100px;
      }

      &__item {
        @include tabletAndDesktopOnly() {
          height: 490px;
        }
      }

      &__buttons {
        @include tabletAndDesktopOnly() {
          width: 16px;
          width: 100%;
        }
      }

      &__button {
        @include tabletAndDesktopOnly() {
          position: absolute;
          right: -40px;
        }

        &--prev {
          @include tabletAndDesktopOnly() {
            top: 50%;
            transform: translateY(-20px);
          }
        }

        &--next {
          @include tabletAndDesktopOnly() {
            top: 50%;
            transform: translateY(20px);
          }
        }
      }
    }

    .c-workshop-card {
      @include tabletAndDesktopOnly() {
        height: 100%;
      }
    }

    &__copy {
      @include tabletAndDesktopOnly() {
        position: relative;
        height: 490px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &__content {
        @include tabletAndDesktopOnly() {
          position: relative;
          z-index: 1;
        }
      }

      &__mask {
        background: colour(base, off_white);

        @include tabletAndDesktopOnly() {
          width: 100vw;
          height: 100%;
          position: absolute;
        }

        @include desktopOnly() {
          left: -100px;
        }
      }
    }
  }

  &__lines {
    $heightMobile: calc(100vh - 160px);
    $heightTablet: calc(100vh - 200px);
    $heightDesktop: calc(100vh - 50px);
    $color: rgba(20, 18, 16, 0.1);

    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: #{$color} 1px solid;
    z-index: 0;
    height: $heightMobile;

    @include tabletOnly() {
      height: $heightTablet;
    }

    @include desktopOnly() {
      height: $heightDesktop;
      max-height: 1000px;
    }

    &__line {
      position: absolute;
      background: $color;

      &--h {
        width: 100vw;
        height: 1px;
        left: 0;

        &--1 {
          top: 33.333%;
        }

        &--2 {
          top: 66.666%;
        }
      }

      &--v {
        width: 1px;
        top: 0;
        height: $heightMobile;

        @include tabletOnly() {
          height: $heightTablet;
        }

        @include desktopOnly() {
          height: $heightDesktop;
          max-height: 1000px;
        }

        &--1 {
          left: 33.333%;
        }

        &--2 {
          left: 66.666%;
        }
      }
    }
  }
}
