.ribbons {
  &__container {
    white-space: nowrap;

    &__bg {
      background: colour(base, white);
      width: 20%;
      height: 5vw;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__ribbon {
    $border-size: 10px;
    width: 200vw;
    overflow: hidden;
    padding-top: 6px;
    padding-bottom: 4px;

    @include tabletAndDesktopOnly() {
      width: 100vw;
      padding-top: 6px;
      padding-bottom: 6px;
    }

    span {
      text-transform: uppercase;
      white-space: nowrap;
      font-size: 1.5em;
      line-height: 1;
      margin-right: 6px;

      @include tabletAndDesktopOnly() {
        font-size: 2em;
        line-height: 1;
        margin-right: 6px;
      }

      &:nth-child(1n) {
        font-family: 'NewGroteskSquareFIVE', $font_stack;
      }

      &:nth-child(2n) {
        font-family: 'NewGroteskSquareTHREE', $font_stack;
      }
    }
  }
}
