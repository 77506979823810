.c-toast {
  position: fixed;
  left: 50%;
  bottom: 50px;
  width: 440px;
  max-width: 100%;
  transform: translateX(-50%);
  background: colour(base, white);
  z-index: z-index(modal);
  border-radius: 32px;
  display: flex;
  align-items: center;

  @include mobileOnly() {
    height: 64px;
    background: colour(base, black);
    color: colour(base, white);
    bottom: 0;
  }
}

@include transitionGroup(
  '.t-toast',
  transform,
  $transition,
  translateY(150px) translateX(-50%),
  translateY(0) translateX(-50%)
);
