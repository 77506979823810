.on-demand-player {
  width: 100%;
  max-width: 1344px;
  background: colour(base, black);
  border-radius: 20px;
  overflow: hidden;
  border: rgba(255, 255, 255, 0.25) 1px solid;

  @include tabletAndDesktopOnly() {
    &:not(.on-demand-player--originals) {
      width: calc(100% - (48px * 2));
    }
  }

  &--originals {
    @include mobileOnly() {
      width: 100% !important;
    }
  }

  &--no-border {
    border: none;
  }

  &:hover {
    .on-demand-player__close {
      opacity: 1;
    }
  }

  &__stream_container {
    padding-bottom: $aspectRatio16-9;
    position: relative;

    div {
      position: initial !important;
      padding-top: initial !important;
    }
  }

  &__close {
    $size: 32px;
    $position: 20px;
    width: $size;
    height: $size;
    border-radius: $size;
    top: $position;
    right: $position;
    position: absolute;
    background: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background $transition, opacity $transition;
    opacity: 0;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }
}
