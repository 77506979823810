$settings-overlay-start-height: 200px;
$settings-overlay-medium-height: 500px;
$settings-overlay-large-height: 95vh;
$settings-overlay-threshold: 130px;

.settings-tray {
  position: fixed;
  z-index: z-index(modal);
  bottom: 0;
}

.settings-overlay {
  $settingsTransition: 600ms;

  &__close {
    -webkit-tap-highlight-color: transparent;
    background: rgba(0, 0, 0, 0.4);
    white-space: nowrap;
    text-indent: 200%;
    overflow: hidden;
    position: fixed;
    height: 200%;
    width: 100%;
    left: 0;
    top: 0;

    @include blurBackground();

    @include transitionGroup(
      '.t-settings',
      opacity,
      $settingsTransition,
      0.01,
      1
    );
  }

  &__header {
    position: sticky;
    z-index: z-index(sidebar);
    top: 0;

    .c-close-button {
      height: gap();
    }
  }

  &__container {
    background: colour(base, white);
    padding: gap(ml);
    padding-top: 0;
    border-top-right-radius: gap(s);
    border-top-left-radius: gap(s);
    min-height: $settings-overlay-start-height;
    max-height: calc(100vh - #{$settings-overlay-threshold});
    position: relative;
    z-index: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: -20px;
    border-bottom: 20px solid #fff;

    &--dark {
      background-color: colour(base, charcoal_grey);
    }

    &--full-height {
      border-radius: 0px !important;
      height: calc(var(--vh, 1vh) * 100) !important;
      max-height: calc(var(--vh, 1vh) * 100) !important;
      margin-bottom: 0 !important;
      border-bottom: none !important;
    }

    [data-portal='messages'] & {
      overflow: visible;
    }

    [data-portal='share_project'] &,
    [data-portal='invite_collaborator'] &,
    [data-portal='project_likes'] & {
      min-height: $settings-overlay-medium-height;
    }

    [data-portal='workshop_sign_up'] & {
      padding-bottom: 0;
    }

    [data-portal='workshop_flow_complete'] & {
      padding: 0;
    }

    [data-portal='workshop_instagram_assets'] & {
      padding-left: 0;
      padding-right: 0;

      header {
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    @include transitionGroup(
      '.t-settings',
      transform,
      $settingsTransition,
      translateY(100%),
      translateY(0%),
      cubic-bezier(0.39, 1.39, 0.19, 0.97)
    );

    @include transitionGroup(
      '.t-settings--horizontal',
      transform,
      $settingsTransition,
      translateX(100%),
      translateX(0%),
      cubic-bezier(0.39, 1.39, 0.19, 0.97)
    );
  }

  &__line {
    width: 100%;
    height: 4px;
    position: sticky;
    top: 13px; // hacky af
    outline: none;
    margin-bottom: 16px;

    &:before {
      content: '';
      bottom: 0;
      background: colour(base, light_grey);
      width: 50px;
      left: calc(50% - 25px);
      position: absolute;
      height: 4px;
    }
  }
}
