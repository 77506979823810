.blocks--masonry {
  margin: -#{gap() / 2} -#{gap() / 2} 0;
  position: relative;
  overflow: hidden;

  &--small-spacing {
    margin: -2px -2px 0;
  }

  @include tabletAndDesktopOnly() {
    overflow: visible;
  }

  .no-js & {
    max-width: 500px;
    margin: 0 auto;

    .bpm {
      transform: none !important;
      position: static;
      width: 100%;
    }
  }
}

.blocks--editable {
  .bpm {
    -webkit-touch-callout: none;
    cursor: grab;

    &.dragging,
    &.dragging:hover {
      cursor: grabbing;
      z-index: 2;

      .c-block-controls,
      .c-block-controls:hover {
        opacity: 0;
        visibility: hidden;
      }
    }

    a,
    img {
      -webkit-touch-callout: none;
    }
  }
}

.blocks--hidden {
  opacity: 0;
  visibility: hidden;
}

.bpm {
  width: 25%;
  position: absolute;
  padding: 12px;
  user-select: none;
  transition: 300ms transform;

  &--small-spacing {
    padding: 2px;
  }

  &.hovering:not(.bp--editing),
  &:hover:not(.bp--editing) {
    .c-block-controls,
    .c-block-controls:hover {
      opacity: 1;
      visibility: visible;
    }
  }

  &[data-nearest='before']:before,
  &[data-nearest='after']:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 2px;
    left: 0;
    top: 0;
    background: #0097e6;
  }

  &[data-nearest='after']:before {
    content: '';
    left: auto;
    right: 0;
  }
}
