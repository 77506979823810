.progress-circle {
  width: 200px;
  height: 200px;

  @include mobileOnly {
    width: 50vw;
    height: 160px;
  }

  &__text {
    fill: colour(base, white);
  }
}

circle {
  animation: animationStartToEnd 1s linear;

  @keyframes animationStartToEnd {
    from {
      stroke-dashoffset: 200%;
    }
  }
}
