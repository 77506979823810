@include tabletAndDesktopOnly() {
  .page-layout[data-key-route='students'] {
    .c-category-page-logged-out__hero_container {
      min-height: 1000px;
    }
  }
}

.c-category-page-logged-out {
  &__hero_container {
    width: 100%;
    position: relative;

    @include mobileOnly() {
      margin-bottom: 64px;
    }

    @include tabletAndDesktopOnly() {
      min-height: 930px;
      height: 100vh;
    }

    &--padding {
      @include tabletAndDesktopOnly() {
        height: calc(100vh - #{$headerHeight});
      }
    }
  }

  &__hero {
    position: relative;

    @include tabletAndDesktopOnly() {
      height: 100%;
    }

    &--alt {
      @include tabletAndDesktopOnly() {
        top: 72px;
        height: calc(100% - 72px - 160px);
      }
    }

    &__overlay_v {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      width: 66%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__overlay_h {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 40%,
        rgba(0, 0, 0, 0) 100%
      );
      width: 100%;
      position: absolute;
      height: 50%;
      top: 72px;
      left: 0;

      @include tabletAndDesktopOnly() {
        height: 33%;
        bottom: 0;
        top: auto;
      }
    }
  }

  &__app_store {
    height: 48px;
  }

  &__creators_cards {
    display: flex;
    flex-direction: row;
    position: relative;

    overflow-x: scroll;
    height: 400px;
    width: 100vw;

    @include tabletAndDesktopOnly() {
      overflow-x: hidden;
      height: initial;
      width: initial;
      padding-left: 8px;
      padding-right: 8px;
    }

    &__card {
      flex-shrink: 0;
      position: relative;

      width: calc(100vw - 60px);
      margin-right: 20px;

      &:first-of-type {
        margin-left: 20px;
      }

      @include tabletAndDesktopOnly() {
        flex-shrink: 1;
        width: initial;
        margin-left: 8px !important;
        margin-right: 8px !important;
      }

      &__image {
        border-radius: $defaultBorderRadiusOld;
        width: 100%;
        height: 100%;
      }
    }
  }
}
