.nav-list {
  & > * {
    padding: gap(m) 0;
    display: flex;
    width: 100%;
    text-align: left;
    align-items: center;
    justify-content: space-between;

    & + * {
      border-top: 1px solid colour(base, light_grey);
    }
  }

  &--users {
    width: 100%;

    & > * {
      margin: 0;
      padding: gap(m) 0;

      &:first-child {
        border-top: none;
      }
    }
  }
}
