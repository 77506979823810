.carousel {
  position: relative;
  overflow: hidden;

  @mixin createCarouselWrap($padding: 0px) {
    padding-left: calc(((100vw - 100%) / 2) + #{$padding});
    margin-left: calc(0px - ((100vw - 100%) / 2));
    padding-right: calc(((100vw - 100%) / 2) + #{$padding});
    margin-right: calc(0px - ((100vw - 100%) / 2));
  }

  &--navigation-bar {
    .carousel__container {
      padding-left: calc(((100vw - 100%) / 2) + 24px);
      margin-left: calc(0px - ((100vw - 100%) / 2));
      padding-right: calc(((100vw - 100%) / 2) + 8px);
      margin-right: calc(0px - ((100vw - 100%) / 2));
    }

    .carousel__buttons {
      width: calc(100% - 24px);
    }
  }

  &--wrap {
    @include createCarouselWrap();
  }

  &__container {
    @include createCarouselWrap();
    scrollbar-width: none;
    -ms-overflow-style: none;

    &--disable-snap {
      overflow: scroll !important;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @include mobileAndTabletOnly() {
      &--animate {
        transition: transform $transition;
      }
    }

    @include desktopOnly() {
      overflow: scroll;
    }
  }

  &__track {
    display: flex;
    transition: transform $transition;

    &__spacer {
      display: flex;
      width: calc((100vw - 100%) / 2);
      flex-shrink: 0;
    }
  }

  &__item {
    width: 100%;
    height: 100%;
    flex-shrink: 0;

    &--equal-height {
      height: auto;
    }

    &--active-item {
      opacity: 1;
      transform: scale(1.15);
      margin: auto calc(2.1% - 4px) auto 2.1%;
      transition: transform 500ms, margin 500ms, opacity 900ms;
    }

    &--non-active-item {
      opacity: 0.4;
      transform: scale(1);
      transition: transform 500ms, margin 500ms, opacity 500ms;
    }
  }

  &__buttons {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 1;
    max-width: calc(100vw - 12rem);
    transform: translateX(-50%);
    pointer-events: none;
    width: calc(100% - 40px);

    @include tabletOnly() {
      width: calc(100% - 64px);
    }

    @include desktopOnly() {
      width: calc(100% - 96px);
    }

    &:before,
    &:after {
      content: '';
      width: calc((100vw - 100%) / 2);
      height: 100%;
      top: 0;
      position: absolute;
      pointer-events: all;
      min-width: 20px;

      @include tabletOnly() {
        min-width: 32px;
      }

      @include desktopOnly() {
        min-width: 48px;
      }
    }

    &:before {
      left: 0;
      transform: translateX(-100%);
    }

    &:after {
      right: 0;
      transform: translateX(100%);
    }

    &--fade {
      z-index: 2;

      &:before {
        @include desktopOnly() {
          background: rgb(249, 249, 249);
          background: linear-gradient(
            90deg,
            rgb(0, 0, 0) 20%,
            rgba(249, 249, 249, 0) 100%
          );
        }
      }

      &:after {
        @include desktopOnly() {
          background: rgb(249, 249, 249);
          background: linear-gradient(
            270deg,
            rgb(0, 0, 0) 20%,
            rgba(249, 249, 249, 0) 100%
          );
        }
      }
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    z-index: 2;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    transition: all $transition;

    @include mobileOnly() {
      display: none;
    }

    @include desktopOnly() {
      width: 52px;
      height: 52px;
    }

    &--prev {
      transform: translate(0, -50%);

      &--wrap {
        transform: translate(-50%, -50%);
      }
    }

    &--next {
      right: 0;
      transform: translate(0, -50%);

      &--wrap {
        transform: translate(50%, -50%);
      }
    }

    // Button themes
    &--theme {
      &-xsmall {
        width: 16px;
        height: 16px;
      }

      &-small {
        width: 32px;
        height: 32px;
      }

      &-dark,
      &-light {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      &-dark {
        background: colour(base, sad_grey);

        &:hover {
          background: #444;
        }
      }

      &-light {
        background: colour(base, white);
      }

      &-outline {
        border: #{colour(base, white)} 2px solid;
        transition: all 300ms;

        &:hover {
          border-color: colour(base, dark_grey);
        }
      }
    }
  }
}
