.c-search-bar {
  position: relative;
  width: 100%;

  @include tabletAndDesktopOnly() {
    width: 500px;
  }

  &__input {
    padding-right: 78px;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
  }

  &__clear {
    position: absolute;
    top: 50%;
    right: 56px;
    transform: translateY(-50%);
    color: colour(search, grey);
    height: 18px;
    transition: color $transition, opacity 100ms;
  }

  &__submit {
    position: absolute !important;
    top: 9px;
    right: 9px;
  }
}

.c-search-bar-nav {
  position: relative;
  width: 100%;

  @include tabletAndDesktopOnly() {
    width: 200px;
  }

  &__clear {
    top: 50%;
    right: 56px;
    transform: translateY(-50%);
    color: colour(search, grey);
    height: 18px;
    transition: color $transition, opacity 100ms;
  }

  &__animation {
    animation-name: animationRightToLeft;
    animation-duration: 0.2s;
  }
}
