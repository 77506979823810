.site-footer {
  a:hover {
    color: #fff;
  }

  li {
    margin-bottom: 20px;
  }

  &__menu {
    @include mobileOnly() {
      ul {
        display: flex;
        margin: 12px 0;
      }
      li {
        padding-right: 8px;
      }
    }
  }
}
