.badge {
  text-align: center;
  width: 24px;
  height: 24px;
  pointer-events: none;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-20deg);
    color: colour(base, white);
    font-family: 'NewGroteskSquareFIVE', $font-stack;
    font-size: 9px;
  }

  &--exponent {
    top: -11px;
    right: 0;
  }

  &--animated {
    svg {
      animation: spin 10s infinite linear;
    }
  }
}
