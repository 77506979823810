.scrubber {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: default !important;

  &__track {
    width: 100%;
    height: 4px;
    position: relative;
    background: colour(base, light_grey);
  }

  &__head {
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);

    $scrubber-active-area: 30px;

    width: $scrubber-active-area;
    height: $scrubber-active-area;
    border-radius: $scrubber-active-area;

    &__inner {
      background: colour(base, black);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);

      $scrubber-size: 16px;

      width: $scrubber-size;
      height: $scrubber-size;
      border-radius: $scrubber-size;
    }
  }
}
