.user-info-popup {
  font-size: 14px;
}

.c-user-sidebar {
  .bb,
  .bb\@l,
  .bb\@s {
    border-color: colour(base, off_white);
    border-width: 2px;
  }
}
