.wrap {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  .wrap {
    padding-left: 0;
    padding-right: 0;
  }

  &--small-spacing {
    padding-left: 4px;
    padding-right: 4px;
  }

  &--m {
    max-width: 1122px;
  }

  &--sm {
    max-width: 796px;
  }

  &--content {
    max-width: 712px;
  }

  &--carousel-content {
    max-width: 625px;
  }

  &--create-project {
    max-width: 545px;
  }

  &--small {
    max-width: 500px;
  }

  &--s {
    max-width: 471px;
  }

  @include tabletOnly {
    padding-right: 32px;
    padding-left: 32px;
  }

  @include desktopOnly {
    padding-right: 48px;
    padding-left: 48px;
  }
}

.wrap\@m {
  @include tabletAndDesktopOnly {
    padding-right: 32px;
    padding-left: 32px;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    .wrap,
    .wrap\@m,
    .wrap\@l {
      padding-left: 0;
      padding-right: 0;
    }

    &--m {
      max-width: 1122px;
    }

    &--sm {
      max-width: 796px;
    }

    &--content {
      max-width: 712px;
    }

    &--s {
      max-width: 471px;
    }

    @include desktopOnly {
      padding-right: 48px;
      padding-left: 48px;
    }
  }
}

@include desktopOnly() {
  .wrap\@l {
    padding-right: 48px;
    padding-left: 48px;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    .wrap,
    .wrap\@l {
      padding-left: 0;
      padding-right: 0;
    }

    &--m {
      max-width: 1122px;
    }

    &--sm {
      max-width: 796px;
    }

    &--content {
      max-width: 712px;
    }

    &--s {
      max-width: 471px;
    }
  }
}

.insulate {
  padding-top: gap();
  padding-bottom: gap();
}

.fill {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
