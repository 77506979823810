.c-workshop-attend-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  pointer-events: none;
  overflow: hidden;

  &__close {
    $size: 32px;
    width: $size;
    height: $size;
    position: absolute;
    top: 0;
    right: 0;

    &__button {
      border-radius: $size;
      width: $size;
      height: $size;
      background: rgba(0, 0, 0, 0.25);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;

      &:hover {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__discord {
    &__button {
      background: rgba(0, 0, 0, 0.25);

      &:hover {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &__overlay {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__content {
    width: calc(100vw - 40px);
    box-shadow: 0px 7px 40px 10px rgba(0, 0, 0, 0.5);
    border-radius: $defaultBorderRadius / 2;
    position: absolute;
    bottom: -232px;
    left: 50%;
    transform: translateX(-50%);
    transition: bottom $transition;
    background: colour(base, charcoal_grey);
    pointer-events: all;

    @include tabletAndDesktopOnly() {
      width: 500px;
      height: 200px;

      &__workshop-page {
        height: 225px;
      }
    }

    &--animate-in {
      bottom: 0px;
    }
  }
}
