$largeButtonHeight: 64px;
$defaultButtonHeight: 40px;
$smallerButtonHeight: 32px;
$tinyButtonHeight: 24px;
$xSmallButtonHeight: 18px;

.button {
  @extend .f-text-3;

  background-color: colour(base, white);
  color: colour(base, dark_grey);
  text-align: center;
  border: 1px solid colour(base, grey);
  padding: gap(xs) gap(ml);
  height: $defaultButtonHeight;
  outline: none;
  cursor: pointer;
  transition: all 300ms;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $defaultBorderRadiusOld;

  @include tabletAndDesktopOnly() {
    padding: gap(xs) gap(sm);
  }

  @at-root a#{&}:hover,
    &:hover:enabled {
    color: colour(base, black);
    border: 1px solid colour(base, black);

    svg {
      fill: currentColor;
    }
  }

  &-icon {
    padding: 0;
    width: $defaultButtonHeight;
    height: $defaultButtonHeight;

    &--small {
      height: $smallerButtonHeight;
      width: $smallerButtonHeight;
    }
  }

  &--loud {
    font-family: 'NewGroteskSquareFOUR';
    text-transform: uppercase;
    font-size: 16px;
    font-size: 1rem;
    line-height: 19px;
    line-height: 1.2rem;

    @include tabletOnly() {
      font-size: 16px;
      font-size: 1rem;
      line-height: 19px;
      line-height: 1.2rem;
    }

    @include desktopOnly() {
      font-size: 16px;
      font-size: 1rem;
      line-height: 19px;
      line-height: 1.2rem;
    }
  }

  &--no-border {
    border: none !important;
  }

  &--shadow {
    box-shadow: $buttonShadowNormal;
    transition: all $transition;

    @at-root a#{&}:hover,
      &:hover:enabled {
      box-shadow: $buttonShadowHover;
    }
  }

  &--wide {
    padding-left: gap(xl);
    padding-right: gap(xl);

    @include tabletAndDesktopOnly() {
      padding-left: gap(xl);
      padding-right: gap(xl);
    }

    &\@m {
      @include tabletAndDesktopOnly() {
        padding-left: gap(xl);
        padding-right: gap(xl);
      }
    }
  }

  &--xwide {
    padding-left: gap(xxl);
    padding-right: gap(xxl);

    @include tabletAndDesktopOnly() {
      padding-left: gap(xxxl);
      padding-right: gap(xxxl);
    }
  }

  &--thin {
    height: $smallerButtonHeight;
    padding-bottom: gap(xxs);
    padding-top: gap(xxs);
  }

  &--circle {
    border-radius: 100%;
    height: $smallerButtonHeight;
    width: $smallerButtonHeight;
    border: 1px solid colour(base, grey);
    background: colour(base, white);
    color: colour(base, dark_grey);
    text-align: center;
    position: relative;
    transition: 300ms all;

    @at-root a#{&}:hover,
      a#{&}:focus,
      &:hover:enabled,
      &:focus:enabled {
      border-color: colour(base, black);
      color: colour(base, black);
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &-white {
      background: colour(base, white);
      color: colour(base, dark_grey);
      border: 1px solid colour(base, light_grey);
    }

    &-shadowed {
      border: none;
      box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.12),
        0px 1px 6px rgba(0, 0, 0, 0.04);
    }

    &-black {
      background: colour(base, black);
      color: colour(base, white);
      border: 1px solid colour(base, black);

      @at-root a#{&}:hover,
        &:hover:enabled {
        color: colour(base, white);
      }
    }

    &--xsmall {
      height: $xSmallButtonHeight;
      width: $xSmallButtonHeight;
    }

    &--large {
      height: $defaultButtonHeight;
      width: $defaultButtonHeight;
    }

    &--xlarge {
      height: $largeButtonHeight;
      width: $largeButtonHeight;
    }
  }

  &--rounded {
    border-radius: $defaultButtonHeight;
  }

  &--edit-project {
    @extend .f-bellow-3;
    padding: gap(s) gap(m);
  }

  &--large {
    @include tabletAndDesktopOnly() {
      padding: gap(xs) gap(ml);
      min-width: 100px;
    }
  }

  &--black {
    background: colour(base, black);
    border: 1px solid colour(base, black);
    color: colour(base, white);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(brand);
      border: 1px solid colour(brand);
      color: colour(base, white);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      border: 1px solid colour(brand);
    }

    &[disabled] {
      color: colour(base, dark_grey);
      cursor: not-allowed;
      opacity: 1;
    }
  }

  &--black-alt {
    background: colour(base, black);
    border: 1px solid colour(base, black);
    color: colour(base, white);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(base, white);
      border: 1px solid colour(base, black);
      color: colour(base, black);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      border: 1px solid colour(base, black);
    }

    &[disabled] {
      color: colour(base, dark_grey);
      cursor: not-allowed;
      opacity: 1;
    }
  }

  &--yellow {
    color: colour(other, yellow);
    background-color: colour(base, black);

    @at-root a#{&}:hover,
      &:hover:enabled {
      color: colour(base, white);
    }
  }

  &--white {
    background: colour(base, white);
    border: 1px solid colour(base, light_grey);
    color: colour(base, dark_grey);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(brand);
      border: 1px solid colour(brand);
      color: colour(base, white);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      border: 1px solid colour(brand);
    }

    &[disabled] {
      color: colour(base, grey);
      cursor: not-allowed;
      opacity: 1;
    }

    &--dark-mode {
      color: colour(base, black);
    }

    &--alt {
      color: colour(base, black);
      border: none !important;

      &[disabled] {
        color: colour(base, grey);
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  &--white-invert {
    background: transparent;
    border: 1px solid colour(base, white);
    color: colour(base, white);

    @at-root a#{&}:hover,
      a#{&}:focus,
      &:hover:enabled,
      &:focus:enabled {
      border: 1px solid colour(base, light_grey);
      color: colour(base, light_grey);
    }

    &[disabled] {
      color: colour(base, grey);
      cursor: not-allowed;
      opacity: 1;
    }
  }

  &--danger {
    background: colour(base, white);
    border: 1px solid colour(other, alert);
    color: colour(other, alert);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(other, alert);
      border: 1px solid colour(other, alert);
      color: colour(base, white);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      border: 1px solid colour(base, black);
    }
  }

  &--danger-invert {
    background: colour(other, alert);
    border: 1px solid colour(other, alert);
    color: colour(base, white);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(base, black);
      border: 1px solid colour(base, black);
      color: colour(base, white);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      border: 1px solid colour(base, white);
    }
  }

  &--danger-alt {
    background: colour(base, black);
    border: 1px solid colour(base, black);
    color: colour(base, white);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(other, alert);
      border: 1px solid colour(other, alert);
      color: colour(base, white);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      border: 1px solid colour(base, black);
    }
  }

  &--go {
    background: colour(other, go);
    border: 1px solid colour(other, go);
    color: colour(base, white);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(base, black);
      color: colour(base, white);
      border: 1px solid colour(base, black);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      border: 1px solid colour(base, black);
    }
  }

  &--grey {
    background: colour(base, grey);
    border: 1px solid colour(base, grey);
    color: colour(base, white);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(base, black);
      color: colour(base, white);
      border: 1px solid colour(base, black);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      border: 1px solid colour(base, black);
    }
  }

  &--grey-alt {
    background: transparent;
    border: 1px solid colour(base, grey);
    color: colour(base, black);

    @at-root a#{&}:hover,
      &:hover:enabled {
      border: 1px solid colour(base, black);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      border: 1px solid colour(base, black);
    }
  }

  &--grey-alt-dark {
    background: transparent;
    border: 1px solid colour(base, grey);
    color: colour(base, grey);

    @at-root a#{&}:hover,
      &:hover:enabled {
      color: colour(base, white);
      border: 1px solid colour(base, white);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      border: 1px solid colour(base, white);
    }
  }

  &--brand {
    background-color: colour(brand, default);
    border: 1px solid colour(brand, default);
    color: colour(base, white);

    @at-root a#{&}:focus,
      &:focus:enabled {
      background-color: colour(base, white);
      border: 1px solid colour(brand, default);
      color: colour(brand, default);

      svg {
        fill: colour(brand, default);
      }
    }

    .u-bg-black & {
      border-color: colour(brand, default);
    }

    @at-root a#{&}:hover,
      &:hover:enabled {
      background-color: colour(base, white);
      border: 1px solid colour(brand, default);
      color: colour(brand, default);

      svg {
        fill: colour(brand, default);
      }
    }
  }

  &--brand-dark {
    background-color: colour(brand, default_dark);
    border: 1px solid colour(brand, default_dark);
    color: colour(base, white);

    @at-root a#{&}:focus,
      &:focus:enabled {
      background-color: colour(base, white);
      border: 1px solid colour(brand, default_dark);
      color: colour(brand, default_dark);

      svg {
        fill: colour(brand, default_dark);
      }
    }

    .u-bg-black & {
      border-color: colour(brand, default_dark);
    }

    @at-root a#{&}:hover,
      &:hover:enabled {
      background-color: colour(base, white);
      border: 1px solid colour(brand, default_dark);
      color: colour(brand, default_dark);

      svg {
        fill: colour(brand, default_dark);
      }
    }
  }

  &--brand-invert {
    background: colour(base, white);
    border: 1px solid colour(brand);
    color: colour(brand);

    &--transparent {
      background: none;

      @at-root a#{&}:focus,
        &:focus:enabled {
        border: 1px solid colour(brand) !important;
      }
    }

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(brand);
      border: 1px solid colour(base, white);
      color: colour(base, white);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      border: 1px solid colour(base, white);
    }

    &[disabled] {
      color: colour(base, light_grey);
      cursor: not-allowed;
    }
  }

  &--black-invert {
    background: colour(base, white);
    border: 1px solid colour(base, black);
    color: colour(base, black);

    &--transparent {
      background: none !important;

      @at-root a#{&}:focus,
        &:focus:enabled {
        border: 1px solid colour(base, dark_grey) !important;
      }
    }

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(base, white);
      border: 1px solid colour(base, dark_grey);
      color: colour(base, dark_grey);
    }

    @at-root a#{&}:focus,
      &:focus:enabled {
      border: 1px solid colour(base, black);
    }

    &[disabled] {
      color: colour(base, light_grey);
      cursor: not-allowed;
    }
  }

  &--facebook {
    background-color: colour(socialMedia, facebook);
    border-color: colour(socialMedia, facebook);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background-color: colour(base, black);
    }
  }

  &--twitter {
    background-color: colour(socialMedia, twitter);
    border-color: colour(socialMedia, twitter);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background-color: colour(base, black);
    }
  }

  &--instagram {
    background: colour(socialMedia, instagram);
    border-color: colour(socialMedia, instagram);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background-color: colour(base, black);
    }
  }

  &--reddit {
    background-color: colour(socialMedia, reddit);
    border-color: colour(socialMedia, reddit);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background-color: colour(base, black);
    }
  }

  &--whatsapp {
    background-color: colour(socialMedia, whatsapp);
    border-color: colour(socialMedia, whatsapp);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background-color: colour(base, black);
    }
  }

  &--fb-messenger {
    background-color: colour(base, very_light_grey);
    border-color: colour(base, very_light_grey);

    svg {
      fill: colour(socialMedia, facebookMessenger);
    }

    @at-root a#{&}:hover,
      &:hover:enabled {
      background-color: colour(base, white);
      border-color: colour(base, white);
    }
  }

  &--corner-close {
    position: absolute;
    top: -14px;
    right: -14px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: colour(base, devil_grey);
    box-shadow: $cardShadowNormal;
    transition: background $transition;

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(base, cloud_grey);
    }
  }

  // Saving projects button
  &--save {
    padding: gap(s) gap(sm);

    &--active {
      background: colour(base, black);
      border-color: colour(base, black);
      color: colour(base, white);

      @at-root a#{&}:hover,
        &:hover:enabled {
        @include desktopOnly() {
          background: colour(brand, default);
          border-color: colour(brand, default);
        }

        svg {
          fill: colour(base, white);
        }
      }

      svg {
        fill: colour(base, white);
      }
    }
  }

  // Creating a project category buttons
  &--category {
    width: 92px;
    height: 92px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 100%;
    border: 1px solid colour(base, grey);
  }
}

.counter-badge {
  font: $font;
  color: colour(base, white);
  background: colour(other, coral);
  text-align: center;
  display: inline-block;
  font-size: 10px;
  line-height: normal;
  font-weight: 600;
  border-radius: 1.2em;
  min-width: 2.3em;
  height: 2.3em;
  padding: 0.5em;
  margin-left: 4px;
  pointer-events: none;

  &--exponent {
    top: -6px;
    line-height: normal;
    position: absolute;
    box-shadow: 0 0 0 2px colour(base, white);
    padding: 0.3em;
    min-width: 1.9em;
    height: 1.9em;

    @include tabletAndDesktopOnly() {
      top: -10px;
      font-size: 11px;
      padding: 0.4em;
      min-width: 2em;
      height: 2em;
    }
  }

  &--hug {
    right: -10px;
  }

  &--nudge-up {
    position: relative;
    top: -3px;
  }

  &-inline {
    background: colour(other, coral);
    display: inline-block;
    min-width: 18px;
    font-style: normal;
    text-align: center;
    margin-left: 5px;
    border-radius: 16px;
    color: #fff;
    height: 18px;
    font-size: 12px;
    line-height: 18px;
    padding: 0 4px;
  }
}

.counter-dot {
  background: colour(other, coral);
  width: 0.5em;
  height: 0.5em;
  border-radius: 100%;

  &--brand {
    background: colour(brand, default);
  }

  &--white {
    background: colour(base, white);

    @at-root a#{&}:hover,
      &:hover:enabled {
      background: colour(base, grey);
    }

    @at-root a#{&}:hover,
      &:focus:enabled {
      background: colour(base, white);
    }
  }

  &--grey {
    background: colour(base, grey);
  }
}

.social-buttons {
  &-outer {
    width: 100%;
    overflow: hidden;
  }

  &-inner {
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    margin-bottom: -10px;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  &-grid {
    display: flex;
  }

  &-arrow-decoration {
    &--left {
      margin-top: -4px;

      @include tabletAndDesktopOnly() {
        margin-top: -22px;
      }
    }
    &--right {
      margin-top: 0px;
      margin-left: -2px;

      @include tabletAndDesktopOnly() {
        margin-top: -8px;
        margin-left: -12px;
      }
    }
  }

  &-item {
    flex-shrink: 0;

    div {
      transition: opacity $transition;
    }

    &:hover {
      div {
        opacity: 0.7;
      }
    }

    &--full-width {
      max-width: 100vw;
      width: 100%;

      @include tabletOnly() {
        max-width: calc(100vw - 256px);
        width: 100%;
      }

      @include desktopOnly() {
        max-width: calc(1440px - 48px);
        width: 100%;
      }
    }
  }
}
