$collectionContainerHeight: 60vh;

@keyframes pulseSkeletonLoading {
  0% {
    background: colour(base, almost_black);
  }

  50% {
    background: colour(base, charcoal_grey);
  }

  100% {
    background: colour(base, almost_black);
  }
}

.c-collection-card {
  background: colour(base, almost_black);
  border-radius: $defaultBorderRadius / 2;
  transition: background $transition;

  &:hover {
    background: colour(base, charcoal_grey);
  }

  &--loading {
    animation-name: pulseSkeletonLoading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
}

.c-collection-page {
  &__container {
    @include tabletAndDesktopOnly() {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: $collectionContainerHeight;
      width: 100%;
    }
  }

  &__back {
    $size: 20px;
    background: colour(base, white);
    width: $size;
    height: $size;
    border-radius: $size;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.c-collection-clip-card {
  border-radius: $defaultBorderRadius / 2;
  height: $collectionContainerHeight;
  width: 100%;
  background: colour(base, almost_black);
  overflow: hidden;
  text-align: left;
  transform: translateZ(0);

  &:hover {
    @include tabletAndDesktopOnly() {
      transform: scale(1.025) translateZ(0);
    }
  }

  &__fade {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    height: 33%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.c-collection-clip-card-carousel {
  width: 100%;
  overflow-x: scroll;

  @include tabletAndDesktopOnly() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(#{$collectionContainerHeight} + 24px);
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &__margin,
  &__item {
    flex-shrink: 0;
  }

  &__margin {
    // `height` is given a pixel value because iOS Safari doesn't want
    // to render this div if its the last item in a flexbox container
    // and has a percentage height
    height: 20px;
    width: 20px;

    @include tabletAndDesktopOnly() {
      height: 100%;
      width: calc((100vw - 1344px) / 2);
      min-width: 48px;
    }
  }

  &__item {
    height: 100%;
    flex-shrink: 0;
    width: calc(100% - 60px);
    margin-right: 20px;

    @include tabletAndDesktopOnly() {
      width: calc(33.333% - 48px);
      max-width: 432px;
      margin-right: 24px;
    }
  }
}
