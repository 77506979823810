header[role='banner'] {
  background: colour(base, white);
  z-index: z-index(header);
  height: $mobileHeaderHeight;
  width: 100vw;
  left: 0;
  top: 0;
  transition: background $transition;

  @include tabletAndDesktopOnly() {
    position: fixed;
    height: $largerHeaderHeight;
    min-height: $largerHeaderHeight;

    .page-layout--cookie-banner & {
      position: sticky;
    }
  }

  [data-auth='false'] & {
    @include smallMobileOnly() {
      .dds-button {
        padding: 8px 12px;
      }
    }

    @include tabletAndDesktopOnly() {
      height: $largerHeaderHeight;
    }
  }

  &.header--transparent {
    background: transparent !important;
  }

  &.header--dark {
    background: colour(base, black);

    .c-animated-wordmark--black {
      opacity: 0;
    }

    .c-animated-wordmark--white {
      opacity: 1;
    }

    .counter-badge {
      box-shadow: none;
    }
  }

  &.header--transparent-dark {
    background: transparent;
  }

  &.header--transparent-light {
    background: transparent;

    .c-animated-wordmark--black,
    .wordmark-container {
      @include tabletAndDesktopOnly() {
        opacity: 0;
      }
    }

    .c-animated-wordmark--white {
      opacity: 1;
    }
  }

  .wordmark-container {
    .wordmark {
      padding: 2px 1px;
      height: 26px;
      width: 80px;
    }

    .wordmark--animate {
      padding: 0;
      height: 26px;
      width: 80px;
    }

    svg {
      width: 24px;
    }
  }

  .navicon {
    position: relative;
    width: gap(ml);
    height: gap(ml);

    i {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: currentColor;
      transition: 500ms background-color;
      left: 0;

      &:nth-child(1),
      &:nth-child(3) {
        transform-origin: 0 50%;
        transition: $transition transform;
      }

      &:nth-child(1) {
        top: 2px;
      }

      &:nth-child(2) {
        top: 8px;
        transition: $transition opacity;
      }

      &:nth-child(3) {
        top: 14px;
      }
    }

    &--active {
      i {
        &:nth-child(1) {
          transform: translateX(3px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translateX(3px) translateY(2px) rotate(-45deg);
        }
      }
    }
  }

  .nav-overlay {
    transition: $transition opacity, $transition visibility;
    position: fixed;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    background: colour(base, black);
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    opacity: 0;
    visibility: hidden;
    overflow-y: scroll;

    &--open {
      visibility: visible;
      opacity: 1;
    }

    &--avatar {
      z-index: 0;
    }

    &--padding {
      padding: #{$mobileHeaderHeight + 28px} gap(ml) gap(l);
    }

    &--padding-download-banner {
      // Apply padding-top only on Chrome, as you get native install pop-up on safari.
      @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
        padding-top: $mobileHeaderHeight * 2.3 !important;
      }
    }

    &--padding-discord-banner {
      padding-top: $mobileHeaderHeight * 2.3 !important;
    }
  }
}

.c-room-workshop-nav-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: z-index(modal) + 1;
  transition: transform $transition;
  pointer-events: none;
  padding-left: 32px;
  padding-right: 32px;

  &--invisible {
    transform: translateY(-100%);
  }

  &--visible {
    transform: translateY(0%);
  }

  &__close {
    transform: translate(35%, -35%);
  }

  &__content {
    width: 100%;
    max-width: 1344px;
    margin-top: $headerHeight + 32px;
    border-radius: 30px;
    pointer-events: auto;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
    margin-left: auto;
    margin-right: auto;
    transition: height $transition background $transition;

    &--light {
      background: colour(base, white);
    }

    &--dark {
      background: colour(base, black);
    }
  }

  &__empty_state {
    height: 200px;
  }

  &__close_fill {
    z-index: z-index(modal);
  }
}

.c-room-workshop-nav-dropdown-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: transform $transition;
  pointer-events: none;
  z-index: z-index(modal) + 100;

  &__content {
    width: 100%;
    max-width: 1344px;
    margin-top: $mobileHeaderHeight;
    pointer-events: auto;
    margin-left: auto;
    margin-right: auto;
    transition: height $transition background $transition;
    overflow: auto;
  }
}

.c-navigation {
  &__dot {
    $size: 8px;
    border-radius: $size;
    width: $size;
    height: $size;
    background: colour(other, coral);

    &--dropdown {
      position: absolute;
      bottom: 2px;
      right: 0;
    }
  }
}
