.c-picker {
  .c-checkbox + label {
    -webkit-tap-highlight-color: transparent;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 32px;

    &:after {
      display: none;
    }

    &:before {
      border-radius: 100%;
      background: transparent;
      border: colour(base, grey) 2px solid;
      width: 24px;
      height: 24px;
      top: -1px;
      box-sizing: border-box;
    }
  }

  .c-checkbox:checked + label:before {
    background: linear-gradient(
      90deg,
      #{colour(designSystem, brandA)} 0%,
      #{colour(designSystem, brandB)} 100%
    );
    border: none;
  }

  .c-checkbox:checked + label:after {
    background: white;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: block;
    left: 6px;
    top: 5px;
  }

  @include mobileOnly() {
    height: 100%;
    overflow-y: scroll;
  }

  &__dropdown {
    text-align: left;
    padding: gap(s);
    border: 1px solid colour(base, grey);
    border-radius: $defaultBorderRadiusOld;

    & ~ .c-dropdown {
      z-index: 201;
      top: calc(100% + 8px);

      @include mobileOnly() {
        z-index: 301;
        width: 100%;
        max-width: none;
        height: 100%;
        position: fixed;
        background-color: #ffffff;
        top: 0;
        left: 0;
        opacity: 0.97;
        display: flex;
        align-items: center;
      }
    }

    @include tabletAndDesktopOnly() {
      padding: gap(sm);
    }
  }

  &__tag {
    padding: 12px 0;
    height: auto;
    width: fit-content;
    transition: $transition;

    svg {
      transition: 300ms fill;
    }

    button {
      transition: 300ms color;
      padding: gap(s) gap(m);
    }

    &:focus,
    &:hover {
      border-color: colour(base, black);

      button {
        color: colour(base, black);

        svg {
          fill: colour(base, black);
        }
      }
    }
    
    button {
      padding: 0;
    }
  }

  &__clear {
    z-index: calc(#{z-index(dropdown)} + 2);
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.3;

    &:focus,
    &:hover {
      border-color: colour(base, grey);

      button {
        color: colour(base, grey);

        svg {
          fill: colour(base, grey);
        }
      }
    }
  }
}
