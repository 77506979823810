@mixin createSpacing($size, $breakpoint: null, $important: null) {
  $breakpoint-suffix: '';

  $important-suffix: '';
  $important-modifier: '';

  @if $breakpoint != null {
    $breakpoint-suffix: \@#{$breakpoint};
  }

  @if $important != null {
    $important-suffix: '-i';
    $important-modifier: ' !important';
  }

  $size-with-unit: #{$size}px#{$important-modifier};

  .m#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    margin: $size-with-unit;
  }
  .mt#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    margin-top: $size-with-unit;
  }
  .mb#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    margin-bottom: $size-with-unit;
  }
  .ml#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    margin-left: $size-with-unit;
  }
  .mr#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    margin-right: $size-with-unit;
  }
  .mv#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    margin-top: $size-with-unit;
    margin-bottom: $size-with-unit;
  }
  .mh#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    margin-left: $size-with-unit;
    margin-right: $size-with-unit;
  }
  .p#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    padding: $size-with-unit;
  }
  .pv#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    padding-top: $size-with-unit;
    padding-bottom: $size-with-unit;
  }
  .ph#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    padding-left: $size-with-unit;
    padding-right: $size-with-unit;
  }
  .pt#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    padding-top: $size-with-unit;
  }
  .pb#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    padding-bottom: $size-with-unit;
  }
  .pl#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    padding-left: $size-with-unit;
  }
  .pr#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    padding-right: $size-with-unit;
  }
  .t#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    top: $size-with-unit;
  }
  .r#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    right: $size-with-unit;
  }
  .b#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    bottom: $size-with-unit;
  }
  .l#{$size}#{$breakpoint-suffix}#{$important-suffix} {
    left: $size-with-unit;
  }
}

@mixin createMarginAuto($breakpoint: null, $important: null) {
  $breakpoint-suffix: '';

  $important-suffix: '';
  $important-modifier: '';

  @if $breakpoint != null {
    $breakpoint-suffix: \@#{$breakpoint};
  }

  @if $important != null {
    $important-suffix: '-i';
    $important-modifier: ' !important';
  }

  $auto: auto#{$important-modifier};

  .mtauto#{$breakpoint-suffix}#{$important-suffix} {
    margin-top: $auto;
  }

  .mrauto#{$breakpoint-suffix}#{$important-suffix} {
    margin-right: $auto;
  }

  .mbauto#{$breakpoint-suffix}#{$important-suffix} {
    margin-bottom: $auto;
  }

  .mlauto#{$breakpoint-suffix}#{$important-suffix} {
    margin-left: $auto;
  }

  .m0auto#{$breakpoint-suffix}#{$important-suffix} {
    margin: 0 #{$auto};
  }

  .mhauto#{$breakpoint-suffix}#{$important-suffix} {
    margin-left: $auto;
    margin-right: $auto;
  }

  .mvauto#{$breakpoint-suffix}#{$important-suffix} {
    margin-top: $auto;
    margin-bottom: $auto;
  }
}

// sizes should match gaps array in settings.scss
$sizes: (
  -128,
  -100,
  -80,
  -64,
  -60,
  -48,
  -44,
  -32,
  -24,
  -12,
  -8,
  -4,
  -2,
  -1,
  0,
  1,
  2,
  4,
  6,
  8,
  12,
  16,
  20,
  24,
  32,
  44,
  48,
  60,
  64,
  80,
  100,
  128,
  200,
  256,
  312,
  368
);

@include createMarginAuto(null, null);
@include createMarginAuto(null, true);

@each $size in $sizes {
  @include createSpacing($size);
  @include createSpacing($size, null, true);
}

@include mobileOnly() {
  @include createMarginAuto(s, null);
  @include createMarginAuto(s, true);

  @each $size in $sizes {
    @include createSpacing($size, s);
    @include createSpacing($size, s, true);
  }
}

@include tabletAndDesktopOnly() {
  @include createMarginAuto(m, null);
  @include createMarginAuto(m, true);

  @each $size in $sizes {
    @include createSpacing($size, m);
    @include createSpacing($size, m, true);
  }
}

@include desktopOnly() {
  @include createMarginAuto(l, null);
  @include createMarginAuto(l, true);

  @each $size in $sizes {
    @include createSpacing($size, l);
    @include createSpacing($size, l, true);
  }
}

.mtheader {
  margin-top: -#{$headerHeight};
}

.mtheader\@s {
  @include mobileOnly() {
    margin-top: -#{$headerHeight};
  }
}

.pb16by9 {
  padding-bottom: $aspectRatio16-9;
}

.ptheader\@s {
  @include mobileOnly() {
    padding-top: $headerHeight;
  }
}
