$carouselHeight: 310px;

.c-section-workshops {
  &__workshop_carousel {
    @include tabletAndDesktopOnly() {
      height: $carouselHeight;
    }

    .carousel {
      @include desktopOnly() {
        overflow: visible;
        position: relative;
      }

      &__item {
        @include tabletAndDesktopOnly() {
          height: $carouselHeight;
        }
      }

      &__buttons {
        display: none;
      }
    }

    .c-workshop-card {
      @include tabletAndDesktopOnly() {
        height: 100%;
      }
    }

    &__copy {
      @include tabletAndDesktopOnly() {
        position: relative;
        height: $carouselHeight;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
      }

      &__content {
        @include tabletAndDesktopOnly() {
          position: relative;
          z-index: 1;
        }
      }

      &__mask {
        &--dark {
          background: rgb(0, 0, 0);
          background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 1) 95%,
            rgba(0, 0, 0, 0) 100%
          );
        }

        &--light {
          background: rgb(249, 249, 249);
          background: linear-gradient(
            90deg,
            rgba(249, 249, 249, 1) 95%,
            rgba(249, 249, 249, 0) 100%
          );
        }

        @include tabletAndDesktopOnly() {
          width: 100vw;
          height: 100%;
          position: absolute;
        }

        @include desktopOnly() {
          right: 0;
        }
      }
    }

    &__card {
      &__avatar {
        width: 36px;
        height: 36px;
      }

      &__description {
        height: 2.3rem;
      }
    }
  }
}
