.dds-progress-bar {
  width: 100%;
  height: 5px;
  background: colour(other, translucentWhite);
  border-radius: 20px;
  overflow: hidden;

  &__fill {
    background: colour(base, white);
    height: 5px;
    transition: width $transition;
  }
}

.dds-progress-bar-arc {
  circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
}
