.c-follow {
  &--icons {
    padding-left: 12px;
    padding-right: 28px;
    position: relative;

    svg {
      transform: translateZ(0);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: colour(base, grey);
      transition: $transition all;

      width: 10px;
      height: 1px;
      right: 15px;
      top: 50%;
      margin-top: -1px;
    }

    &:before {
      transform: rotate(-90deg);
    }

    &:hover {
      &:before,
      &:after {
        background-color: colour(base, black);
      }
    }
  }

  &--following-show-unfollow {
    &:before,
    &:after {
      background-color: colour(other, coral) !important;
    }

    &:before {
      transform: translate(1px, 1px) rotate(-45deg) scale(1.333) !important;
    }

    &:after {
      transform: translate(1px, 1px) rotate(45deg) scale(1.333) !important;
    }
  }

  &--following,
  &--following:focus,
  &--following:hover {
    &:before,
    &:after {
      background-color: colour(other, go);
    }

    &:before {
      transform: translateX(2px) rotate(-45deg) scale(1.333);
    }

    &:after {
      transform: translate(-4px, 3px) rotate(45deg) scale(1.333) scaleX(0.4);
    }
  }

  @include tabletAndDesktopOnly {
    &--not-following {
      transition: $transition border-color, $transition color;
    }
  }
}
