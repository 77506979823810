.dds-dropdown-menu {
  width: 100%;

  &:first-child {
    margin-top: 12px;
  }

  &:last-child {
    margin-bottom: 12px;
  }

  li {
    display: flex;

    button {
      flex: 1;
      text-align: left;
      margin: 0 12px;
      padding: 8px 12px;
      border-radius: 12px;

      &:hover {
        background: colour(brand, default);
        color: colour(base, white);
      }
    }
  }
}
