.c-teach {
  &__logo {
    width: 300px;

    @include tabletAndDesktopOnly() {
      width: 500px;
    }
  }

  &__img {
    object-position: 50% 22px;

    .opacity-1 {
      opacity: 0.85;
    }
  }
}
