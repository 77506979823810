/* Media Queries */
@mixin mq($min, $max: false) {
  @if ($min and $max == false) {
    @media screen and (min-width: $min) {
      @content;
    }
  } @else if ($min and $max == true) {
    @media screen and (max-width: $min) {
      @content;
    }
  } @else if ($min and $max) {
    @media screen and (min-width: $min) and (max-width: $max) {
      @content;
    }
  }
}

@mixin smallMobileOnly() {
  @media screen and (max-width: $small-mobile-size) {
    @content;
  }
}

@mixin bigMobileOnly() {
  @media screen and (min-width: $big-mobile-size) and (max-width: $tablet-size) {
    @content;
  }
}

@mixin mobileOnly() {
  @media screen and (max-width: $mobile-upper-bound) {
    @content;
  }
}

@mixin mobileAndTabletOnly() {
  @media screen and (max-width: $tablet-upper-bound) {
    @content;
  }
}

@mixin tabletOnly() {
  @media screen and (min-width: $tablet-lower-bound) and (max-width: $tablet-upper-bound) {
    @content;
  }
}

@mixin tabletAndDesktopOnly() {
  @media screen and (min-width: $tablet-lower-bound) {
    @content;
  }
}

@mixin desktopOnly() {
  @media screen and (min-width: $desktop-lower-bound) {
    @content;
  }
}

@mixin mobileAndDesktopOnly() {
  @media screen and (max-width: $mobile-upper-bound),
    screen and (min-width: $desktop-lower-bound) {
    @content;
  }
}

@mixin transitionGroup(
  $animation,
  $property,
  $time,
  $from,
  $to,
  $timing: ease-in-out
) {
  &#{$animation}-enter#{$animation}-enter-active,
  &#{$animation}-appear#{$animation}-appear-active,
  &#{$animation}-leave {
    #{$property}: $to;
  }

  &#{$animation}-leave#{$animation}-leave-active,
  &#{$animation}-enter,
  &#{$animation}-appear {
    #{$property}: $from;
  }

  &#{$animation}-leave#{$animation}-leave-active,
  &#{$animation}-enter#{$animation}-enter-active,
  &#{$animation}-appear#{$animation}-appear-active {
    transition: $property $time $timing;
  }
}

// extend classname of div with a scrollable child element
// apply mixin to parent div
// i.e. c-sub-navigation__wrapper and c-sub-navigation

// .c-sub-navigation__wrapper {
//   @include hide-horizontal-scrollbar();
// }
@mixin hide-horizontal-scrollbar($height: 36px) {
  $scrollBarWidth: 24px;

  height: $height;
  overflow-y: hidden;

  & > :first-child {
    height: $height + $scrollBarWidth;
    padding-bottom: $scrollBarWidth;
    overflow-x: scroll;
  }
}

@mixin blurBackground() {
  -webkit-backdrop-filter: saturate(150%) blur(5px);
  backdrop-filter: saturate(150%) blur(5px);
}

@mixin safariBorderRadiusFix() {
  // For some reason, macOS Safari flashes non-rounded corners without this fix
  mask-image: radial-gradient(white, black);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
