.avatar-initials {
  font-size: 1em;
  text-align: center;

  &--medium {
    font-size: 0.8em;
    font-weight: 500;
  }

  &--m {
    font-size: 0.65em;
  }

  &--large {
    width: 32px;
  }

  &--xl {
    font-size: 1.2em;
  }

  &--xxl {
    font-size: 1.4em;
  }

  &--100pc {
    font-size: 2.5em;

    @include tabletAndDesktopOnly() {
      font-size: 4.5em;
    }
  }

  &--profile {
    font-size: 1em;

    @media screen and (min-width: 31.4em) and (max-width: $tablet-lower-bound) {
      font-size: 4.5em;
    }

    @media screen and (min-width: $tablet-lower-bound) and (max-width: $tablet-upper-bound) {
      font-size: 4.5em;
    }

    @media screen and (min-width: $tablet-upper-bound) and (max-width: 1180px) {
      font-size: 1.5em !important;
    }

    @include desktopOnly() {
      font-size: 4.5em;
    }
  }

  &--aspect {
    @extend .avatar-initials--profile;
  }

  &--notification {
    font-size: 0.4em;
  }

  &--circle {
    font-size: 2.5rem;

    @include tabletAndDesktopOnly() {
      font-size: 5rem;
    }
  }
}

.avatar {
  width: gap();
  height: gap();
  object-fit: cover;
  font-size: 0px;
  background-color: colour(base, very_light_grey);

  &__container {
    border-radius: 100%;
    overflow: hidden;
    @include safariBorderRadiusFix();

    &--circle {
      position: relative;
      height: 0;
      overflow: hidden;
      background-color: colour(pastel, light_grey);
      background-repeat: no-repeat;
      padding-bottom: $aspectRatio1-1;
      border-radius: 100%;
    }
  }

  &--inline {
    display: inline-block;
    vertical-align: middle;
    margin-right: gap(s);
  }

  &--count {
    background: colour(base, white);
    line-height: 200%;
    border: 1px solid colour(base, light_grey);
  }

  &--aspect {
    width: 100%;
    height: 100%;
  }

  &--medium {
    width: gap(l);
    height: gap(l);
  }

  &--large {
    width: gap(xl);
    height: gap(xl);
  }

  &--xl {
    width: 40px;
    height: 40px;
  }

  &--xxl {
    width: 64px;
    height: 64px;
  }

  &--xxxl {
    width: 72px;
    height: 72px;
  }

  &--xxxxl {
    width: 95px;
    height: 95px;
  }

  &--xxxxxl {
    width: 150px;
    height: 150px;

    @include mobileOnly() {
      width: 165px;
      height: 165px;
    }
  }

  &--feed {
    width: 40px;
    height: 40px;

    @include tabletAndDesktopOnly() {
      width: gap(xxl);
      height: gap(xxl);
    }
  }

  &--notification {
    width: 40px;
    height: 40px;
  }

  &--adaptive {
    width: 40px;
    height: 40px;

    @include tabletAndDesktopOnly() {
      height: gap(xl);
      width: gap(xl);
    }
  }

  &--100pc,
  &--profile {
    width: 100%;
    height: 100%;
  }

  &--circle {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-group {
    display: inline-block;

    .avatar {
      box-shadow: 0 0 0 2px colour(base, white);
      float: left;

      & ~ .avatar {
        margin-left: -16px;

        &--large {
          margin-left: -18px;
        }
      }
    }
  }
}
