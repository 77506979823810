.dds-carousel {
  display: flex;
  height: 100%;
  width: 100%;
  overflow-x: scroll;

  // Disable scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @include tabletAndDesktopOnly() {
    // Disable mouse scrolling on desktop
    pointer-events: none;

    // Add padding so transform: scale doesn't cause clipping
    padding-top: 24px;
    padding-bottom: 24px;
  }

  &__buttons {
    width: calc(100vw - 10rem);
  }

  &__item {
    // Mobile spacing
    margin-right: 12px;
    width: calc(100vw - 3 * 16px);
    flex-shrink: 0;
    pointer-events: all;
    overflow: hidden;
    transition: opacity 400ms, transform 500ms;

    &:first-of-type {
      @include mobileOnly() {
        margin-left: 20px;
      }
    }

    @include tabletAndDesktopOnly() {
      margin-right: 0;
      width: 37vw;
      height: 50%;
    }

    &--on {
      @include tabletAndDesktopOnly() {
        opacity: 1;
        transform: scale(1);

        & > div > img {
          animation: scale-in 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }

        @keyframes scale-in {
          from {
            transform: scale(1);
          }
          to {
            transform: scale(1.072);
          }
        }
      }
    }

    &--off {
      @include tabletAndDesktopOnly() {
        opacity: 0.4;
        transform: scale(0.9);

        & > div > img {
          animation: scale-out 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }

        @keyframes scale-out {
          from {
            transform: scale(1.072);
          }
          to {
            transform: scale(1);
          }
        }
      }
    }
  }

  &__shadow {
    z-index: 1;
    position: absolute;
    padding: 12%;
    opacity: 0.8;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(to bottom, transparent, #000000);
  }
}
