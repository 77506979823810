.c-download-app-banner {
  position: fixed;
  z-index: 999;
  color: colour(base, white);
  background: colour(designSystem, brandB);
}

// This is to hide this download app banner on safari, as you get the native install pop-up at top of the page.
@include mobileOnly {
  &_::-webkit-full-page-media,
  :root .c-download-app-banner {
    display: none;
  }
}
