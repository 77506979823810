.content-editable {
  position: relative;
  cursor: text;
  font-family: $font_stack_console;

  [contenteditable] {
    -webkit-user-select: text;
    user-select: text;

    &:focus {
      outline: 0px solid transparent;
    }
  }

  &--disable-outline {
    -webkit-user-select: text;
    user-select: text;

    &:focus {
      outline: 0px solid transparent;
    }
  }

  &__active_area,
  &__background_area {
    white-space: pre-wrap;
    display: block;
    min-width: 2px;
  }

  &__background_area {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    color: rgba(0, 0, 0, 0);

    em {
      font-style: normal;
      background: rgba(255, 0, 0, 0.25);
    }
  }

  &__focus_helper {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}
