.c-join-flow {
  &__pay {
    &--background {
      background-image: linear-gradient(
          0deg,
          colour(base, black) 60%,
          transparent
        ),
        linear-gradient(-100deg, colour(designSystem, brandB) 30%, transparent);
      background-color: colour(designSystem, brandA);
    }

    &--icon {
      @include tabletAndDesktopOnly() {
        margin: auto 0 0 0;
      }

      &-container {
        @include tabletAndDesktopOnly() {
          min-height: 150px;
          max-height: 150px;
        }

        @include desktopOnly() {
          min-height: 200px;
          max-height: 200px;
        }
      }
    }
  }

  &__frame {
    &--caption {
      line-height: 2rem;
    }
  }
}

.c-apple-pay-button {
  background: white;
  height: 54px;
  border-radius: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont;
  font-weight: 500;

  img {
    height: 20px;
  }
}

.c-workshop-hero-wrapper {
  height: 250px;
  width: 100vw;
  position: absolute;
  top: 60px;
  left: 0;

  @include tabletAndDesktopOnly() {
    position: absolute;
    top: 0;
    left: 0;
    width: 990px;
    height: 100vh;
    overflow: hidden;
  }

  &__spacer {
    height: 250px;
    width: 100vw;

    @include tabletAndDesktopOnly() {
      display: none;
    }
  }
}

.c-workshop-hero {
  &-desktop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 15px;
  }

  &-skeleton {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 15px;
    margin: 15px 0;
  }

  &-a {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    transform: scale(1.2);
  }

  &-b {
    grid-gap: 10px;
    transform: scale(1.2);
    margin-top: 39px;
  }

  &--img {
    width: 100%;
    height: 100%;
  }

  &--img-container {
    min-width: 205px;
    max-width: 205px;
    max-height: 130px;
    min-height: 130px;

    @include tabletAndDesktopOnly() {
      min-width: 280px;
      min-height: 180px;
    }
  }

  &-container {
    height: 252px;
  }

  &-shadow {
    z-index: 1;
    position: absolute;
    padding: 16%;

    @include mobileOnly() {
      padding: 7%;
    }

    &--bottom {
      bottom: 0;
      width: 100%;
      background-image: linear-gradient(to bottom, transparent, #000000);
    }

    &--right {
      right: 0;
      height: 100%;
      background-image: linear-gradient(to right, transparent, #000000);
    }

    &--top {
      top: 0;
      width: 100%;
      background-image: linear-gradient(to top, transparent, #000000);
    }
  }
}
