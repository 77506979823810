.c-workshop-review {
  &--slide-left {
    animation: slideLeft 36s linear infinite;
    height: fit-content;

    &:hover {
      animation-play-state: paused;
    }
  }

  &--slide-right {
    animation: slideRight 36s linear infinite;
    height: fit-content;

    &:hover {
      animation-play-state: paused;
    }
  }

  &--slide-row {
    animation: slideRow 36s linear infinite;
    width: fit-content;
  }

  &--score {
    font-size: 5rem;
  }
}

.c-workshop-review-v2 {
  height: 6000px;

  @include tabletOnly {
    height: 5000px;
  }

  @include desktopOnly {
    height: 4000px;
  }

  &__content {
    width: 100%;
    top: 50vh;
    margin: 0 auto;
  }

  &__intro {
    width: 100%;
    max-width: 600px;
    transform-origin: center;

    @include mobileOnly {
      h3 {
        line-height: 2.5rem;
      }
    }
  }

  &__carousel {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    overflow: hidden;
    max-width: 100%;
  }

  &__card {
    background-color: colour(base, black);
    border: 1px solid colour(base, sad_grey);
    border-radius: 16px;
    height: 100%;

    p {
      font-size: 1.125rem;
    }

    span {
      color: colour(base, dark_grey);
      font-size: 1.125rem;
    }
  }

  .carousel__track {
    padding-left: 24px;
    padding-right: 24px;
    transition: none;
  }

  .carousel__container {
    overflow: hidden;
  }

  .carousel__item {
    height: auto;
  }
}

@keyframes slideLeft {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-50%);
  }
}

@keyframes slideRight {
  from {
    transform: translateY(-50%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideRow {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}
