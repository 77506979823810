$font_stack: 'Lynstone', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
$font: 400 100% /1.375 $font_stack;
$font_stack_console: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas',
  'source-code-pro', monospace;

$small-mobile-size: 23em; // 368px
$big-mobile-size: 33.75em; // 540px;
$tablet-size: 45em; // 720px
$x-small-desktop-size: 60em; // 960px
$small-desktop-size: 90em; // 1440px
$medium-desktop-size: 96em; // 1536px
$regular-desktop-size: 120em; // 1920px
$large-desktop-size: 160em; // 2560px
$x-large-desktop-size: 240em; // 3840px

$tablet-lower-bound: $tablet-size;
$desktop-lower-bound: $x-small-desktop-size;
$mobile-upper-bound: ($tablet-size - 0.0625em);
$tablet-upper-bound: ($x-small-desktop-size - 0.0625em);

$transition: 250ms;
$transitionSlow: 400ms;
$mobileHeaderHeight: 60px;
$headerHeight: 60px;
$largerHeaderHeight: 72px;
$defaultBorderRadius: 32px;
$defaultBorderRadiusOld: 4px;
$mobileNavigatorHeight: 64px;
$sideBarWidth: 18.75rem; // 300px
$bottomBannerHeight: 88px;
$bottomBannerHeightMobile: 80px;

$aspectRatio16-9: 56.25%;
$aspectRatioVimeo: 53.25%;
$aspectRatio21-9: 42.85%;
$aspectRatio1-1: 100%;
$aspectRatio4-3: 75%;
$aspectRatioFeatured: 39.88%;

$colours: (
  designSystem: (
    brandA: rgba(133, 67, 227, 1),
    brandB: rgba(99, 59, 235, 1),
  ),
  brand: (
    default: rgba(133, 67, 227, 1),
    default_dark: #4146bf,
    pale: #ececff,
    old: #ff861e,
    interaction: #237de7,
    translucent: rgba(100, 104, 221, 0.5),
  ),
  pastel: (
    yellow: #fdf4e2,
    green: #e5f6e5,
    blue: #e2eefb,
    red: #fbe4e5,
    light_grey: #f2f2f2,
  ),
  base: (
    transparent: rgba(255, 255, 255, 0),
    default: #fff,
    white: #ffffff,
    black: #000000,
    almost_black: #111111,
    charcoal_grey: #19191b,
    sad_grey: #333333,
    devil_grey: #666666,
    dark_grey: #737677,
    cloud_grey: #bbb,
    grey: #adb2b4,
    light_grey: #dadbdd,
    overcast_grey: #e5e5e5,
    very_light_grey: #f6f6f6,
    off_white: #f9f9f9,
    skeleton_grey: #f0f0f0,
  ),
  other: (
    alert: #ff4646,
    blueAlert: #7d81e8,
    go: #24c050,
    coral: #ff5261,
    orange: #ff861e,
    interaction: #4085f3,
    gold: #e8bb16,
    yellow: #fde444,
    veryTranslucentBlack: rgba(0, 0, 0, 0.3),
    veryTranslucentWhite: rgba(255, 255, 255, 0.3),
    translucentBlack: rgba(0, 0, 0, 0.5),
    translucentWhite: rgba(255, 255, 255, 0.5),
    lessTranslucentBlack: rgba(0, 0, 0, 0.8),
    lessTranslucentWhite: rgba(255, 255, 255, 0.8),
  ),
  textBlock: (
    light: #ffffff,
    medium: #e9e9e9,
    dark: #222222,
  ),
  newPalette: (
    grey: #323232,
    dark_grey: #666666,
    off_white: #fbfbfb,
  ),
  socialMedia: (
    facebook: #3b5998,
    twitter: #00acee,
    instagram:
      linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
      ),
    reddit: #ff4500,
    whatsapp: #25d366,
    facebookMessenger: #3982f7,
    google: #ea4335,
  ),
  search: (
    grey: #4f4f4f,
  ),
  workshop: (
    yellow: #ecdaa4,
    yellowHover: #ece3c7,
    originalsYellow: #ffd649,
    green: #a7c796,
    greenHover: #b2c5a8,
    pink: #dc8cae,
    pinkHover: #d6a8bb,
    lightBlue: #a7aaf8,
    lightBlueHover: #8488f3,
    blue: #545adf,
    blueHover: #464dd6,
  ),
);

$gaps: (
  default: 24px,
  xxs: 2px,
  xs: 4px,
  s: 8px,
  sm: 12px,
  m: 16px,
  ml: 20px,
  l: 24px,
  xl: 32px,
  xll: 44px,
  xxl: 48px,
  xxxl: 64px,
);

$zIndex: (
  projectHeader: 4,
  modal: 501,
  cookieBar: 450,
  header: 500,
  sidebar: 300,
  dropdown: 200,
);

$cardShadowNormal: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
$cardShadowHover: 0px 1px 15px 1px rgba(0, 0, 0, 0.1);
$buttonShadowNormal: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
$buttonShadowHover: 0 3px 8px rgba(0, 0, 0, 0.1);
