.incorrect-formats {
  width: 100%;
  max-width: 486px;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  max-height: 100%;
  text-align: left;

  header,
  footer,
  &__content {
    padding: gap(ml);
    border-color: colour(base, very_light_grey);
  }

  footer {
    text-align: right;
  }
}
