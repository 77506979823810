.new-user-profile {
  &__wrapper {
    @include tabletAndDesktopOnly() {
      height: calc(100vh - 112px);
    }
  }

  &__info {
    @include tabletAndDesktopOnly() {
      height: 88vh;
    }

    &__container {
      @include tabletAndDesktopOnly() {
        width: 30vw;
      }
    }

    &__shadow {
      z-index: 1;
      position: absolute;
      padding: 20%;
      bottom: 0;
      width: 100%;
      background-image: linear-gradient(to bottom, transparent, #000000);
    }

    &__image {
      $smallerBorderRadius: $defaultBorderRadius / 2;
      min-width: 100%;
      max-height: 50vh;
      @include tabletAndDesktopOnly() {
        border-radius: $smallerBorderRadius $smallerBorderRadius 0 0;
      }
    }

    &__user-message {
      svg {
        width: 17px;
        height: 17px;
      }
    }
  }

  &__empty-state {
    // same width as workshop cards
    @include tabletAndDesktopOnly() {
      width: 500px;
    }
  }

  &__joined-classes {
    &__item {
      @include mobileOnly() {
        width: calc(100vw - 3.5 * 16px);
        flex-shrink: 0;
        pointer-events: all;

        &:first-of-type {
          margin-left: 20px;
        }

        &:last-of-type {
          margin-right: 20px !important;
          padding-right: 20px !important;
        }

        &-single {
          width: calc(100vw - 3.5 * 12px);

          &:first-of-type {
            margin-left: 20px;
          }

          &:last-of-type {
            padding-right: 0px !important;
          }
        }
      }
    }
  }

  &__upgrade {
    &:before {
      border-radius: $defaultBorderRadius / 2 !important;
    }
  }

  &__skeleton {
    &--stats {
      width: 200px;
      height: 200px;
      margin: 0 auto;

      @include mobileOnly {
        width: 120px;
        height: 160px;
      }

      &-circle {
        width: 160px;
        height: 160px;

        @include mobileOnly {
          width: 120px;
          height: 120px;
        }

        border-radius: 100%;
      }
    }

    &--profile-image {
      height: 40vh;
    }

    &--right-section {
      height: 400px;
      width: 500px;
    }
  }
}
