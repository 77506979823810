.c-classes {
  &__sidebar {
    &__body,
    &__container {
      position: sticky;
      top: 76px;

      @include tabletAndDesktopOnly() {
        top: 92px;
      }
    }

    &__container {
      @include tabletAndDesktopOnly() {
        transform: translateX(-12px);
        min-width: 250px;
      }
    }

    &__body {
      overflow-y: auto;
      height: calc(100vh - 76px);

      @include tabletAndDesktopOnly() {
        height: calc(100vh - 92px);
      }
    }
  }
}
