.c-marketing-section-promo {
  &__circle {
    border: rgba(255, 255, 255, 0.3) 1px solid;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }
}
