.dds-button-group {
  &--normal {
    .dds-button {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--s {
    .dds-button {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @include mobileOnly() {
    text-align: center;
  }
}
