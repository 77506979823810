.c-global-tooltip {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  background: white;
  padding: 12px;

  &--animation {
    &-temporary-scale {
      transform-origin: bottom center;
      transform: scale(0);
      opacity: 0;
      transition: transform 500ms, opacity 250ms;

      &-in {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
