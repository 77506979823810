.c-modal-confirm {
  min-width: 300px;
  max-width: 600px;

  @include mobileOnly {
    min-width: 0px;
  }

  .settings-overlay & {
    min-width: 100%;
  }
}
