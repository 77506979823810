.c-subscription-welcome-back-modal {
    background: rgba(0, 0, 0, 1);
    z-index: z-index(modal);
  
    &--animate-in {
      animation-name: backgroundAnimateIn;
      animation-delay: 1500ms;
      animation-duration: 5000ms;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    }
  
    &--animate-out {
      background: rgba(0, 0, 0, 0.85);
  
      animation-name: animateOut;
      animation-duration: 500ms;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    }
  
    &__content {
      opacity: 0;
      transform: translateY(20px);
      animation-name: contentAnimatein;
      animation-delay: 500ms;
      animation-duration: 500ms;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      width: 100%;
  
      @include tabletAndDesktopOnly() {
        max-width: 450px;
      }
  
      &--animate-out {
        opacity: 1;
        transform: translateY(0px);
        animation-name: contentAnimateOut;
        animation-duration: 250ms;
        animation-delay: 0ms;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
      }
  

    }
  
    &__get_started {
      opacity: 0;
      animation-name: getStartedAnimateIn;
      animation-delay: 4000ms;
      animation-duration: 500ms;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    }
  
    @keyframes backgroundAnimateIn {
      from {
        background: rgba(0, 0, 0, 1);
      }
  
      to {
        background: rgba(0, 0, 0, 0.85);
      }
    }
  
    @keyframes contentAnimatein {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
  
      to {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  
    @keyframes contentAnimateOut {
      from {
        opacity: 1;
        transform: translateY(0px);
      }
  
      to {
        opacity: 0;
        transform: translateY(20px);
      }
    }
  
    @keyframes getStartedAnimateIn {
      from {
        opacity: 0;
      }
  
      to {
        opacity: 1;
      }
    }
  
    @keyframes animateOut {
      from {
        opacity: 1;
      }
  
      to {
        opacity: 0;
      }
    }
  }
  