.featured-video {
  position: relative;
  height: 0;
  overflow: hidden;
  background-repeat: no-repeat;
  padding-bottom: $aspectRatioVimeo;

  & > iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__overlay {
    transition: $transition opacity;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &--widescreen {
    padding-bottom: $aspectRatio21-9;
  }

  &--border {
    @include tabletAndDesktopOnly() {
      border: 24px solid colour(base, black);
      border-radius: 24px;
    }
  }
}
