.dds-calendar {
  &__month_selector {
    $size: 22px;
    background: colour(base, white);
    width: $size;
    height: $size;
    border-radius: $size;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__day {
    height: 56px;
    border-radius: 8px;
    margin: 2px;
    border: transparent 1px solid;

    &--today {
      border: colour(base, cloud_grey) 1px solid;

      &:hover {
        border: colour(base, white) 1px solid !important;
      }
    }

    &--previous-or-next {
      color: colour(base, sad_grey);
    }

    &--no-events {
      color: colour(base, devil_grey);
    }

    &--selected {
      background: colour(base, charcoal_grey);
      color: colour(base, white) !important;
    }

    &:hover {
      border: colour(base, charcoal_grey) 1px solid;
    }
  }

  &__dots {
    $size: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $size;
    margin-top: 8px;

    &__dot {
      width: $size;
      height: $size;
      border-radius: $size;
      background: colour(brand, default);
      margin: 0 2px;
    }
  }
}
