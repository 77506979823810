.share-preview-grid {
  &--1 {
    transition: 300ms all;
    display: grid;
    max-width: 500px;
    grid-template-columns: auto;
  }

  &--2 {
    transition: 300ms all;
    display: grid;
    grid-template-columns: auto auto;

    img {
      border: 4px solid transparent;

      &:hover {
        cursor: pointer;
      }

      @include desktopOnly() {
        &.selected {
          border: 4px solid colour(brand);
        }
      }
    }
  }

  &--3 {
    transition: 300ms all;
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin: 0 -8px;

    img {
      transition: 300ms all;
      &:hover {
        cursor: pointer;
      }

      @include desktopOnly() {
        &.selected {
          border: 4px solid colour(brand);
        }
      }
    }

    &__main-image {
      grid-column: 1;
      grid-row: 1 / 3;
    }

    &__second-image {
      grid-column: 2;
      grid-row: 1;
    }

    &__third-image {
      grid-column: 2;
      grid-row: 2;
    }
  }
}
