.u-clear-both {
  clear: both;
}

.clearfix:after {
  content: '';
  clear: both;
  display: table;
}

.u-block {
  display: block;
}

.u-inherit {
  display: inherit !important;
}

.u-inline {
  display: inline !important;
}

.u-inline-block {
  display: inline-block;
}

.u-inline-flex {
  display: inline-flex;
}

.u-grid {
  display: grid;
}

.u-grid--1 {
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 16px;
}

.u-grid--2 {
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @include tabletAndDesktopOnly() {
    grid-template-columns: 1fr 1fr;
  }
}

.u-grid--3 {
  grid-template-columns: 1fr;
  grid-gap: 16px;

  @include tabletOnly() {
    grid-template-columns: 1fr 1fr;
  }
  @include desktopOnly() {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.u-grid--4 {
  grid-template-columns: 1fr;
  grid-gap: 12px;

  @include tabletOnly() {
    grid-template-columns: 1fr 1fr;
  }
  @include desktopOnly() {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.u-grid--5 {
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 0;
  justify-items: center;
}

.u-grid--6 {
  grid-template-columns: auto 1fr auto;
  align-items: center;
}

.u-grid--7 {
  grid-auto-columns: 1fr;
  grid-template-rows: 0.5fr 1.25fr 1.25fr;
  align-items: center;
}

.u-grid--8 {
  grid-gap: 27px;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));

  @include tabletOnly() {
    grid-template-rows: 1fr;
  }
}

.u-grid--3-col {
  grid-template-columns: repeat(3, 1fr);
}

.u-flex-1\@s {
  @include mobileOnly() {
    flex: 1;
  }
}

.u-flex-1\@m {
  @include tabletAndDesktopOnly() {
    flex: 1;
  }
}

.u-flex {
  display: flex;
}

.u-flex-i {
  display: flex !important;
}

.u-flex-0-0-auto {
  flex: 0 0 auto;
}

.u-fill-height {
  flex: 1 1 auto;
  flex-flow: column;

  & > * {
    height: 100%;
  }
}

.u-flex-none {
  flex: none;
  flex-grow: initial;
  flex-shrink: initial;
}

.u-flex-1 {
  flex: 1;

  &-i {
    flex: 1 !important;
  }
}

.u-flex-wrap {
  flex-flow: wrap;
}

.u-flex-flow-column {
  flex-flow: column;
}

.u-flex-column {
  flex-direction: column;
}

.u-flex-row {
  flex-direction: row;
}

.u-flex-row-reverse {
  flex-direction: row-reverse;
}

.u-flex-column-reverse {
  flex-direction: column-reverse;
}

.u-flex-start {
  align-self: flex-start;
}

.u-flex-square {
  flex: 1 0 auto;
  height: auto;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.u-justify-stretch {
  justify-content: stretch;
}

.u-justify-start {
  justify-content: flex-start;
}

.u-justify-end {
  justify-content: flex-end;
}

.u-justify-center {
  justify-content: center;
}

.u-justify-between {
  justify-content: space-between;
}

.u-justify-around {
  justify-content: space-around;
}

.u-align-stretch {
  align-items: stretch;
}

.u-align-start {
  align-items: flex-start;
}

.u-align-end {
  align-items: flex-end;
}

.u-align-center {
  align-items: center;
}

.u-align-between {
  align-items: space-between;
}

.u-align-around {
  align-items: space-around;
}

.u-align-self-baseline {
  align-self: baseline;
}

.u-align-baseline {
  align-items: baseline;
}

.u-align-flex-end {
  align-self: flex-end;
}

.u-split {
  display: flex;
  width: 100%;
  text-align: left;
  align-items: center;
  justify-content: space-between;
}

.u-text-right {
  text-align: right;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-flex-hold {
  flex-shrink: 0;
}

.u-flex-no-hold {
  flex-shrink: 1;
}

.u-flex-grow {
  flex-grow: 1;
}

.nowrap {
  white-space: nowrap;
}

.nowrap--unset {
  white-space: normal;
}

.vertical-align {
  & > * {
    vertical-align: middle;
  }
}

svg.vertical-align {
  vertical-align: middle;
}

.u-hide {
  display: none !important;
}

.u-visibility-hidden {
  visibility: hidden;
}

.inline {
  vertical-align: middle;
}

.u-align-top {
  vertical-align: top;
}

svg.inline {
  position: relative;
  top: -1px;
}

.relative {
  position: relative;

  &--nudge-left {
    left: -2px;
  }

  &--left-50 {
    left: 50%;
  }

  &--top-50 {
    top: 50%;
  }

  &--top-100vh {
    top: 100vh;
  }
}

.absolute {
  position: absolute;

  &--i {
    position: absolute !important;
  }

  &--tr {
    top: 0;
    right: 0;
  }

  &--tl {
    top: 0;
    left: 0;
  }

  &--tb {
    top: 0;
    bottom: 0;
  }

  &--bl {
    bottom: 0;
    left: 0;
  }

  &--br {
    bottom: 0;
    right: 0;
  }

  &--l {
    left: 0;
  }

  &--r {
    right: 0;
  }

  &--b {
    bottom: 0;
  }

  &--t {
    top: 0;
  }

  &--top-center {
    top: 50%;
    transform: translateY(-50%);
  }

  &--left-center {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &--right-center {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &--mid-center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &--center {
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    left: 0;
    top: 50%;
  }

  &--x-center {
    transform: translateX(-50%);
    left: 50%;
  }

  &--bottom-edge {
    transform: translate(-50%, 50%);
    position: absolute;
    left: 50%;
    bottom: 0;
  }

  &--bottom-center {
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    bottom: 0;
  }

  &--avoid-m-nav {
    @include mobileOnly() {
      top: $mobileHeaderHeight;
    }
  }

  &--corner {
    top: 0;
    right: 0;
    transform: translate(50%, -25%);
  }
}

.u-sticky {
  position: sticky;

  &--t {
    top: 0;
  }

  &--b {
    bottom: 0;
  }
}

.u-fixed {
  position: fixed;

  &--t {
    top: 0;
  }

  &--tl {
    top: 0;
    left: 0;
  }

  &--tr {
    top: 0;
    right: 0;
  }

  &--b {
    bottom: 0;
  }

  &--bl {
    bottom: 0;
    left: 0;
  }

  &--center {
    left: 50%;
    transform: translateX(-50%);
  }
}

.u-translate-y-m50 {
  transform: translateY(-50%);
}

.u-translate-y-x-m50 {
  transform: translate(-50%, -50%);
}

.fill {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.u-flip-v {
  transform: scaleY(-1);
}

.u-25vh {
  height: 25vh;
}

.u-75vh {
  height: 75vh;
}

.u-100vh {
  height: 100vh;
}

.u-100vh-var {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.u-75vw {
  width: 75vw;
}

.u-100vw {
  width: 100vw;
}

.u-min-height-auto {
  min-height: auto !important;
}

.u-z-index-0 {
  z-index: 0 !important;
}

.u-z-index-1 {
  z-index: 1;

  &--i {
    z-index: 1 !important;
  }
}

.u-z-index-2 {
  z-index: 2;

  &--i {
    z-index: 2 !important;
  }
}

.u-send-to-front {
  z-index: z-index(header) - 1;

  @include tabletAndDesktopOnly() {
    z-index: z-index(modal) - 1;
  }
}

.page-content--centered {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: calc(100vh - #{$headerHeight});
}

@include smallMobileOnly() {
  .u-hide\@xs {
    display: none !important;
  }
}

@include mobileOnly() {
  .u-block\@s {
    display: block;
  }

  .u-100vw\@s {
    width: 100vw;
  }

  .u-75vw\@s {
    width: 75vw;
  }

  .absolute\@s {
    position: absolute;
  }

  .absolute--b\@s {
    position: absolute;
    bottom: 0;
  }

  .absolute--tl\@s {
    position: absolute;
    top: 0;
    left: 0;
  }

  .relative\@s {
    position: relative;
  }

  .u-flex\@s {
    display: flex;
  }

  .u-grid\@s {
    display: grid;
  }

  .u-flex-hold\@s {
    flex-shrink: 0 !important;
  }

  .u-flex-no-hold\@s {
    flex-shrink: 1 !important;
  }

  .u-justify-between\@s {
    justify-content: space-between;
  }

  .u-flex-wrap\@s {
    flex-flow: wrap !important;
  }

  .u-inline-flex\@s {
    display: inline-flex;
  }

  .u-flex-column\@s {
    flex-direction: column;
  }

  .u-flex-column-reverse\@s {
    flex-direction: column-reverse;
  }

  .u-align-center\@s {
    align-items: center;
  }

  .u-hide\@s {
    display: none !important;
  }

  .u-text-center\@s {
    text-align: center;
  }

  .u-100vh\@s {
    height: 100vh;
  }

  .u-100vh-var\@s {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  .u-justify-center\@s {
    justify-content: center;
  }

  .page-content--centered\@s {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: calc(100vh - #{$headerHeight});
  }

  .u-80vh\@s {
    height: 80vh;
  }
}

@include tabletOnly() {
  .u-flex-column\@m {
    flex-direction: column;
  }
  .relative\@m {
    position: relative;
  }

  .page-content--centered\@m {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: calc(100vh - #{$headerHeight});
  }

  .u-100vh-var\@m {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
}

@include tabletAndDesktopOnly() {
  .u-100vh\@m {
    height: 100vh;
  }

  .u-inline-block\@m {
    display: inline-block !important;
  }

  .u-block\@m {
    display: block !important;
  }

  .u-grid\@m {
    display: grid;
  }

  .u-flex\@m {
    display: flex !important;
  }

  .u-flex-wrap\@m {
    flex-flow: wrap !important;
  }

  .u-flex-row\@m {
    flex-flow: row !important;
  }

  .u-flex-hold\@m {
    flex-shrink: 0 !important;
  }

  .u-flex-grow\@m {
    flex-grow: 1 !important;
  }

  .u-flex-1\@m {
    flex: 1;
  }

  .u-flex-none\@m {
    flex: none;
    flex-grow: initial;
    flex-shrink: initial;
  }

  .u-flex-row-reverse\@m {
    flex-direction: row-reverse;
  }

  .u-justify-between\@m {
    justify-content: space-between;
  }

  .u-hide\@m {
    display: none !important;
  }

  .u-text-center\@m {
    text-align: center;
  }

  .u-text-left\@m {
    text-align: left;
  }

  .u-text-right\@m {
    text-align: right;
  }

  .static\@m {
    position: static !important;
  }

  .relative\@m {
    position: relative;
  }

  .absolute\@m {
    position: absolute !important;
  }

  .u-justify-end\@m {
    justify-content: flex-end;
  }

  .u-split\@m {
    display: flex;
    width: 100%;
    text-align: left;
    align-items: center;
    justify-content: space-between;
  }

  .u-align-center\@m {
    align-items: center;
  }

  .u-justify-center\@m {
    justify-content: center;
  }

  .u-100vh-var\@m {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  .u-align-end\@m {
    align-items: flex-end;
  }

  .absolute--bl\@m {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .u-flex-column\@m {
    flex-direction: column;
  }
}

@include desktopOnly() {
  .u-inline-block\@l {
    display: inline-block !important;
  }

  .u-block\@l {
    display: block !important;
  }

  .u-flex\@l {
    display: flex !important;
  }

  .u-flex-wrap\@l {
    flex-flow: wrap !important;
  }

  .u-flex-row\@l {
    flex-flow: row !important;
  }

  .u-flex-hold\@l {
    flex-shrink: 0 !important;
  }

  .u-flex-1\@l {
    flex: 1;
  }

  .u-flex-column\@l {
    flex-direction: column;
  }

  .u-flex-row-reverse\@l {
    flex-direction: row-reverse;
  }

  .u-flex-grow\@l {
    flex-grow: 1 !important;
  }

  .u-flex-none\@l {
    flex: none;
    flex-grow: initial;
    flex-shrink: initial;
  }

  .u-hide\@l {
    display: none !important;
  }

  .u-text-center\@l {
    text-align: center !important;
  }

  .u-text-left\@l {
    text-align: left !important;
  }

  .u-text-right\@l {
    text-align: right !important;
  }

  .static\@l {
    position: static !important;
  }

  .absolute\@l {
    position: absolute !important;
  }

  .u-justify-center\@l {
    justify-content: center;
  }

  .page-content--centered\@l {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: calc(100vh - #{$headerHeight});
  }

  .u-justify-end\@l {
    justify-content: flex-end;
  }

  .u-split\@l {
    display: flex;
    width: 100%;
    text-align: left;
    align-items: center;
    justify-content: space-between;
  }
}

.u-translate-3d-fix {
  transform: translate3d(0, 0, 0);
}

.u-fit-content {
  width: fit-content;
}
