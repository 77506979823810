.cropper-view-box {
  outline: none;
  outline-color: none;
}

.cropper-center {
  display: none;
}

.profile-picture-crop {
  width: 100%;
  height: 100%;
  position: relative;

  div:first-of-type {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  &__mask {
    display: none;

    &--circle {
      display: block;
      width: 100%;
      height: 100%;
      background-image: url('/images/circle-cropper.svg');
      position: absolute;
      top: 0;
      opacity: 0.8;
      pointer-events: none;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__icon {
    &--small {
      width: 10px;
      height: 10px;
    }

    &--big {
      width: 16px;
      height: 16px;
    }
  }
}
